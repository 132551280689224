import CheckboxIcon from '@/icons/job-post/CheckboxIcon';
import MultipleChoiceIcon from '@/icons/job-post/MultipleChoiceIcon';
import ParagraphIcon from '@/icons/job-post/ParagraphIcon';
import ShortAnswerIcon from '@/icons/job-post/ShortAnswerIcon';

import DateIcon from '../../icons/DateIcon';
import NumericIcon from '../../icons/NumericIcon';

export const TECHNICAL_TEST_PREVIEW_KEY = 'technical-preview-data';
export const EMAIL_PREVIEW_KEY = 'email-preview-data';

import HiringStepActionNoAction from '../../images/hiring-step-action-no-action.png';
import HiringStepActionSendEmail from '../../images/hiring-step-action-send-email.png';
import HiringStepActionTechnicalTest from '../../images/hiring-step-action-technical-test.png';

export const HIRING_STEPS_ACTION_ENUM = {
  noAction: null,
  sendStudyCase: 'send-case-study',
  sendEmail: 'send-email',
};

export const HIRING_STEPS_ACTIONS = [
  {
    title: 'No action',
    description: 'Tidak ada notifikasi/aksi apapun untuk step ini',
    action: HIRING_STEPS_ACTION_ENUM.noAction,
    icon: HiringStepActionNoAction.src,
  },
  {
    title: 'Email',
    description:
      'Dealls akan mengirimkan email otomatis ke kandidat yang dipindahkan ke step ini',
    action: HIRING_STEPS_ACTION_ENUM.sendEmail,
    icon: HiringStepActionSendEmail.src,
  },
  {
    title: 'Take Home Test',
    description:
      'Dealls akan mengirimkan take home test otomatis ke kandidat yang dipindahkan ke step ini',
    action: HIRING_STEPS_ACTION_ENUM.sendStudyCase,
    icon: HiringStepActionTechnicalTest.src,
  },
] as const;

export const HIRING_STEPS_TYPE_ENUM = {
  custom: 'custom',
  techTest: 'techTest',
} as const;

export const MAX_SKILL_PREFERENCE = 5;

export const MAX_JOB_QUOTA_REACHED_MESSAGE = 'JOB_QUOTA_REACHED';

export const CREATE_JOB_STEPS = [
  {
    key: 0,
    title: 'Basic Info',
  },
  {
    key: 1,
    title: 'Job Description',
  },
  {
    key: 2,
    title: 'Applicant Options',
  },
  {
    key: 3,
    title: 'Hiring Steps',
  },
];
export const REGEX_URL_WITHOUT_HTTPS =
  // eslint-disable-next-line no-useless-escape
  /(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const PRESCREENING_QUESTION_TYPES = [
  {
    label: 'Short answer',
    value: 'shortText',
    icon: <ShortAnswerIcon />,
  },
  {
    label: 'Paragraph',
    value: 'longText',
    icon: <ParagraphIcon />,
  },
  {
    label: 'Checkboxes',
    value: 'checkbox',
    icon: <CheckboxIcon />,
  },
  {
    label: 'Multiple choice',
    value: 'radio',
    icon: <MultipleChoiceIcon />,
  },
  {
    label: 'Numeric',
    value: 'numeric',
    icon: <NumericIcon />,
  },
  {
    label: 'Date',
    value: 'date',
    icon: <DateIcon />,
  },
];
