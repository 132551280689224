import { message } from 'antd';
import { useMemo } from 'react';

import { parseErrorMessage } from '@/common/utils';
import useMyUserCompanyQuery from '@/modules/company/hooks/queries/useMyUserCompanyQuery';
import { Member } from '@/modules/company/types';
import { PERMISSION_ACTIONS } from '@/modules/manage-team/constants';

import { AccessPermission, Action, Resource } from '../types';

type UseGatePermissionProps = {
  action: Action;
  resource: Resource;
  matchAllowedCriteria?: (
    resource: Resource,
    allowedCriteria: AccessPermission['allowedCriteria']
  ) => boolean | undefined;
};

export type UseGatePermissionPropsAsFunction = <T>(
  props: T
) => UseGatePermissionProps;

type CheckAccessPermissionProps = {
  accessPermission: AccessPermission;
} & Omit<UseGatePermissionProps, 'action'>;

type ReturnType<
  T extends UseGatePermissionProps | UseGatePermissionPropsAsFunction
> = T extends UseGatePermissionPropsAsFunction
  ? {
      isGranted: <T>(args: T) => boolean;
      readyCheckPermission: boolean;
      owner: boolean;
      data?: Member;
      error: unknown;
    }
  : T extends UseGatePermissionProps
  ? {
      granted: boolean;
      readyCheckPermission: boolean;
      owner: boolean;
      data?: Member;
      error: unknown;
    }
  : never;

export const showPermissionError = (error: unknown) => {
  const tempError = error
    ? parseErrorMessage(error)
    : {
        content: 'A network error occured. Please try refreshing the page',
      };
  return message.error(tempError);
};

const useGatePermission = <
  T extends UseGatePermissionProps | UseGatePermissionPropsAsFunction
>(
  props: T
): ReturnType<T> => {
  const getProps = <T,>(args: T) => {
    return typeof props === 'function' ? props(args) : props;
  };

  const runDirectly = typeof props !== 'function';

  const { data, isLoading, error } = useMyUserCompanyQuery();
  const user = data?.data?.result;

  const runCheckPermission = <T,>(args: T) => {
    const { action, resource, matchAllowedCriteria } = getProps(
      args
    ) as UseGatePermissionProps;

    const checkAccessPermission = (
      checkAccessPermissionProps: CheckAccessPermissionProps
    ) => {
      const { accessPermission } = checkAccessPermissionProps;

      if (accessPermission && accessPermission?.allowedCriteria) {
        if (!matchAllowedCriteria) {
          return true;
        }

        return !!matchAllowedCriteria(
          resource,
          accessPermission.allowedCriteria
        );
      }

      const createJob = action === 'create' && resource === 'jobPost';

      /**
       * allowedCriteria is required for resource jobPost, jobPostSalary. but not for action create jobPost
       */
      const requiredAllowedCriteria =
        resource === 'jobPost' || resource === 'jobPostSalary';

      if (!createJob && requiredAllowedCriteria) {
        return false;
      }

      // if allowedCriteria is not defined, then it is allowed by default
      return true;
    };

    const accessPermissionsMap = (
      accessPermissions: AccessPermission[] = []
    ) => {
      return accessPermissions?.reduce((prev, cur) => {
        const { permission } = cur;

        if (permission) {
          const key = `${permission.resource}.${permission.action}`;
          prev.set(key, cur);
        }

        return prev;
      }, new Map());
    };

    const isAllowed = () => {
      if (!user) return false;

      const owner = user.role === 'owner';
      if (owner) return true;

      const accessPermissions = user.accessPermissions;
      const resultAccessPermissionMap = accessPermissionsMap(accessPermissions);

      if (resultAccessPermissionMap.size === 0) {
        return false;
      }

      const manageAction = PERMISSION_ACTIONS.manage;
      const manageAccessPermission = resultAccessPermissionMap.get(
        `${resource}.${manageAction}`
      );
      const accessPermission = resultAccessPermissionMap.get(
        `${resource}.${action}`
      );

      let permissionGranted = false;

      if (accessPermission) {
        permissionGranted = checkAccessPermission({
          accessPermission,
          resource,
          matchAllowedCriteria,
        });
      }

      const createJob = action === 'create' && resource === 'jobPost';

      if (!permissionGranted && manageAccessPermission && !createJob) {
        permissionGranted = checkAccessPermission({
          accessPermission: manageAccessPermission,
          resource,
          matchAllowedCriteria,
        });
      }

      return permissionGranted;
    };

    return isAllowed();
  };

  const granted = useMemo(() => {
    return runCheckPermission({});
  }, [user]);

  const owner = user?.role === 'owner';

  if (runDirectly) {
    return {
      granted,
      owner,
      data,
      readyCheckPermission: !isLoading,
      error,
    } as ReturnType<T>;
  } else {
    return {
      isGranted: runCheckPermission,
      owner,
      data,
      readyCheckPermission: !isLoading,
      error,
    } as ReturnType<T>;
  }
};

/**
 * Usage example:
 * const canCreateJob = useGatePermission({
 *   action: 'create',
 *   resource: 'jobPost',
 * });
 *
 * const { granted: canViewJobById } = useGatePermission((jobId) => {
 *   return {
 *     action: 'view',
 *     resource: 'jobPost',
 *     matchAllowedCriteria: (_, allowedCriteria) => {
 *       return allowedCriteria?.jobIds.includes(jobId);
 *     },
 *   };
 * });
 *
 * canViewJobById(1);
 */

export default useGatePermission;
