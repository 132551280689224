const SendInvitation = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2849 1.71891C13.9515 1.37824 13.4582 1.25224 12.9982 1.38557L2.27153 4.48491C1.7862 4.61957 1.4422 5.00424 1.34953 5.49224C1.25487 5.98957 1.58553 6.62157 2.01753 6.88557L5.37153 8.93357C5.71553 9.14424 6.15953 9.09157 6.4442 8.80624L10.2849 4.96557C10.4782 4.76491 10.7982 4.76491 10.9915 4.96557C11.1849 5.15824 11.1849 5.47224 10.9915 5.67224L7.1442 9.51291C6.85887 9.79824 6.80553 10.2409 7.01553 10.5856L9.06487 13.9522C9.30487 14.3516 9.7182 14.5789 10.1715 14.5789C10.2249 14.5789 10.2849 14.5789 10.3382 14.5716C10.8582 14.5056 11.2715 14.1516 11.4249 13.6516L14.6049 3.00557C14.7449 2.55224 14.6182 2.05891 14.2849 1.71891Z"
        fill="#6913D8"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00691 11.2053C1.87891 11.2053 1.75091 11.1566 1.65358 11.0586C1.45825 10.8633 1.45825 10.5473 1.65358 10.352L2.56358 9.44129C2.75891 9.24662 3.07558 9.24662 3.27091 9.44129C3.46558 9.63662 3.46558 9.95329 3.27091 10.1486L2.36025 11.0586C2.26291 11.1566 2.13491 11.2053 2.00691 11.2053ZM4.51438 12.0002C4.38638 12.0002 4.25838 11.9516 4.16105 11.8536C3.96571 11.6582 3.96571 11.3422 4.16105 11.1469L5.07105 10.2362C5.26638 10.0416 5.58305 10.0416 5.77838 10.2362C5.97305 10.4316 5.97305 10.7482 5.77838 10.9436L4.86771 11.8536C4.77038 11.9516 4.64238 12.0002 4.51438 12.0002ZM4.68351 14.3789C4.78085 14.4769 4.90885 14.5256 5.03685 14.5256C5.16485 14.5256 5.29285 14.4769 5.39018 14.3789L6.30085 13.4689C6.49551 13.2736 6.49551 12.9569 6.30085 12.7616C6.10551 12.5669 5.78885 12.5669 5.59351 12.7616L4.68351 13.6722C4.48818 13.8676 4.48818 14.1836 4.68351 14.3789Z"
        fill="#6913D8"
      />
    </svg>
  );
};

export default SendInvitation;
