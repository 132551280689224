import { message } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import { useRouter } from 'next/router';

import { useStoreApi } from '@/common/contexts/useApi';
import { parseErrorMessage } from '@/common/utils';
import {
  getRedirectRouteBasedOnAction,
  updateLastSeenNotification,
  updateNotificationReadStatus,
} from '@/modules/notifications/services/NotificationService';

const Announcement = ({ data, defaultImage, size = 'large' }) => {
  const getDateNotification = (date) => {
    const result = moment(date).format('DD MMM');
    return result;
  };

  const router = useRouter();
  const { axios } = useStoreApi();

  const notifData = data?.data;

  const handleClickNotification = async ({ action, notifData, data }) => {
    try {
      await updateNotificationReadStatus(axios, data._id);
      await updateLastSeenNotification(axios);
      const redirectURL = getRedirectRouteBasedOnAction(action, notifData);

      if (redirectURL) return router.push(redirectURL);
    } catch (error) {
      const errorMessage = parseErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const imageUrl = defaultImage ? defaultImage : data?.data?.imageUrl;

  return (
    <>
      <div
        className={clsx(
          'border-b-solid relative flex border-b border-b-neutral-200 py-3 px-4 md:py-4 md:px-8',
          {
            'py-3 px-4 md:py-4 md:px-8': size === 'large',
            'py-3 px-4': size === 'small',
            'bg-white': data?.isRead,
            'bg-violet-0': data?.isRead === false,
          }
        )}
        onClick={() =>
          handleClickNotification({
            action: data.action,
            notifData,
            data,
          })
        }
      >
        {imageUrl && (
          <>
            <img
              src={imageUrl}
              alt="notification image"
              className={clsx('cursor-pointer rounded-lg object-contain', {
                'mr-4 max-h-[48px] w-auto max-w-[48px]': size === 'large',
                'mr-4 max-h-[28px] w-auto max-w-[28px]': size === 'small',
              })}
            />
          </>
        )}
        <div className="flex w-full flex-col">
          <span
            className={clsx(
              'w-[calc(100%_-_35px)] cursor-pointer text-xs font-bold leading-[18px] text-neutral-900',
              {
                'md:text-sm md:leading-[21px]': size === 'large',
              }
            )}
          >
            {data?.title}
          </span>
          <p
            className={clsx(
              'mt-1 w-full max-w-[616px] text-xs leading-[18px] text-neutral-900',
              {
                'md:text-sm md:leading-[18.2px]': size === 'large',
              }
            )}
          >
            {data?.body}
          </p>
        </div>

        <span
          className={clsx(
            'absolute top-[14px] right-[16px] block text-[10px] font-medium leading-[15px] text-neutral-500 ',
            {
              'md:top-[16px] md:right-[32px] md:text-xs md:leading-[15.6px]':
                size === 'large',
            }
          )}
        >
          {getDateNotification(data.createdAt)}
        </span>
      </div>
    </>
  );
};

export default Announcement;
