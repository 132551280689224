import React, { forwardRef } from 'react';

type ArrowCircleIconProps = {
  className?: string;
  onClick?: (event?: React.MouseEvent) => void;
  variant?: 'primary' | 'white';
  style?: React.CSSProperties;
};

const ArrowCircleIcon = forwardRef<SVGSVGElement, ArrowCircleIconProps>(
  ({ className, onClick, variant, style }, ref) => {
    return (
      <svg
        ref={ref}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        style={style}
      >
        <circle
          cx="12"
          cy="12"
          r="12"
          fill={variant === 'primary' ? '#6913D8' : 'white'}
        />
        <path
          d="M13.0503 8.25041C13.3422 8.54237 13.3425 9.01564 13.0509 9.30791L11.2009 11.1619C10.6167 11.7474 10.6167 12.6954 11.2009 13.2809L13.0509 15.1348C13.3425 15.4271 13.3422 15.9004 13.0503 16.1923C12.7581 16.4845 12.2844 16.4845 11.9922 16.1923L9.08189 13.282C8.49611 12.6962 8.49611 11.7465 9.0819 11.1607L11.9922 8.25041C12.2844 7.95823 12.7581 7.95823 13.0503 8.25041Z"
          fill={variant === 'primary' ? 'white' : '#6913D8'}
        />
      </svg>
    );
  }
);

export default ArrowCircleIcon;
