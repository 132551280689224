import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { getLocalStorageValue, setLocalStorageValue } from '../helpers';
import { PreRegistLocalStorageKey } from '../types/enums';

// constant name
export const PRE_REGIST = 'pre-regist';

const PreRegistContext = createContext({});

const initialStatePreRegist = {
  email: getLocalStorageValue(PreRegistLocalStorageKey.PRE_REGIST)?.email ?? '',
};

export const removeRelatedWithPreRegist = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(PRE_REGIST);
  }
};

const reducer = (state, { key, ...payload }) => {
  switch (key) {
    case 'SET_PRE_REGIST':
      setLocalStorageValue(PreRegistLocalStorageKey.PRE_REGIST, payload.data);
      return {
        ...state,
        ...payload.data,
      };

    default:
      return state;
  }
};

export const PreRegistProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialStatePreRegist);
  useEffect(() => {
    const dataUser = getLocalStorageValue(PreRegistLocalStorageKey.PRE_REGIST);
    if (dataUser) {
      dispatch({ key: 'SET_PRE_REGIST', data: { ...dataUser } });
    } else {
      setLocalStorageValue(
        PreRegistLocalStorageKey.PRE_REGIST,
        initialStatePreRegist
      );
    }
  }, []);

  return (
    <PreRegistContext.Provider value={{ state, dispatch }}>
      {children}
    </PreRegistContext.Provider>
  );
};

export const useStorePreRegist = () => {
  const context = useContext(PreRegistContext);
  return context;
};
