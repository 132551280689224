import clsx from 'clsx';

const WarningIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('text-[#A76603]', props.className)}
      {...props}
    >
      <path
        d="M10.0001 16.6666C11.7682 16.6666 13.4639 15.9642 14.7141 14.714C15.9644 13.4638 16.6667 11.7681 16.6667 9.99996C16.6667 8.23185 15.9644 6.53616 14.7141 5.28591C13.4639 4.03567 11.7682 3.33329 10.0001 3.33329C8.23197 3.33329 6.53628 4.03567 5.28604 5.28591C4.03579 6.53616 3.33341 8.23185 3.33341 9.99996C3.33341 11.7681 4.03579 13.4638 5.28604 14.714C6.53628 15.9642 8.23197 16.6666 10.0001 16.6666ZM10.0001 18.3333C5.39758 18.3333 1.66675 14.6025 1.66675 9.99996C1.66675 5.39746 5.39758 1.66663 10.0001 1.66663C14.6026 1.66663 18.3334 5.39746 18.3334 9.99996C18.3334 14.6025 14.6026 18.3333 10.0001 18.3333ZM9.16675 14.1666C9.16675 13.7064 9.53984 13.3333 10.0001 13.3333V13.3333C10.4603 13.3333 10.8334 13.7064 10.8334 14.1666V14.1666C10.8334 14.6269 10.4603 15 10.0001 15V15C9.53984 15 9.16675 14.6269 9.16675 14.1666V14.1666ZM9.16675 5.83329C9.16675 5.37306 9.53984 4.99996 10.0001 4.99996V4.99996C10.4603 4.99996 10.8334 5.37306 10.8334 5.83329V10.8333C10.8334 11.2935 10.4603 11.6666 10.0001 11.6666V11.6666C9.53984 11.6666 9.16675 11.2935 9.16675 10.8333V5.83329Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WarningIcon;
