import { atomWithReset, atomWithStorage } from 'jotai/utils';

import { LocalSyncStorage } from '@/common/helpers/VirtualLocalStorage';

export const KEYWORD_ATOM_TALENT_SEARCH_KEY = 'ed_talent-search-keyword.';

export const searchAtom = atomWithStorage<string>(
  KEYWORD_ATOM_TALENT_SEARCH_KEY,
  '',
  new LocalSyncStorage<string>()
);

export const showLimitedSearchResultBannerAtom = atomWithReset(false);

export const showUnlimitedSearchResultBannerAtom = atomWithReset(false);
