import { message, Spin } from 'antd';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

import useObserver from '@/common/hooks/useIntersectionObserver';
import { parseErrorMessage } from '@/common/utils';
import { apiInstance } from '@/modules/core/axios/configs';
import AnnouncementNotification from '@/modules/notifications/components/NotificationType/Announcement';
import DefaultNotification from '@/modules/notifications/components/NotificationType/Default';
import {
  getDefaultNotificationImage,
  updateLastSeenNotification,
} from '@/modules/notifications/services/NotificationService';

import useNotificationInfiniteQuery from '../../hooks/queries/useNotificationInfiniteQuery';
import { NotificationList as NotificationListItem } from '../../types';
import { EmptyNotification } from '../EmptyNotification';

const NotificationList = ({ onlyShowUnread, category, size }) => {
  const loadMoreRef = useRef(null);
  const intersection = useObserver(loadMoreRef);

  const {
    data: responseAllNotification,
    fetchNextPage,
    hasNextPage,
    isError: isErrorAllNotification,
    isLoading: isLoadingAllNotification,
    isFetchingNextPage: isRefreshingAllNotification,
  } = useNotificationInfiniteQuery({
    params: {
      isRead: onlyShowUnread ? false : null,
      category: category ? [category] : null,
    },
    options: {
      select: (data) => {
        const notificationsList: NotificationListItem[] =
          data?.pages?.reduce(
            (acc, curr) => [...acc, ...(curr?.docs ?? [])],
            [] as NotificationListItem[]
          ) ?? [];

        return {
          pageParams: data?.pageParams,
          pages: notificationsList,
        };
      },
    },
  });

  const notificationsList = responseAllNotification?.pages ?? [];
  const updateLastSeenNotificationPage = async () => {
    try {
      await updateLastSeenNotification(apiInstance);
    } catch (error) {
      const errorMessage = parseErrorMessage(error);
      message.error(errorMessage);
    }
  };

  useEffect(() => {
    updateLastSeenNotificationPage();
  }, []);

  const findTodayNotification = (notification) =>
    new Date(notification?.createdAt).toLocaleDateString() ===
    new Date().toLocaleDateString();

  const findOlderNotification = (notification) =>
    new Date(notification?.createdAt).toLocaleDateString() !==
    new Date().toLocaleDateString();

  // Notification has 2 type (Notification Default & Blast Notification)
  const findDefaultNotificationType = (response) => {
    return response?.notification; // find Default Notif
  };

  const findBlastNotificationType = (response) => {
    return !response?.notification;
  };

  const fetchNextList = async () => {
    try {
      await fetchNextPage();
    } catch (error) {
      const errorMessage = parseErrorMessage(error);
      message.error(parseErrorMessage(errorMessage));
    }
  };

  useEffect(() => {
    if (
      intersection.intersecting &&
      !isLoadingAllNotification &&
      !isErrorAllNotification &&
      !isRefreshingAllNotification &&
      hasNextPage
    ) {
      fetchNextList();
    }
  }, [
    intersection.intersecting,
    isRefreshingAllNotification,
    isLoadingAllNotification,
  ]);

  return (
    <div>
      {notificationsList.length <= 0 && !isLoadingAllNotification && (
        <div className="mt-3 flex w-full items-center justify-center md:mt-4">
          <EmptyNotification size={size} />
        </div>
      )}

      {/* Today */}
      {isLoadingAllNotification ? (
        <div
          className={clsx('flex w-full items-center justify-center', {
            'fixed inset-0 z-[-1]': size === 'large',
            'my-4 min-w-[360px]': size === 'small',
          })}
        >
          <Spin />
        </div>
      ) : (
        <>
          {notificationsList.filter(findTodayNotification)?.length > 0 && (
            <>
              <div
                className={clsx('block', {
                  'py-4': size === 'large',
                  'py-3': size === 'small',
                })}
              >
                <span
                  className={clsx(
                    'block font-bold leading-[150%] tracking-[0.02em] text-neutral-900',
                    {
                      'px-4 text-base md:px-0 md:text-sm md:leading-[21px]':
                        size === 'large',
                      'px-4 text-xs leading-[21px]': size === 'small',
                    }
                  )}
                >
                  Today
                </span>
              </div>
              <div className="border-t border-t-neutral-200">
                {notificationsList
                  .filter(findTodayNotification)
                  .filter(findDefaultNotificationType)
                  .map((todayNotification) => (
                    <DefaultNotification
                      key={todayNotification._id}
                      data={todayNotification}
                      defaultImage={getDefaultNotificationImage(
                        todayNotification.notification.action
                      )}
                      size={size}
                    />
                  ))}

                {/* Blast Notif */}
                {notificationsList
                  .filter(findTodayNotification)
                  .filter(findBlastNotificationType)
                  .map((todayNotification) => (
                    <AnnouncementNotification
                      key={todayNotification?._id}
                      data={todayNotification}
                      defaultImage={getDefaultNotificationImage(
                        todayNotification?.notification?.action
                      )}
                      size={size}
                    />
                  ))}
              </div>
            </>
          )}

          {/* Older */}
          {notificationsList.filter(findOlderNotification).length > 0 && (
            <>
              <div
                className={clsx('block', {
                  'py-4': size === 'large',
                  'py-3': size === 'small',
                })}
              >
                <span
                  className={clsx(
                    'block font-bold leading-[150%] tracking-[0.02em] text-neutral-900',
                    {
                      'px-4 text-base md:px-0 md:text-sm md:leading-[21px]':
                        size === 'large',
                      'px-4 text-xs leading-[21px]': size === 'small',
                    }
                  )}
                >
                  Older
                </span>
              </div>
              <div className="border-t border-t-neutral-200">
                {notificationsList
                  .filter(findOlderNotification)
                  .filter(findDefaultNotificationType)
                  .map((olderNotification) => (
                    <DefaultNotification
                      key={olderNotification._id}
                      data={olderNotification}
                      defaultImage={getDefaultNotificationImage(
                        olderNotification.notification.action
                      )}
                      size={size}
                    />
                  ))}

                {/* Blast Notif */}
                {notificationsList
                  .filter(findOlderNotification)
                  .filter(findBlastNotificationType)
                  .map((olderNotification) => (
                    <AnnouncementNotification
                      key={olderNotification?._id}
                      data={olderNotification}
                      defaultImage={getDefaultNotificationImage(
                        olderNotification?.notification?.action
                      )}
                      size={size}
                    />
                  ))}
              </div>
            </>
          )}

          {isRefreshingAllNotification && (
            <div className="mt-5 flex w-full justify-center">
              <Spin />
            </div>
          )}
        </>
      )}
      <div ref={loadMoreRef}></div>
    </div>
  );
};

export default NotificationList;
