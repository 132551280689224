import LocalStorageService from './LocalStorageService';
import { EmployerDashboardLocalStorageModel } from './LocalStorageService/model';

export const formatCurrencyIDR = (
  num: number,
  { withoutPrefix = false, withoutSpace = false } = {}
) => {
  const output = new Intl.NumberFormat('id-ID', {
    ...(withoutPrefix ? {} : { style: 'currency', currency: 'IDR' }),
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);

  if (withoutSpace) return output.replace(/\s/g, '');

  return output;
};

/**
 * Local Storage API
 * Directly accessing window.localStorage is not recommended as:
 * - It is not type safe
 * - It does not handle case where localStorage is not available
 *
 * The following API is a wrapper around window.localStorage so that:
 * - It is type safe
 * - It handles case where localStorage is not available (usually due to user disabled cookies)
 */

export const getLocalStorageValue = <
  T = EmployerDashboardLocalStorageModel,
  K extends keyof T = keyof T
>(
  key: K
): T[K] | null => {
  const localStorageService = new LocalStorageService<T>();
  return localStorageService.get(key);
};

export const setLocalStorageValue = <
  T = EmployerDashboardLocalStorageModel,
  K extends keyof T = keyof T
>(
  key: K,
  value: T[K]
) => {
  const localStorageService = new LocalStorageService<T>();
  return localStorageService.set(key, value);
};

export const removeLocalStorageValue = <
  T = EmployerDashboardLocalStorageModel,
  K extends keyof T = keyof T
>(
  key: K
) => {
  const localStorageService = new LocalStorageService<T>();
  return localStorageService.remove(key);
};

/**
 * End Local Storage API
 */
