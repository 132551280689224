import { Tooltip as AntdTooltip, TooltipProps as AntdTooltipProps } from 'antd';
import { forwardRef, PropsWithChildren } from 'react';

type TooltipProps = PropsWithChildren<AntdTooltipProps>;

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    { title, overlayInnerStyle, placement = 'top', children, ...props },
    ref
  ) => {
    return (
      <AntdTooltip
        ref={ref}
        title={title}
        placement={placement}
        overlayInnerStyle={{
          borderRadius: '6px',
          backgroundColor: '#313131',
          fontSize: '11px',
          padding: '6px 8px',
          ...overlayInnerStyle,
        }}
        color="#313131"
        align={{
          offset: [0, 4],
        }}
        {...props}
      >
        {children}
      </AntdTooltip>
    );
  }
);

export default Tooltip;
