import React from 'react';

const DisciplineIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.625 4.15625C11.875 4.15625 4.78125 11.25 4.78125 20C4.78125 28.75 11.875 35.8437 20.625 35.8437C29.375 35.8437 36.4687 28.75 36.4687 20C36.4687 11.25 29.375 4.15625 20.625 4.15625ZM20.625 34.3437C12.7188 34.3437 6.28125 27.9062 6.28125 20C6.28125 12.0938 12.7188 5.65625 20.625 5.65625C28.5312 5.65625 34.9687 12.0938 34.9687 20C34.9687 27.9062 28.5312 34.3437 20.625 34.3437Z"
        fill="url(#paint0_linear_28_1779)"
      />
      <path
        d="M21.375 19.6875V10.9375C21.375 10.5313 21.0313 10.1875 20.625 10.1875C20.2188 10.1875 19.875 10.5313 19.875 10.9375V20C19.875 20.1875 19.9688 20.4062 20.0938 20.5312L26.125 26.5625C26.2812 26.7188 26.4688 26.7812 26.6563 26.7812C26.8438 26.7812 27.0313 26.7188 27.1875 26.5625C27.4688 26.2813 27.4688 25.7812 27.1875 25.5L21.375 19.6875Z"
        fill="url(#paint1_linear_28_1779)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_28_1779"
          x1="20.625"
          y1="4.15625"
          x2="20.625"
          y2="35.8437"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_28_1779"
          x1="23.6367"
          y1="10.1875"
          x2="23.6367"
          y2="26.7812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DisciplineIcon;
