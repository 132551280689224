import React from 'react';

import AdaptableIcon from '@/modules/company/icons/culture/AdaptableIcon';
import BalanceIcon from '@/modules/company/icons/culture/BalanceIcon';
import CollaborativeIcon from '@/modules/company/icons/culture/CollaborativeIcon';
import DisciplineIcon from '@/modules/company/icons/culture/DisciplineIcon';
import DrivenIcon from '@/modules/company/icons/culture/DrivenIcon';
import EnergeticIcon from '@/modules/company/icons/culture/EnergeticIcon';
import FastPacedIcon from '@/modules/company/icons/culture/FastPacedIcon';
import FunIcon from '@/modules/company/icons/culture/FunIcon';
import InnovativeIcon from '@/modules/company/icons/culture/InnovativeIcon';
import IntegrityIcon from '@/modules/company/icons/culture/IntegrityIcon';
import NurturingIcon from '@/modules/company/icons/culture/NurturingIcon';
import OpenIcon from '@/modules/company/icons/culture/OpenIcon';
import PassionateIcon from '@/modules/company/icons/culture/PassionateIcon';
import ProgessiveIcon from '@/modules/company/icons/culture/ProgessiveIcon';
import QualityIcon from '@/modules/company/icons/culture/QualityIcon';
import RespectfulIcon from '@/modules/company/icons/culture/RespectfulIcon';
import TrustWorthyIcon from '@/modules/company/icons/culture/TrustWorthyIcon';
import VibrantIcon from '@/modules/company/icons/culture/VibrantIcon';

import { generateCurrentEnvironment } from '../utils/generator';

type PaymentMethod = {
  name: string;
  value: string;
  imageSource: string;
  feeType: 'percentage' | 'number';
};

type PaymentMethodList = Record<string, PaymentMethod[]>;

type ApplicantGrade = {
  label: string;
  tableLabel: string;
  dropdownLabel: string;
  tagLabel: string;
  importApplicantLabel: string;
  value: number;
};

type TalentGrade = {
  label?: string;
  tableLabel: string;
  eligibility: number | number[];
  weight: number;
};

type CompanyCulture = {
  key: string;
  name: string;
  icon: React.ReactNode;
  subtitle: string;
};

export const paymentMethodList: PaymentMethodList = {
  'e-wallet': [
    {
      name: 'Gopay',
      value: 'gopay',
      imageSource: '/payment-method/gopay.png',
      // feeValue: 2,
      feeType: 'percentage',
    },
    {
      name: 'Shopee Pay',
      value: 'shopeepay',
      imageSource: '/payment-method/shopeepay.png',
      // feeValue: 1.3,
      feeType: 'percentage',
    },
  ],
  'virtual-account': [
    {
      name: 'Mandiri',
      value: 'echannel',
      imageSource: '/payment-method/mandiri.png',
      // feeValue: 2200,
      feeType: 'number',
    },
    {
      name: 'Bank BRI',
      value: 'bri_va',
      imageSource: '/payment-method/bri.png',
      // feeValue: 3300,
      feeType: 'number',
    },
    {
      name: 'Bank BNI',
      value: 'bni_va',
      imageSource: '/payment-method/bni.png',
      // feeValue: 2900,
      feeType: 'number',
    },
    {
      name: 'Bank BCA',
      value: 'bca_va',
      imageSource: '/payment-method/bca.png',
      // feeValue: 2900,
      feeType: 'number',
    },
    {
      name: 'Bank Permata',
      value: 'permata_va',
      imageSource: '/payment-method/permata-bank.png',
      // feeValue: 2200,
      feeType: 'number',
    },
  ],
};

export const listJobTypeOptions = [
  {
    label: 'Full-Time',
    value: 'fullTime',
  },
  {
    label: 'Freelance',
    value: 'freelance',
  },
  {
    label: 'Internship',
    value: 'internship',
  },
  {
    label: 'Part-Time',
    value: 'partTime',
  },
  {
    label: 'Contract',
    value: 'contract',
  },
];

export const listWorkplace = [
  {
    label: 'Remote',
    value: 'remote',
  },
  {
    label: 'Hybrid',
    value: 'hybrid',
  },
  {
    label: 'On-site',
    value: 'onSite',
  },
];

export const salary = [
  {
    label: 'Paid',
    value: 'paid',
  },
  {
    label: 'Unpaid',
    value: 'unpaid',
  },
];

export const expectationList = [
  {
    label: 'Students',
    value: 'student',
  },
  {
    label: 'Freshgrad',
    value: 'freshgrad',
  },
  {
    label: 'Experienced',
    value: 'experienced',
  },
];

export const companySizeList = [
  {
    label: '1-50 Employees',
    value: 'small',
  },
  {
    label: '50-100 Employees',
    value: 'medium',
  },
  {
    label: '>100 Employees',
    value: 'large',
  },
];

export const statusList = [
  { value: 'accepted', name: 'Shortlisted' },
  { value: 'notProcessed', name: 'Pending' },
  { value: 'review', name: 'In Review' },
  { value: 'approached', name: 'On Hold' },
  { value: 'rejected', name: 'Rejected' },
  { value: 'expired', name: 'Expired' },
  { value: 'offerred', name: 'Offering Stage' },
];

export const applicantsGradeList: ApplicantGrade[] = [
  {
    label: '1st year college student',
    tableLabel: 'Uni 1st year',
    dropdownLabel: 'Uni year 1',
    tagLabel: '1st year uni',
    importApplicantLabel: '1st year college student',
    value: 1,
  },
  {
    label: '2nd year college student',
    tableLabel: 'Uni 2nd year',
    dropdownLabel: 'Uni year 2',
    tagLabel: '2nd year uni',
    importApplicantLabel: '2nd year college student',
    value: 2,
  },
  {
    label: '3rd year college student',
    tableLabel: 'Uni 3rd year',
    dropdownLabel: 'Uni year 3',
    tagLabel: '3rd year uni',
    importApplicantLabel: '3rd year college student',
    value: 3,
  },
  {
    label: '4th year college student',
    tableLabel: 'Uni 4th year',
    dropdownLabel: 'Uni year 4',
    tagLabel: '4th year uni',
    importApplicantLabel: '4th year college student',
    value: 4,
  },
  {
    label: 'Freshgrad',
    tableLabel: 'Freshgrad',
    dropdownLabel: 'Freshgrad',
    tagLabel: 'Freshgrad',
    importApplicantLabel: 'Freshgraduate',
    value: 0,
  },
  {
    label: '1-3 years of experience',
    tableLabel: '1–3 Years',
    dropdownLabel: '1–3 years',
    tagLabel: '1-3 years',
    importApplicantLabel: '1-3 years of experience',
    value: 5,
  },
  {
    label: '4-5 years of experience',
    tableLabel: '4–5 Years',
    dropdownLabel: '4–5 Years',
    tagLabel: '4-5 years',
    importApplicantLabel: '4-5 years of experience',
    value: 6,
  },
  {
    label: '5+ years of experience ',
    tableLabel: '5+ Years',
    dropdownLabel: '5+ years',
    tagLabel: '5+ years',
    importApplicantLabel: '5+ years of experience',
    value: 7,
  },
];

export const talentYoEGradeList: TalentGrade[] = [
  {
    label: 'Non-Final Year',
    tableLabel: 'Non-Final Year',
    eligibility: [1, 2, 3],
    weight: 0,
  },
  {
    label: 'Final Year',
    tableLabel: 'Final Year',
    eligibility: 4,
    weight: 1,
  },
  {
    label: 'Fresh Graduate',
    tableLabel: 'Fresh Graduate',
    eligibility: 0,
    weight: 2,
  },
  {
    label: '1-3 years',
    tableLabel: '1-3',
    eligibility: 5,
    weight: 3,
  },
  {
    label: '4-5 years',
    tableLabel: '4-5',
    eligibility: 6,
    weight: 4,
  },
  {
    label: '5+ years',
    tableLabel: '5+',
    eligibility: 7,
    weight: 5,
  },
  {
    label: 'Others',
    tableLabel: 'Others',
    eligibility: 8,
    weight: 6,
  },
];

/**
 *  @deprecated use RANK in modules/jobs/constant instead
 */
export const RANK = {
  'top1%': 'top1%',
  'top5%': 'top5%',
  'top10%': 'top10%',
  'top25%': 'top25%',
  others: 'others',
};

/**
 *  @deprecated use RANK in modules/jobs/constant instead
 */
export const rankList = [
  { label: 'Top 1%', value: RANK['top1%'] },
  { label: 'Top 5%', value: RANK['top5%'] },
  { label: 'Top 10%', value: RANK['top10%'] },
  { label: 'Top 25%', value: RANK['top25%'] },
  { label: 'Others', value: RANK['others'] },
];

export const preferredEmploymentTypeList = [
  {
    label: 'Full-Time',
    value: 'fullTime',
  },
  {
    label: 'Internship',
    value: 'internship',
  },
  {
    label: 'Part-Time',
    value: 'partTime',
  },
];

export const limitList = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
];

export const Fundings = [
  {
    label: 'Bootstrap',
    value: 'bootstrap',
  },
  {
    label: 'Pre-seed',
    value: 'preSeed',
  },
  {
    label: 'Seed',
    value: 'seed',
  },
  {
    label: 'Series A',
    value: 'seriesA',
  },
  {
    label: 'Series B',
    value: 'seriesB',
  },
  {
    label: 'Series C',
    value: 'seriesC',
  },
  {
    label: 'Series D',
    value: 'seriesD',
  },
  {
    label: 'Unicorn and above',
    value: 'unicornAndAbove',
  },
  {
    label: 'Revenue',
    value: 'revenue',
  },
  {
    label: 'Others',
    value: 'other',
  },
];

export const WorkingPolicy = [
  {
    label: 'Hybrid',
    value: 'hybrid',
  },
  {
    label: 'Flexible',
    value: 'flexible',
  },
  {
    label: 'On-Site',
    value: 'onSite',
  },
  {
    label: 'Work From Anywhere',
    value: 'workFromAnywhere',
  },
];

export const Benefits = [
  {
    label: 'Competitive Salary',
    value: 'competitiveSalary',
    icon: '/company/benefits/competitiveSalary.png',
  },
  {
    label: 'Medical Insurance',
    value: 'medicalLeave',
    icon: '/company/benefits/medicalInsurance.png',
  },
  {
    label: 'Dental Insurance',
    value: 'dentalInsurance',
    icon: '/company/benefits/dentalInsurance.png',
  },
  {
    label: 'THR / Bonus system',
    value: 'bonusSystem',
    icon: '/company/benefits/bonusSystem.png',
  },
  {
    label: 'Period Leave',
    value: 'periodLeave',
    icon: '/company/benefits/periodLeave.png',
  },
  {
    label: 'Transportation',
    value: 'transport',
    icon: '/company/benefits/transportation.png',
  },
  {
    label: 'Free Lunch',
    value: 'freeLunch',
    icon: '/company/benefits/freeLunch.png',
  },
  {
    label: 'Free Food / Snacks',
    value: 'freeMeals',
    icon: '/company/benefits/freeFood.png',
  },
  {
    label: 'International Exposure',
    value: 'internationalExposure',
    icon: '/company/benefits/internationalExposure.png',
  },
  {
    label: 'Team Building Activity',
    value: 'teamBuilding',
    icon: '/company/benefits/teamBuilding.png',
  },
  {
    label: 'Self-development Allowance',
    value: 'selfDevelopmentAllowance',
    icon: '/company/benefits/selfDevelopmentAllowance.png',
  },
  {
    label: 'Casual Dress Code',
    value: 'casualDressCode',
    icon: '/company/benefits/casualDressCode.png',
  },
  {
    label: 'Childcare Assistance',
    value: 'childCareAssistance',
    icon: '/company/benefits/childCareAssistance.png',
  },
  {
    label: 'Company Outings',
    value: 'companyOutings',
    icon: '/company/benefits/companyOutings.png',
  },
  {
    label: 'Employee Discounts',
    value: 'employeeDiscounts',
    icon: '/company/benefits/employeeDiscounts.png',
  },
  {
    label: 'Gym Membership',
    value: 'gymMembership',
    icon: '/company/benefits/gymMembership.png',
  },
  {
    label: 'Paid Maternity / Paternity Leave',
    value: 'maternityLeave',
    icon: '/company/benefits/maternityLeave.png',
  },
  {
    label: 'Paid Sick Days',
    value: 'paidSickDays',
    icon: '/company/benefits/paidSickDays.png',
  },
  {
    label: 'Pet-friendly Office',
    value: 'petFriendlyOffice',
    icon: '/company/benefits/petFriendlyOffice.png',
  },
  {
    label: 'Professional Development',
    value: 'professionalDevelopment',
    icon: '/company/benefits/professionalDevelopment.png',
  },
  {
    label: 'Recreational Area',
    value: 'recreationalArea',
    icon: '/company/benefits/recreationalArea.png',
  },
  {
    label: 'Vacation Time',
    value: 'vacationTime',
    icon: '/company/benefits/vacationTime.png',
  },
  {
    label: 'Wellness Program',
    value: 'wellnessProgram',
    icon: '/company/benefits/wellnessProgram.png',
  },
  {
    label: 'Others',
    value: 'other',
    icon: '/company/benefits/other.png',
  },
];

export const listJobStatus = [
  {
    label: 'Actively looking for the next 3 months ',
    tableLabel: 'Actively looking',
    value: 'activelyLooking',
  },
  {
    label: 'Hired, but open for more exciting opportunities',
    tableLabel: 'Open',
    value: 'openOpportunity',
  },
  {
    label: 'Not actively looking, but might consider an invitation',
    tableLabel: 'Open For Invitation',
    value: 'notActivelyLooking',
  },
  {
    label: 'Not open for opportunities',
    tableLabel: 'Not Open',
    value: 'closedOpportunity',
  },
];

export const listCommitment = [
  {
    label: 'Full time',
    value: 'fullTime',
  },
  {
    label: 'Part time',
    value: 'partTime',
  },
  {
    label: 'Contract',
    value: 'contract',
  },
  {
    label: 'Internship',
    value: 'internship',
  },
];

export const companyCultureList: CompanyCulture[] = [
  {
    key: 'innovative',
    name: 'Innovative',
    icon: <InnovativeIcon />,
    subtitle:
      'We strive to constantly pursue innovation of our products and services ',
  },
  {
    key: 'nurturing',
    name: 'Nurturing',
    icon: <NurturingIcon />,
    subtitle:
      "Our working environment prioritizes encouragement for employee's growth and development",
  },
  {
    key: 'passionate',
    name: 'Passionate',
    icon: <PassionateIcon />,
    subtitle:
      'Strong belief and efforts are the base to achieve long term success ',
  },
  {
    key: 'vibrant',
    name: 'Vibrant',
    icon: <VibrantIcon />,
    subtitle:
      'Our company is comprised of enthusiastic and positive-spirited people',
  },
  {
    key: 'adaptable',
    name: 'Adaptable',
    icon: <AdaptableIcon />,
    subtitle: 'Our team is flexible and resilient in the face of adversities ',
  },
  {
    key: 'collaborative',
    name: 'Collaborative',
    icon: <CollaborativeIcon />,
    subtitle: 'Here, we work together to make the dream work',
  },
  {
    key: 'fast-paced',
    name: 'Fast-Paced',
    icon: <FastPacedIcon />,
    subtitle:
      'Swift in handling new challenges and producing new ideas to improve',
  },
  {
    key: 'open',
    name: 'Open',
    icon: <OpenIcon />,
    subtitle: 'Outstanding result stems from a free and welcoming enviroment',
  },
  {
    key: 'driven',
    name: 'Driven',
    icon: <DrivenIcon />,
    subtitle:
      'We hope to confidently inspire our team to maintain high level of motivation in completing their duties',
  },
  {
    key: 'progressive',
    name: 'Progressive',
    icon: <ProgessiveIcon />,
    subtitle:
      'Continuous ambition for progress and finding the best means to make it happen',
  },
  {
    key: 'energetic',
    name: 'Energetic',
    icon: <EnergeticIcon />,
    subtitle: 'Here, we work together to make the dream work',
  },
  {
    key: 'integrity',
    name: 'Integrity',
    icon: <IntegrityIcon />,
    subtitle:
      'We uphold honesty and virtue in every single product/service we deliver',
  },
  {
    key: 'quality',
    name: 'Quality',
    icon: <QualityIcon />,
    subtitle:
      'We put great emphasis on preserving the high existing standard of our products/services',
  },
  {
    key: 'discipline',
    name: 'Discipline',
    icon: <DisciplineIcon />,
    subtitle:
      'We seek to ensure that every individual contributes accordingly to the rules and agreement to achieve company objectives',
  },
  {
    key: 'trustworthy',
    name: 'Trustworthy',
    icon: <TrustWorthyIcon />,
    subtitle:
      "Consistently making an all-out effort to sustain people's trust of our products/services",
  },
  {
    key: 'fun',
    name: 'Fun',
    icon: <FunIcon />,
    subtitle: "We consider employee's happiness as a part of ours",
  },
  {
    key: 'respectful',
    name: 'Respectful',
    icon: <RespectfulIcon />,
    subtitle: 'Everyone should be treated with courtesy and professionalism',
  },
  {
    key: 'balanced',
    name: 'Balanced',
    icon: <BalanceIcon />,
    subtitle:
      'For us, employee productivity is just as important as their well-being',
  },
];

export const jobPerkPredefinedValues = [
  {
    value: 'handsOnExperience',
    label: 'Hands-on experience on vital business initiatives',
  },
  {
    value: 'hugeLearningCurve',
    label: 'Huge learning curve, surrounded by passionate team',
  },
  {
    value: 'workCloselyWithCLevels',
    label: 'Work closely with the C-levels & the founding team',
  },
  {
    value: 'workInFastGrowingAndWellFundedStartup',
    label: 'Work in fast growing & well-funded startup',
  },
  {
    value: 'flexibleWorkingHours',
    label: 'Flexible working hours',
  },
  {
    value: 'casualDressAndRelaxedEnvironment',
    label: 'Casual dress and relaxed working environment',
  },
  {
    value: 'goldenOpportunityToDevelopSkills',
    label:
      'Golden opportunity to develop your existing skills and learn new ones',
  },
  {
    value: 'funSociableEnvironment',
    label: 'Fun sociable environment with regular company meals and events',
  },
];

export const REFRESH_TOKEN_NOT_FOUND = 'Refresh token not found';
export const TOKEN_EXPIRED_ERROR = 'TOKEN_EXPIRED,_PLEASE_LOGIN_AGAIN';
export const EMAIL_DOMAIN_NOT_ALLOWED_TO_REGISTER =
  'Email domain not allowed to register';
export const SOCMED_DATA_INVALID = 'Socmed data INVALID';
export const EMAIL_NOT_FOUND = 'Email Not Found';
export const ACCOUNT_REGISTERED_WITH_SOCIAL_MEDIA =
  'ACCOUNT_REGISTERED_WITH_SOCIAL_MEDIA';
export const WA_MESSAGE_DOMAIN_RESTRICTED =
  'Hi, Deall! My domain is restricted & I can’t sign up. Can you help?';

export const DEALL_EMPLOYER_DASHBOARD_URL =
  generateCurrentEnvironment() === 'production'
    ? 'https://employer.dealls.com'
    : generateCurrentEnvironment() === 'staging'
    ? 'https://employer-staging.dealls.com'
    : 'https://employer-dev.dealls.com';

export const DEALL_CAREER_PORTAL_URL =
  generateCurrentEnvironment() === 'production'
    ? 'https://dealls.com'
    : generateCurrentEnvironment() === 'staging'
    ? 'https://talent-staging.dealls.com'
    : 'https://talent-dev.dealls.com';

export const MAXIMUM_EWALLET_AMOUNT = 10_000_000;

export const NOTIFICATION_CATEGORY = {
  forYou: 'COMPANY_FOR_YOU',
  all: 'COMPANY_ALL',
};

export const notificationCategories = [
  {
    label: 'For You',
    value: NOTIFICATION_CATEGORY.forYou,
  },
  {
    label: 'All',
    value: NOTIFICATION_CATEGORY.all,
  },
];

export const NOTIFICATION_ACTION = {
  processApplicantsReminderReceived: 'processApplicantsReminderReceived',
  applicantsReceived: 'applicantsReceived',
  studentRecommendationSetReady: 'studentRecommendationSetReady',
  companyTrialSubsActivated: 'companyTrialSubsActivated',
  jobPostTrialLimitReached: 'jobPostTrialLimitReached',
  companyMemberTrialLimitReached: 'companyMemberTrialLimitReached',
  awardedAsBestCompany: 'awardedAsBestCompany',
  companySubscriptionPurchased: 'companySubscriptionPurchased',
  jobInterviewReminderReceived: 'jobInterviewReminderReceived',
  companyTrialSubsUpgraded: 'companyTrialSubsUpgraded',
  jobTechnicalTestAssessmentReminderReceived:
    'jobTechnicalTestAssessmentReminderReceived',
  completeCompanyFeaturedMembersReminderReceived:
    'completeCompanyFeaturedMembersReminderReceived',
  jobTechTestDeadlineExtensionReceived: 'jobTechTestDeadlineExtensionReceived',
  jobTechTestSubmittedReceived: 'jobTechTestSubmittedReceived',
  employerCustomStepAnnouncementReceived:
    'employerCustomStepAnnouncementReceived',
  invitedEmployerJoinReceived: 'invitedEmployerJoinReceived',
  userCompanyPermissionChangedReceived: 'userCompanyPermissionChangedReceived',
};

export const experienceRequirementList = [
  {
    label: '1st year college student',
    value: 1,
  },
  {
    label: 'Freshgrad',
    value: 0,
  },
  {
    label: '2nd year college student',
    value: 2,
  },
  {
    label: '1-3 years of experience',
    value: 5,
  },
  {
    label: '3rd year college student',
    value: 3,
  },
  {
    label: '4-5 years of experience',
    value: 6,
  },
  {
    label: '4th year college student',
    value: 4,
  },
  {
    label: '5+ years of experience',
    value: 7,
  },
];

export const experienceRequirementListShortVersion = [
  {
    label: 'College Students (1st–2nd year)',
    value: 8,
  },
  {
    label: '1+ year',
    value: 5,
  },
  {
    label: 'College Students (3rd–4th year)',
    value: 9,
  },
  {
    label: '3+ years',
    value: 6,
  },
  {
    label: 'Freshgrad',
    value: 0,
  },
  {
    label: '5+ years',
    value: 7,
  },
];

export const defaultApplicantListTabs = [
  {
    id: 'rejected',
    title: 'Rejected',
    status: ['rejected', 'expired'],
    type: 'default',
  },
  {
    id: 'notProcessed',
    title: 'CV Screening',
    status: ['notProcessed', 'curatedInProgress'],
    type: 'default',
  },
  {
    id: 'accepted',
    title: 'CV Shortlisted',
    status: 'accepted',
    type: 'default',
  },
];

/**
 * @deprecated use APPLICANT_STATUS from jobs/constants instead
 */
export const APPLICANT_STATUS = {
  techTestSent: 'techTestSent',
  techTestSubmitted: 'techTestSubmitted',
  notProcessed: 'notProcessed',
  rejected: 'rejected',
  waitListed: 'waitListed',
  expired: 'expired',
  rejectedBySystem: 'rejectedBySystem',
};

export const SUBSCRIPTION_PLAN_PERIOD = {
  annual: 'annual',
  quartal: 'quartal',
  retail: 'retail',
  custom: 'custom',
};

export const TECHNICAL_TEST_ADD_ELEMENT = {
  companyName: 'Company Name',
  jobTitle: 'Job Title',
  talentName: 'Talent Name',
  yourName: 'Your Name',
  roleName: 'Role Name',
};

export const TECHNICAL_TEST_ADD_ELEMENT_OPTIONS = [
  { name: 'Company Name', value: TECHNICAL_TEST_ADD_ELEMENT.companyName },
  { name: 'Job Title', value: TECHNICAL_TEST_ADD_ELEMENT.jobTitle },
  { name: 'Talent Name', value: TECHNICAL_TEST_ADD_ELEMENT.talentName },
  { name: 'Your Name', value: TECHNICAL_TEST_ADD_ELEMENT.yourName },
];

export const STUDENT_DETAIL_PAGES = {
  TALENT_HUNT_DETAIL: 'talentHuntDetail',
  TALENT_DB_DETAIL: 'talentDbDetail',
  JOB_APPLICANT_DETAIL: 'jobApplicantDetail',
  NULL: null,
} as const;

export const ALLOWED_IMAGES_DOMAINS = [
  'dev-sejutacita-id.storage.googleapis.com',
  'cdn.sejutacita.id',
];

export const SHORTLIST_REJECT_MESSAGE_VARIABLES = [
  '{Company Name}',
  '{Job Title}',
  '{Your Name}',
  '{Talent Name}',
  '{Step Name}',
] as const;
export const MOVE_APPLICANT_MESSAGE_VARIABLES = [
  '{Company Name}',
  '{Old Job Title}',
  '{New Job Title}',
  '{Talent Name}',
  '{Step Name}',
  '{New Step Name}',
] as const;

export const BREAKPOINTS = {
  tablet: 768,
} as const;

export const PUBLIC_PATHS = [
  '/sign-in',
  '/sign-up',
  '/verification',
  '/forgot-password',
  '/reset-password',
  '/verification-success',
];

export const ONBOARDING_PATHS = ['/onboarding'];
export const SUBSCRIPTION_PATHS = ['/subscription'];

export const DAYS_IN_MONTH = 30;
export const QUARTERLY_DURATION_MONTHS = 3;
export const BIANNUALLY_DURATION_MONTHS = 6;
export const ANNUALLY_DURATION_MONTHS = 12;
export const HOURS_IN_A_DAY = 24;

export const UNLEASH_TOGGLES = {
  ed_exportApplicants: 'ed_export-applicants',
  ed_importApplicants: 'ed_import-applicants',
  ed_hrisFeature: 'ed_hris-feature',
  ed_mergeInboundAndOutbound: 'ed_merge-inbound-and-outbound',
};
