import { InfoCircleOutlined } from '@ant-design/icons';
import { message as antMessage } from 'antd';
import {
  ArgsProps,
  MessageApi,
  MessageInstance,
  MessageType,
  NoticeType,
} from 'antd/lib/message';
import clsx from 'clsx';
import React, { Key } from 'react';

import CircleCheckIcon from '@/common/icons/CircleCheckIcon';
import { ErrorIcon, SuccessIcon, WarningIcon } from '@/icons/messages';

import styles from './index.module.scss';

const PrimaryMessage = (props: PrimaryProps) => {
  const generateIcon = () => {
    if (React.isValidElement(props.icon)) {
      return props.icon;
    }

    switch (props.icon) {
      case 'check':
        return <CircleCheckIcon className="text-violet-500" />;
      default:
        return <CircleCheckIcon className="text-violet-500" />;
    }
  };

  return antMessage.open({
    className: styles.antd_message_primary,
    content: (
      <div className={clsx('flex gap-x-2', props.containerClassName)}>
        <div className="mt-[3px]">{generateIcon()}</div>
        <div className="w-full text-left text-[13px] font-medium leading-[20px] text-neutral-1000">
          {props.content}
        </div>
      </div>
    ),
    duration: props?.duration,
  });
};

export const message: Pick<MessageInstance, 'open'> & {
  close: (key: Key | Key[]) => void;
  open: (
    args: Omit<ArgsProps, 'content'> & {
      iconClassName?: string;
      title?: string;
      subtitle?: string;
      content?: React.ReactNode;
    }
  ) => void;
  destroy: MessageApi['destroy'];
  primary: (props: PrimaryProps) => MessageType;
} = {
  open: (
    props: Omit<ArgsProps, 'content'> & {
      iconClassName?: string;
      title?: string;
      subtitle?: string;
      content?: React.ReactNode;
    }
  ) => {
    const { title, subtitle, content, type, iconClassName, key } = props;

    const customContent = !!content;
    const defaultDuration = 10;

    const generateIcon = (type: NoticeType) => {
      switch (type) {
        case 'success':
          return <SuccessIcon />;
        case 'warning':
          return <WarningIcon />;
        case 'error':
          return <ErrorIcon className="text-danger-600" />;
        default:
          return <InfoCircleOutlined />;
      }
    };

    const generateCloseIcon = (type: NoticeType) => {
      switch (type) {
        case 'success':
          return <SuccessIcon.Close />;
        case 'warning':
          return <WarningIcon.Close />;
        case 'error':
          return <ErrorIcon.Close />;
        default:
          return <InfoCircleOutlined />;
      }
    };

    return antMessage.open({
      key: key || 'message',
      className: clsx(styles[type || 'default'], styles['custom-message']),
      content: (
        <>
          <div className="flex items-start justify-between">
            {customContent ? (
              <div className="flex">
                <span
                  className={clsx(
                    'mr-1 flex h-[18px] shrink-0 items-center justify-center',
                    iconClassName
                  )}
                >
                  {generateIcon(type as NoticeType)}
                </span>
                <span className="text-left text-sm text-white md:min-w-full">
                  {content}
                </span>
              </div>
            ) : (
              <div className="flex flex-col ">
                <div className="flex items-center gap-2 text-white">
                  {generateIcon(type as NoticeType)}
                  <span className="inline-block text-[13px] font-bold leading-[150%] text-white">
                    {title}
                  </span>
                </div>{' '}
                <span className="ml-[22px] mt-[2px] text-left text-[11px] text-white md:min-w-full">
                  {subtitle}
                </span>
              </div>
            )}

            <button
              type="button"
              className="ml-6 shrink-0 text-white"
              onClick={() => {
                antMessage.destroy(key || 'message');
              }}
            >
              {generateCloseIcon(type as NoticeType)}
            </button>
          </div>
        </>
      ),
      duration: props?.duration || defaultDuration,
    });
  },

  close: (key: Key | Key[]) => {
    if (Array.isArray(key)) {
      key.forEach((item) => {
        antMessage.destroy(item);
      });
    }

    if (typeof key === 'string') {
      antMessage.destroy(key);
    }
  },
  destroy: antMessage.destroy,
  primary: PrimaryMessage,
};

type PrimaryProps = ArgsProps & {
  containerClassName?: string;
  icon?: React.ReactNode | 'check';
};
