interface LocalStorageServiceInterface<T> {
  get<K extends keyof T>(key: K): T[K] | null;

  set<K extends keyof T>(key: K, value: T[K]): void;

  remove<K extends keyof T>(key: K): void;

  clear(): void;
}

/**
 * When using instance LocalStorageService, you need to define
 * 1. `[prefix]LocalStorageKey`` enum. eg: `userLocalStorageKey`
 * 2. `[prefix]LocalStorageModel` interface. eg: `userLocalStorageModel`
 *
 * @example
 * ```ts
 * enum userLocalStorageKey {
 *  token: 'token',
 *  userInfo: 'userInfo',
 * }
 *
 * interface userLocalStorageModel {
 *  [userLocalStorageKey.token]: string;
 *  [userLocalStorageKey.userInfo]: UserInfo;
 * }
 *  ```
 *
 * then you can use it like this:
 * ```ts
 * const userLocalStorageService = new LocalStorageService<userLocalStorageModel>(localStorage);
 *  ```
 */

/**
 * Bare Storage Service:
 *  - Full implementation without checking the presence of `localStorage` in the environment.
 */
class StorageService<T> implements LocalStorageServiceInterface<T> {
  constructor(private storage: Storage | undefined) {}

  set<K extends keyof T>(key: K, value: T[K]): void {
    if (this.storage === undefined) {
      return;
    }
    this.storage.setItem(key.toString(), JSON.stringify(value));
  }

  get<K extends keyof T>(key: K): T[K] | null {
    if (this.storage === undefined) {
      return null;
    }
    const value = this.storage.getItem(key.toString());

    if (
      value === null ||
      value === 'null' ||
      value === undefined ||
      value === 'undefined'
    ) {
      return null;
    }

    return JSON.parse(value);
  }

  remove<K extends keyof T>(key: K): void {
    if (this.storage === undefined) {
      return;
    }
    this.storage.removeItem(key.toString());
  }

  clear(): void {
    if (this.storage === undefined) {
      return;
    }
    this.storage.clear();
  }
}

/**
 * Local Storage Service:
 *  - Check the presence of `localStorage` in the environment.
 */
class LocalStorageService<T> extends StorageService<T> {
  public localStorageEnabled: boolean;

  constructor() {
    let storage: Storage | undefined;

    if (typeof window !== 'undefined') {
      try {
        /**
         * Checking `window !== undefined` is not enough as `window.localStorage` is `undefined` in some cases.
         */
        storage = window.localStorage;
      } catch (e) {
        storage = undefined;
      }
    }

    super(storage);

    this.localStorageEnabled = !!storage;
  }
}

export default LocalStorageService;
