import { useAtom } from 'jotai';
import moment from 'moment';
import { useEffect } from 'react';

import {
  analyticsNewReleaseClickedAtom,
  analyticsNewReleaseDateAtom,
  analyticsReleaseDate,
} from '../atoms';
import {
  ANALYTICS_NEW_RELEASE_CLICK_STATUS_STRG_IDENTIFIER,
  ANALYTICS_NEW_RELEASE_DATE_STRG_IDENTIFIER,
} from '../constants';

const useAnalyticsBadge = () => {
  const [releaseDate, setReleaseDate] = useAtom(analyticsNewReleaseDateAtom);
  const [buttonClicked, setButtonClicked] = useAtom(
    analyticsNewReleaseClickedAtom
  );

  const today = moment();
  const startDate = moment(releaseDate || analyticsReleaseDate);
  const endDate = moment(startDate).add(1, 'months');

  const stillWithinReleasePeriod = today.isBetween(
    startDate,
    endDate,
    null,
    '[]'
  );

  const flushAnalyticsReleaseStorage = () => {
    try {
      localStorage.removeItem(ANALYTICS_NEW_RELEASE_DATE_STRG_IDENTIFIER);
      localStorage.removeItem(
        ANALYTICS_NEW_RELEASE_CLICK_STATUS_STRG_IDENTIFIER
      );
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    if (stillWithinReleasePeriod) {
      setReleaseDate(releaseDate || analyticsReleaseDate);
    }

    if (releaseDate && !stillWithinReleasePeriod) {
      flushAnalyticsReleaseStorage();
    }
  }, [releaseDate]);

  return {
    setReleaseDate: (date: string) => setReleaseDate(new Date(date!)),
    labelEnabled: !buttonClicked && stillWithinReleasePeriod,
    turnOffNewBadge: () => setButtonClicked(true),
  };
};

export default useAnalyticsBadge;
