const RightArrowIcon = (props) => {
  return (
    <svg
      width={props.width ?? '24'}
      height={props.height ?? '24'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M7.49978 14.6581C7.17538 14.3337 7.1751 13.8078 7.49914 13.4831L10.0245 10.9522C10.414 10.5619 10.414 9.92989 10.0245 9.53954L7.49914 7.0087C7.1751 6.68395 7.17538 6.15809 7.49978 5.8337C7.82442 5.50906 8.35077 5.50906 8.67542 5.8337L12.3805 9.53877C12.771 9.9293 12.771 10.5625 12.3805 10.953L8.67542 14.6581C8.35077 14.9827 7.82442 14.9827 7.49978 14.6581Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RightArrowIcon;
