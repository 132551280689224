import { SVGProps } from 'react';

const CircleCheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="14" height="14" rx="7" fill="currentColor" />
      <path
        d="M6.20719 8.37848C5.81661 8.76869 5.1837 8.76854 4.79331 8.37815L3.76758 7.35242C3.5727 7.15754 3.2569 7.15698 3.06133 7.35118C2.86478 7.54634 2.86422 7.86406 3.06008 8.05992L4.79297 9.79282C5.1835 10.1833 5.81666 10.1833 6.20719 9.79282L11.1475 4.8525C11.3422 4.65778 11.3422 4.34206 11.1475 4.14734C10.9528 3.95268 10.6372 3.9526 10.4425 4.14717L6.20719 8.37848Z"
        fill="white"
      />
    </svg>
  );
};

export default CircleCheckIcon;
