const MoreCircle = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 1.8334C11.4055 1.8334 14.1668 4.59407 14.1668 8.00007C14.1668 11.4054 11.4055 14.1667 8.00016 14.1667C4.59416 14.1667 1.8335 11.4054 1.8335 8.00007C1.8335 4.59473 4.59483 1.8334 8.00016 1.8334Z"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6262 8.00867H10.6322"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.95362 8.00867H7.95962"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.28102 8.00867H5.28702"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

MoreCircle.Primary = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3335 7.99993C1.3335 4.31993 4.3135 1.33327 8.00016 1.33327C11.6802 1.33327 14.6668 4.31993 14.6668 7.99993C14.6668 11.6799 11.6802 14.6666 8.00016 14.6666C4.3135 14.6666 1.3335 11.6799 1.3335 7.99993ZM5.0135 8.79993C4.5735 8.79993 4.2135 8.43993 4.2135 7.99993C4.2135 7.55993 4.5735 7.2006 5.0135 7.2006C5.4535 7.2006 5.80683 7.55993 5.80683 7.99993C5.80683 8.43993 5.4535 8.79993 5.0135 8.79993ZM7.20016 7.99993C7.20016 8.43993 7.56016 8.79993 8.00016 8.79993C8.44016 8.79993 8.7935 8.43993 8.7935 7.99993C8.7935 7.55993 8.44016 7.2006 8.00016 7.2006C7.56016 7.2006 7.20016 7.55993 7.20016 7.99993ZM10.1868 7.99993C10.1868 8.43993 10.5402 8.79993 10.9868 8.79993C11.4268 8.79993 11.7802 8.43993 11.7802 7.99993C11.7802 7.55993 11.4268 7.2006 10.9868 7.2006C10.5402 7.2006 10.1868 7.55993 10.1868 7.99993Z"
        fill="#6014C4"
      />
    </svg>
  );
};

export default MoreCircle;
