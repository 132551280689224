const LogoIcon = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92px"
      height="24px"
      viewBox="0 0 92 24"
      {...props}
    >
      <path
        d="M0 23.516V.488h8.21c6.532 0 11.317 4.254 11.317 11.532 0 7.277-4.816 11.5-11.347 11.5H0zm8.016-4.157c3.75 0 6.886-2.34 6.886-7.34 0-5.003-3.101-7.374-6.851-7.374H4.46v14.714zm28.828-.422c-.809 2.762-3.328 5.067-7.242 5.067-4.364 0-8.211-3.149-8.211-8.543 0-5.098 3.75-8.41 7.824-8.41 4.914 0 7.855 3.148 7.855 8.281 0 .617-.066 1.266-.066 1.332H25.625c.098 2.11 1.875 3.637 4.008 3.637 2.004 0 3.105-1.004 3.62-2.434zm-4.008-5.23c-.066-1.59-1.098-3.152-3.559-3.152-2.226 0-3.457 1.691-3.554 3.152zm10.75.617l3.914-.582c.902-.133 1.195-.586 1.195-1.137 0-1.14-.875-2.082-2.683-2.082-1.875 0-2.91 1.204-3.04 2.598l-3.816-.809c.258-2.503 2.555-5.261 6.82-5.261 5.044 0 6.919 2.855 6.919 6.07v7.863c0 .844.097 1.98.195 2.532h-3.945c-.098-.422-.16-1.301-.16-1.914-.81 1.265-2.329 2.37-4.688 2.37-3.395 0-5.465-2.308-5.465-4.808.004-2.86 2.105-4.45 4.754-4.84zm5.11 2.73v-.714l-3.59.55c-1.098.165-1.973.782-1.973 2.016 0 .942.68 1.852 2.07 1.852 1.809 0 3.492-.88 3.492-3.703zm7.706 6.462V0h4.301v23.516zm7.938 0V0h4.3v23.516zm10.547-5.262c.093 1.27 1.035 2.437 2.906 2.437 1.426 0 2.102-.746 2.102-1.593 0-.715-.485-1.297-1.711-1.559l-2.102-.484c-3.07-.684-4.46-2.535-4.46-4.778 0-2.859 2.519-5.23 5.944-5.23 4.528 0 6.047 2.89 6.239 4.613l-3.586.813c-.13-.942-.809-2.145-2.617-2.145-1.133 0-2.04.684-2.04 1.594 0 .777.583 1.265 1.458 1.43l2.261.484c3.137.652 4.719 2.566 4.719 4.906 0 2.598-2.004 5.262-6.172 5.262-4.785 0-6.433-3.121-6.629-4.938zm0 0"
        fill="#fff"
      />
      <path
        d="M90.984 16.5h-3.136L86.813.488h5.207zm-1.582 2.113a2.6 2.6 0 012.586 2.598 2.572 2.572 0 01-2.586 2.566c-1.39 0-2.523-1.136-2.523-2.566s1.133-2.598 2.523-2.598zm0 0"
        fill="#ffe156"
      />
    </svg>
  );
};

LogoIcon.Primary = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92px"
      height="24px"
      viewBox="0 0 92 24"
      className={className}
    >
      <path
        d="M0 23.516V.488h8.21c6.532 0 11.317 4.254 11.317 11.532 0 7.277-4.816 11.5-11.347 11.5H0zm8.016-4.157c3.75 0 6.886-2.34 6.886-7.34 0-5.003-3.101-7.374-6.851-7.374H4.46v14.714zm28.828-.422c-.809 2.762-3.328 5.067-7.242 5.067-4.364 0-8.211-3.149-8.211-8.543 0-5.098 3.75-8.41 7.824-8.41 4.914 0 7.855 3.148 7.855 8.281 0 .617-.066 1.266-.066 1.332H25.625c.098 2.11 1.875 3.637 4.008 3.637 2.004 0 3.105-1.004 3.62-2.434zm-4.008-5.23c-.066-1.59-1.098-3.152-3.559-3.152-2.226 0-3.457 1.691-3.554 3.152zm10.75.617l3.914-.582c.902-.133 1.195-.586 1.195-1.137 0-1.14-.875-2.082-2.683-2.082-1.875 0-2.91 1.204-3.04 2.598l-3.816-.809c.258-2.503 2.555-5.261 6.82-5.261 5.044 0 6.919 2.855 6.919 6.07v7.863c0 .844.097 1.98.195 2.532h-3.945c-.098-.422-.16-1.301-.16-1.914-.81 1.265-2.329 2.37-4.688 2.37-3.395 0-5.465-2.308-5.465-4.808.004-2.86 2.105-4.45 4.754-4.84zm5.11 2.73v-.714l-3.59.55c-1.098.165-1.973.782-1.973 2.016 0 .942.68 1.852 2.07 1.852 1.809 0 3.492-.88 3.492-3.703zm7.706 6.462V0h4.301v23.516zm7.938 0V0h4.3v23.516zm10.547-5.262c.093 1.27 1.035 2.437 2.906 2.437 1.426 0 2.102-.746 2.102-1.593 0-.715-.485-1.297-1.711-1.559l-2.102-.484c-3.07-.684-4.46-2.535-4.46-4.778 0-2.859 2.519-5.23 5.944-5.23 4.528 0 6.047 2.89 6.239 4.613l-3.586.813c-.13-.942-.809-2.145-2.617-2.145-1.133 0-2.04.684-2.04 1.594 0 .777.583 1.265 1.458 1.43l2.261.484c3.137.652 4.719 2.566 4.719 4.906 0 2.598-2.004 5.262-6.172 5.262-4.785 0-6.433-3.121-6.629-4.938zm0 0"
        fill="#6913d8"
      />
      <path
        d="M90.984 16.5h-3.136L86.813.488h5.207zm-1.582 2.113a2.6 2.6 0 012.586 2.598 2.572 2.572 0 01-2.586 2.566c-1.39 0-2.523-1.136-2.523-2.566s1.133-2.598 2.523-2.598zm0 0"
        fill="#ffe156"
      />
    </svg>
  );
};

export default LogoIcon;
