import React from 'react';

const BalanceIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3749 30.4408V5.87389M37.6488 3.7062H22.5426M18.2072 3.7062H3.10107M28.758 34.4508H11.9911C11.0475 34.4508 10.1426 34.8256 9.47541 35.4928C8.80821 36.16 8.43338 37.0649 8.43338 38.0085V38.4616H32.3157V38.0085C32.3157 37.0649 31.9409 36.16 31.2737 35.4928C30.6065 34.8256 29.7016 34.4508 28.758 34.4508V34.4508ZM24.4949 30.4408H16.2542C15.3106 30.4408 14.4057 30.8156 13.7385 31.4828C13.0713 32.15 12.6965 33.055 12.6965 33.9985V34.4508H28.0518V33.9985C28.0518 33.055 27.677 32.15 27.0098 31.4828C26.3426 30.8156 25.4377 30.4408 24.4942 30.4408H24.4949ZM33.1403 22.1085C29.7957 22.1085 27.6203 18.5893 29.1165 15.5977L33.1403 3.70312L37.1642 15.5977C38.6603 18.5893 36.4849 22.1085 33.1411 22.1085H33.1403ZM7.60877 22.1085C4.26415 22.1085 2.08877 18.5893 3.58492 15.5977L7.608 3.70312L11.6326 15.5977C13.128 18.5893 10.9534 22.1085 7.60877 22.1085V22.1085Z"
        stroke="url(#paint0_linear_28_1746)"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3747 5.87384C21.5719 5.87384 22.5424 4.90333 22.5424 3.70614C22.5424 2.50896 21.5719 1.53845 20.3747 1.53845C19.1775 1.53845 18.207 2.50896 18.207 3.70614C18.207 4.90333 19.1775 5.87384 20.3747 5.87384Z"
        stroke="url(#paint1_linear_28_1746)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.16699 16.9077H12.0493M28.6993 16.9077H37.5808"
        stroke="url(#paint2_linear_28_1746)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_28_1746"
          x1="20.3749"
          y1="3.70312"
          x2="20.3749"
          y2="38.4616"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_28_1746"
          x1="20.3747"
          y1="1.53845"
          x2="20.3747"
          y2="5.87384"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_28_1746"
          x1="20.3739"
          y1="16.9077"
          x2="20.3739"
          y2="17.9077"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BalanceIcon;
