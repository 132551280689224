const GoldCloseIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2848_50514)">
        <path
          d="M15.2459 5.92913C15.5704 5.60466 15.5704 5.07859 15.2459 4.75413C14.9214 4.42966 14.3954 4.42966 14.0709 4.75413L10.7072 8.11785C10.3167 8.50838 9.6835 8.50838 9.29297 8.11785L5.92925 4.75413C5.60478 4.42966 5.07872 4.42966 4.75425 4.75413C4.42978 5.07859 4.42978 5.60466 4.75425 5.92913L8.11797 9.29285C8.5085 9.68338 8.5085 10.3165 8.11797 10.7071L4.75425 14.0708C4.42978 14.3953 4.42978 14.9213 4.75425 15.2458C5.07872 15.5703 5.60478 15.5703 5.92925 15.2458L9.29297 11.8821C9.6835 11.4915 10.3167 11.4915 10.7072 11.8821L14.0709 15.2458C14.3954 15.5703 14.9214 15.5703 15.2459 15.2458C15.5704 14.9213 15.5704 14.3953 15.2459 14.0708L11.8822 10.7071C11.4917 10.3165 11.4917 9.68338 11.8822 9.29285L15.2459 5.92913Z"
          fill="#A76603"
        />
      </g>
      <defs>
        <clipPath id="clip0_2848_50514">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoldCloseIcon;
