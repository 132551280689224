const CheckboxIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.90906"
        y="2.90906"
        width="10.1818"
        height="10.1818"
        rx="1.27273"
        fill="#1A1A1A"
      />
      <path
        d="M7.38972 9.02483C7.14118 9.2731 6.73847 9.27301 6.49004 9.02461L5.71459 8.24926C5.57679 8.11148 5.35351 8.11108 5.21522 8.24838V8.24838C5.07621 8.38639 5.07582 8.61108 5.21433 8.74958L6.48983 10.0249C6.73834 10.2734 7.14122 10.2734 7.38972 10.0249L10.9334 6.48171C11.0711 6.34401 11.0711 6.12074 10.9334 5.98304V5.98304C10.7957 5.84541 10.5726 5.84536 10.4349 5.98292L7.38972 9.02483Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckboxIcon;
