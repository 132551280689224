const HeartIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.91466 7.73221C1.19932 5.49888 2.03532 2.94621 4.37999 2.19088C5.61332 1.79288 6.97466 2.02755 7.99999 2.79888C8.96999 2.04888 10.3813 1.79555 11.6133 2.19088C13.958 2.94621 14.7993 5.49888 14.0847 7.73221C12.9713 11.2722 7.99999 13.9989 7.99999 13.9989C7.99999 13.9989 3.06532 11.3135 1.91466 7.73221Z"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 4.46655C11.3801 4.69722 11.8841 5.33389 11.9447 6.08122"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

HeartIcon.Primary = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5666 1.6671C10.9873 1.6671 11.4073 1.72643 11.8066 1.86043C14.2673 2.66043 15.1539 5.36043 14.4133 7.72043C13.9933 8.92643 13.3066 10.0271 12.4073 10.9264C11.1199 12.1731 9.70726 13.2798 8.1866 14.2331L8.01993 14.3338L7.8466 14.2264C6.3206 13.2798 4.89993 12.1731 3.6006 10.9198C2.70726 10.0204 2.01993 8.92643 1.59326 7.72043C0.83993 5.36043 1.7266 2.66043 4.21393 1.84643C4.40726 1.77977 4.6066 1.7331 4.8066 1.7071H4.8866C5.07393 1.67977 5.25993 1.6671 5.4466 1.6671H5.51993C5.93993 1.67977 6.3466 1.7531 6.7406 1.8871H6.77993C6.8066 1.89977 6.8266 1.91377 6.83993 1.92643C6.98726 1.97377 7.1266 2.0271 7.25993 2.10043L7.51326 2.21377C7.57448 2.24641 7.64319 2.2963 7.70257 2.33941C7.74019 2.36673 7.77407 2.39133 7.79993 2.4071C7.81081 2.41352 7.82187 2.41997 7.83301 2.42648C7.89018 2.45985 7.94972 2.49461 7.99993 2.5331C8.7406 1.9671 9.63993 1.66043 10.5666 1.6671ZM12.3399 6.4671C12.6133 6.45977 12.8466 6.24043 12.8666 5.95976V5.88043C12.8866 4.94643 12.3206 4.10043 11.4599 3.77377C11.1866 3.67977 10.8866 3.8271 10.7866 4.1071C10.6933 4.3871 10.8399 4.69377 11.1199 4.7931C11.5473 4.9531 11.8333 5.37377 11.8333 5.83977V5.86043C11.8206 6.0131 11.8666 6.16043 11.9599 6.27377C12.0533 6.3871 12.1933 6.4531 12.3399 6.4671Z"
        fill="#6014C4"
      />
    </svg>
  );
};

export default HeartIcon;
