const ArrowBottomIcon = (props) => {
  const { fill = '#767676' } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.58782 7.74538C5.91221 7.42099 6.43807 7.4207 6.76282 7.74474L9.29366 10.2701C9.68401 10.6596 10.316 10.6596 10.7063 10.2701L13.2372 7.74474C13.5619 7.4207 14.0878 7.42099 14.4122 7.74538C14.7368 8.07003 14.7368 8.59638 14.4122 8.92102L10.7071 12.6261C10.3166 13.0166 9.68342 13.0166 9.29289 12.6261L5.58782 8.92102C5.26318 8.59638 5.26318 8.07003 5.58782 7.74538Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowBottomIcon;
