import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type HeaderState = {
  isHasBackButton: boolean;
  backButtonName: string;
  backButtonDestination: string;
  backgroundColor: string;
  iconColor: string;
  textColor: string;
};

type HeaderContextType = {
  headerData: HeaderState;
  setHeaderData: Dispatch<SetStateAction<HeaderState>>;
};

const HeaderContext = createContext<HeaderContextType>({} as HeaderContextType);

export const HeaderProvider = ({ children }) => {
  const [headerData, setHeaderData] = useState({
    isHasBackButton: false,
    backButtonName: '',
    backButtonDestination: '/',
    backgroundColor: 'transparent',
    iconColor: '#484848',
    textColor: '#fff',
  });

  return (
    <HeaderContext.Provider
      value={{
        headerData,
        setHeaderData,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => {
  return useContext(HeaderContext);
};
