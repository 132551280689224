import { IConfig } from 'unleash-proxy-client';

export const UNLEASH_CONFIG: IConfig = {
  appName: 'job-portal-admin',
  refreshInterval: parseInt(
    process.env.NEXT_PUBLIC_UNLEASH_REFRESH_INTERVAL as string
  ),
  url: process.env.NEXT_PUBLIC_UNLEASH_URL as string,
  clientKey: process.env.NEXT_PUBLIC_UNLEASH_TOKEN as string,
  metricsInterval: 600,
};
