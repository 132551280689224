const CloseIcon = ({
  fill = '#767676',
  ...props
}: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.295 7.115C18.6844 6.72564 18.6844 6.09436 18.295 5.705C17.9056 5.31564 17.2744 5.31564 16.885 5.705L12.7071 9.88289C12.3166 10.2734 11.6834 10.2734 11.2929 9.88289L7.115 5.705C6.72564 5.31564 6.09436 5.31564 5.705 5.705C5.31564 6.09436 5.31564 6.72564 5.705 7.115L9.88289 11.2929C10.2734 11.6834 10.2734 12.3166 9.88289 12.7071L5.705 16.885C5.31564 17.2744 5.31564 17.9056 5.705 18.295C6.09436 18.6844 6.72564 18.6844 7.115 18.295L11.2929 14.1171C11.6834 13.7266 12.3166 13.7266 12.7071 14.1171L16.885 18.295C17.2744 18.6844 17.9056 18.6844 18.295 18.295C18.6844 17.9056 18.6844 17.2744 18.295 16.885L14.1171 12.7071C13.7266 12.3166 13.7266 11.6834 14.1171 11.2929L18.295 7.115Z"
        fill={fill}
      />
    </svg>
  );
};

CloseIcon.Medium = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8333 5.34175L14.6583 4.16675L9.99999 8.82508L5.34166 4.16675L4.16666 5.34175L8.82499 10.0001L4.16666 14.6584L5.34166 15.8334L9.99999 11.1751L14.6583 15.8334L15.8333 14.6584L11.175 10.0001L15.8333 5.34175Z"
        fill="currentColor"
      />
    </svg>
  );
};

CloseIcon.Small = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2457 1.92916C11.5701 1.60469 11.5701 1.07862 11.2457 0.754156C10.9212 0.429689 10.3951 0.429689 10.0707 0.754157L6.70694 4.11788C6.31642 4.50841 5.68325 4.50841 5.29273 4.11788L1.929 0.754157C1.60454 0.429689 1.07847 0.429689 0.754004 0.754156C0.429537 1.07862 0.429537 1.60469 0.754004 1.92916L4.11773 5.29288C4.50825 5.68341 4.50825 6.31657 4.11773 6.7071L0.754004 10.0708C0.429537 10.3953 0.429537 10.9214 0.754004 11.2458C1.07847 11.5703 1.60454 11.5703 1.929 11.2458L5.29273 7.8821C5.68325 7.49157 6.31642 7.49157 6.70694 7.8821L10.0707 11.2458C10.3951 11.5703 10.9212 11.5703 11.2457 11.2458C11.5701 10.9214 11.5701 10.3953 11.2457 10.0708L7.88194 6.7071C7.49142 6.31657 7.49142 5.68341 7.88194 5.29288L11.2457 1.92916Z"
        fill="#484848"
      />
    </svg>
  );
};

export default CloseIcon;
