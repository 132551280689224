import { Modal } from 'antd';
import clsx from 'clsx';

import Button from '@/common/components/Button';
import UndrawConfidental from '@/modules/permissions/icons/UndrawConfidental';

import * as styles from './index.module.scss';

const AccessRequestedModal = ({
  isVisible: isOpen,
  setIsVisible,
  onCancel,
}) => {
  const handleClickCancel = () => {
    onCancel && onCancel();
    setIsVisible(false);
  };
  return (
    <Modal
      className={clsx(styles['access_requested_modal'])}
      open={isOpen}
      maskClosable={false}
      width={361}
      footer={null}
      onCancel={handleClickCancel}
    >
      <div className="p-4">
        <div className="flex">
          <UndrawConfidental className="m-auto" />
        </div>
        <div className="mt-[24px] text-center">
          <span className="block text-base font-bold leading-[130%] text-neutral-1000">
            Access requested
          </span>
          <p className="mt-1 text-xs font-medium leading-[150%] text-neutral-600">
            Request sent, you’ll be informed when it is responded. If there is
            no response within 3 days, you can come back and request again
          </p>
          <div className="mt-[24px] flex">
            <Button
              onClick={handleClickCancel}
              htmlType="submit"
              className="!mx-auto !h-[36px] !w-[108px] !rounded-[71px] !text-xs hover:!bg-violet-500 hover:!text-white"
            >
              Done
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AccessRequestedModal;
