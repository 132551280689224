const MultipleChoiceIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.09094"
        y="6.09094"
        width="3.81818"
        height="3.81818"
        rx="1.90909"
        fill="#1A1A1A"
      />
      <rect
        x="3.54542"
        y="3.54542"
        width="8.90909"
        height="8.90909"
        rx="4.45455"
        stroke="#1A1A1A"
        strokeWidth="1.27273"
      />
    </svg>
  );
};

export default MultipleChoiceIcon;
