const NoAvatarIcon = ({ width = 34, height = 34, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 28.1624C7.0217 26.5211 7.83096 24.6533 9 23.5C10.169 22.3467 11.7455 21.6997 13.3884 21.6988H20.895C22.5378 21.6997 24.1143 22.3467 25.2834 23.5C26.4524 24.6533 26.9783 26.5211 27 28.1624C24.2562 30.6328 20.6936 32 17 32C13.3064 32 9.74385 30.6328 7 28.1624ZM22.6299 12.625C22.6299 13.7375 22.2997 14.825 21.6811 15.75C21.0625 16.6751 20.1832 17.396 19.1545 17.8218C18.1257 18.2475 16.9938 18.3589 15.9017 18.1419C14.8096 17.9248 13.8064 17.3891 13.019 16.6024C12.2317 15.8158 11.6955 14.8135 11.4783 13.7224C11.261 12.6312 11.3725 11.5002 11.7986 10.4724C12.2247 9.44456 12.9463 8.56606 13.8722 7.94798C14.798 7.3299 15.8865 7 17 7C18.4931 7 19.9251 7.59263 20.981 8.64752C22.0368 9.70241 22.6299 11.1331 22.6299 12.625Z"
        fill="#7767E4"
      />
      <path
        d="M30.0738 27.8434C31.819 25.7454 33.0327 23.2584 33.6124 20.5926C34.1921 17.9269 34.1207 15.1608 33.4042 12.5285C32.6877 9.89614 31.3473 7.47494 29.4962 5.4697C27.6452 3.46445 25.338 1.93417 22.7699 1.00829C20.2017 0.0824064 17.4482 -0.211826 14.7422 0.150478C12.0362 0.512782 9.45732 1.52097 7.22375 3.08974C4.99017 4.65851 3.1676 6.74173 1.91021 9.16315C0.652826 11.5846 -0.00238485 14.273 6.52251e-06 17.0009C0.000924175 20.9666 1.39955 24.8053 3.95047 27.8434L3.92617 27.864C4.01123 27.966 4.10844 28.0534 4.19593 28.1542C4.3053 28.2792 4.42317 28.397 4.53617 28.5184C4.87642 28.8875 5.22638 29.2421 5.59336 29.5747C5.70515 29.6767 5.82059 29.7714 5.9336 29.8686C6.32245 30.2037 6.72224 30.5218 7.1366 30.818C7.19007 30.8545 7.23868 30.9018 7.29214 30.9395V30.9249C10.1382 32.926 13.5333 34 17.0134 34C20.4934 34 23.8886 32.926 26.7346 30.9249V30.9395C26.7881 30.9018 26.8355 30.8545 26.8901 30.818C27.3033 30.5206 27.7043 30.2037 28.0931 29.8686C28.2062 29.7714 28.3216 29.6755 28.4334 29.5747C28.8004 29.2408 29.1503 28.8875 29.4906 28.5184C29.6036 28.397 29.7202 28.2792 29.8308 28.1542C29.9171 28.0534 30.0155 27.966 30.1006 27.8628L30.0738 27.8434ZM17.0122 7.28768C18.0937 7.28768 19.1509 7.60812 20.0501 8.20848C20.9494 8.80884 21.6502 9.66215 22.0641 10.6605C22.478 11.6589 22.5863 12.7574 22.3753 13.8173C22.1643 14.8772 21.6435 15.8507 20.8788 16.6148C20.114 17.3789 19.1397 17.8993 18.0789 18.1101C17.0182 18.3209 15.9188 18.2127 14.9196 17.7992C13.9204 17.3857 13.0664 16.6854 12.4655 15.7869C11.8647 14.8884 11.544 13.832 11.544 12.7514C11.544 11.3023 12.1201 9.91261 13.1456 8.88796C14.171 7.86332 15.5619 7.28768 17.0122 7.28768ZM7.29943 27.8434C7.32051 26.2491 7.96892 24.7273 9.10437 23.6071C10.2398 22.4869 11.771 21.8584 13.3667 21.8576H20.6576C22.2533 21.8584 23.7845 22.4869 24.9199 23.6071C26.0554 24.7273 26.7038 26.2491 26.7249 27.8434C24.0599 30.2429 20.5996 31.5709 17.0122 31.5709C13.4247 31.5709 9.96446 30.2429 7.29943 27.8434Z"
        fill="#F4F2FF"
      />
    </svg>
  );
};

export default NoAvatarIcon;
