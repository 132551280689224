import { forwardRef } from 'react';

import LogoIcon from '../LogoIcon';

export const SidebarLogo = () => {
  return <LogoIcon.Primary />;
};

SidebarLogo.Collapsed = forwardRef<SVGSVGElement>((_, ref) => {
  return (
    <svg
      ref={ref}
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7354_221642)">
        <path
          d="M8.8729 23.0309H0.795898V0.397949H8.9059C12.2039 0.397949 14.8909 1.40795 16.9659 3.43095C19.0399 5.45295 20.0769 8.22095 20.0779 11.7309C20.0779 15.2229 19.0349 17.9789 16.9479 20.0009C14.8629 22.0219 12.1709 23.0319 8.8729 23.0309ZM5.2019 18.9449H8.7129C10.6929 18.9449 12.3209 18.3269 13.5979 17.0929C14.8759 15.8579 15.5129 14.07 15.5109 11.7289C15.5109 9.38895 14.8739 7.59495 13.5979 6.34895C12.3229 5.10295 10.7059 4.48095 8.7449 4.48195H5.2019V18.9449Z"
          fill="#6913D8"
        />
        <path
          d="M28.7959 0.397949L27.7719 16.1379H24.6749L23.6499 0.397949H28.7959ZM24.4539 22.5549C24.2159 22.3229 24.028 22.0445 23.9017 21.737C23.7755 21.4294 23.7136 21.0993 23.7199 20.7669C23.7145 20.4323 23.7768 20.1001 23.9029 19.7901C24.029 19.4801 24.2164 19.1988 24.4539 18.9629C24.6786 18.7229 24.9507 18.5322 25.253 18.4029C25.5553 18.2736 25.8811 18.2086 26.2099 18.2119C26.8868 18.2135 27.5355 18.4831 28.0141 18.9617C28.4927 19.4404 28.7623 20.0891 28.7639 20.7659C28.7694 21.0998 28.7056 21.4311 28.5763 21.7389C28.447 22.0467 28.2551 22.3242 28.0129 22.5539C27.777 22.7913 27.4956 22.9786 27.1857 23.1047C26.8757 23.2308 26.5435 23.2932 26.2089 23.2879C25.882 23.2913 25.5578 23.2277 25.2563 23.1011C24.9549 22.9745 24.6825 22.7877 24.4559 22.5519L24.4539 22.5549Z"
          fill="#F9E05B"
        />
      </g>
      <defs>
        <clipPath id="clip0_7354_221642">
          <rect width="29" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});
