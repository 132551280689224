import { SVGProps } from 'react';

const DateIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.2666 3.09091H11.7333V2H10.6666V3.09091H5.33329V2H4.26663V3.09091H3.73329C3.14663 3.09091 2.66663 3.58182 2.66663 4.18182V12.9091C2.66663 13.5091 3.14663 14 3.73329 14H12.2666C12.8533 14 13.3333 13.5091 13.3333 12.9091V4.18182C13.3333 3.58182 12.8533 3.09091 12.2666 3.09091ZM12.2666 12.9091H3.73329V5.81818H12.2666V12.9091Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};

export default DateIcon;
