import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { PUBLIC_PATHS } from '@/common/constants';
import { BackendRes } from '@/common/types';
import CompanyService from '@/modules/company/services/CompanyService';

import { Member } from '../../types';
import { CompanyKeys } from '../../utils/generator/keys';

export type UseMyCompanyQueryProps = {
  queryParams?: Partial<{}>;
  options?: UseQueryOptions<
    BackendRes<{
      result: Member;
    }>
  >;
};

const useMyUserCompanyQuery = ({
  queryParams,
  options,
}: UseMyCompanyQueryProps = {}) => {
  // check public path
  const router = useRouter();
  const pathIsPublic = PUBLIC_PATHS.includes(router.pathname);

  return useQuery({
    queryKey: [...CompanyKeys.me(), queryParams],
    queryFn: async () => {
      const res = await CompanyService.getMyUserCompany({
        axiosConfig: {
          params: queryParams,
        },
      });

      return res.data;
    },
    enabled: !pathIsPublic,
    refetchOnMount: false,
    refetchOnWindowFocus: true,
    ...options,
  });
};

export default useMyUserCompanyQuery;
