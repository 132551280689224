const WorkIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M7.99691 11.1179V9.42654"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1268 3.55347C13.2535 3.55347 14.1602 4.4668 14.1602 5.59347V7.8868C12.5202 8.8468 10.3535 9.4268 7.9935 9.4268C5.6335 9.4268 3.4735 8.8468 1.8335 7.8868V5.5868C1.8335 4.46013 2.74683 3.55347 3.8735 3.55347H12.1268Z"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3302 3.55054V3.30654C10.3302 2.49321 9.67024 1.83321 8.85691 1.83321H7.13691C6.32357 1.83321 5.66357 2.49321 5.66357 3.30654V3.55054"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.84961 10.322L1.97561 11.9947C2.06094 13.122 3.00028 13.9933 4.13028 13.9933H11.8629C12.9929 13.9933 13.9323 13.122 14.0176 11.9947L14.1436 10.322"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

WorkIcon.Primary = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.1364 2.34598C6.68951 2.34598 6.30932 2.65653 6.2026 3.07509H9.79106C9.68434 2.65653 9.30415 2.34598 8.85726 2.34598H7.1364ZM10.8049 3.07509H12.1256C13.5263 3.07509 14.6668 4.22952 14.6668 5.64723C14.6668 5.64723 14.6268 6.2474 14.6135 7.08317C14.6121 7.14933 14.5801 7.21414 14.5274 7.2533C14.2066 7.49026 13.9131 7.68604 13.8864 7.69954C12.7792 8.44215 11.4926 8.96468 10.1219 9.2246C10.0325 9.24215 9.94447 9.19557 9.89911 9.1159C9.51492 8.45025 8.79723 8.01684 7.99683 8.01684C7.20176 8.01684 6.4774 8.44553 6.08187 9.11185C6.03585 9.19017 5.94914 9.2354 5.86043 9.21852C4.50108 8.95793 3.21444 8.43608 2.11389 7.70629L1.47357 7.26072C1.42021 7.22697 1.38686 7.16621 1.38686 7.0987C1.36685 6.7544 1.3335 5.64723 1.3335 5.64723C1.3335 4.22952 2.47407 3.07509 3.87477 3.07509H5.18876C5.31549 2.09619 6.1359 1.33333 7.1364 1.33333H8.85726C9.85776 1.33333 10.6782 2.09619 10.8049 3.07509ZM14.4401 8.54349L14.4134 8.55699C13.066 9.46162 11.4452 10.0625 9.74437 10.3123C9.50425 10.346 9.26413 10.1907 9.19743 9.9477C9.05069 9.39411 8.57712 9.02956 8.01017 9.02956H8.0035H7.99016C7.42321 9.02956 6.94964 9.39411 6.8029 9.9477C6.7362 10.1907 6.49608 10.346 6.25596 10.3123C4.55511 10.0625 2.9343 9.46162 1.58696 8.55699C1.58029 8.55024 1.51359 8.50973 1.46023 8.54349C1.4002 8.57724 1.4002 8.65825 1.4002 8.65825L1.44689 12.1013C1.44689 13.519 2.58079 14.6667 3.98149 14.6667H12.0122C13.4129 14.6667 14.5468 13.519 14.5468 12.1013L14.6001 8.65825C14.6001 8.65825 14.6001 8.57724 14.5401 8.54349C14.5067 8.52323 14.4667 8.52998 14.4401 8.54349ZM8.49708 11.3722C8.49708 11.6557 8.27697 11.8785 7.99683 11.8785C7.72336 11.8785 7.49658 11.6557 7.49658 11.3722V10.5013C7.49658 10.2245 7.72336 9.99496 7.99683 9.99496C8.27697 9.99496 8.49708 10.2245 8.49708 10.5013V11.3722Z"
        fill="#6014C4"
      />
    </svg>
  );
};

export default WorkIcon;
