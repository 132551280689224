import React from 'react';

const IntegrityIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1615 5.32167C32.2356 6.4879 30.0264 7.10303 27.7749 7.10001C24.986 7.10381 22.2786 6.16001 20.0965 4.42334C17.914 6.16049 15.206 7.10432 12.4165 7.10001C10.1644 7.10334 7.95464 6.48819 6.02819 5.32167C4.8781 8.05095 4.28754 10.9833 4.29152 13.945C4.29163 18.7171 5.82867 23.3624 8.67501 27.1927C11.5214 31.0231 15.5257 33.8349 20.0949 35.2117C24.6641 33.8349 28.6684 31.0231 31.5147 27.1927C34.361 23.3624 35.8981 18.7171 35.8982 13.945C35.903 10.9832 35.3124 8.05071 34.1615 5.32167V5.32167Z"
        stroke="url(#paint0_linear_28_1770)"
      />
      <path
        d="M13.9863 19.3884L19.4597 24.1284L27.8547 14.9817"
        stroke="url(#paint1_linear_28_1770)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_28_1770"
          x1="20.0949"
          y1="4.42334"
          x2="20.0949"
          y2="35.2117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_28_1770"
          x1="20.9205"
          y1="14.9817"
          x2="20.9205"
          y2="24.1284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IntegrityIcon;
