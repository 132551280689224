import { LoadingOutlined } from '@ant-design/icons';
import { UnleashClient } from '@unleash/proxy-client-react';
import { Spin } from 'antd';
import { useSetAtom } from 'jotai';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import {
  ONBOARDING_PATHS,
  PUBLIC_PATHS,
  SUBSCRIPTION_PATHS,
} from '@/common/constants';
import useMyUserCompanyQuery from '@/modules/company/hooks/queries/useMyUserCompanyQuery';

import { unleashAtom } from '../atoms';
import { UNLEASH_CONFIG } from '../configs';

const unleashClient = new UnleashClient({
  ...UNLEASH_CONFIG,
});

const FlagProvider = dynamic(() => import('@unleash/proxy-client-react'), {
  ssr: false,
  loading: () => (
    <div className="flex h-screen items-center justify-center">
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: '64px', color: '#6014c3' }} />
        }
        spinning
      />
    </div>
  ),
});

const CustomUnleashContextProvider = ({ children }) => {
  const router = useRouter();
  const pathIsPublic = PUBLIC_PATHS.includes(router.pathname);
  const onboardingPath = ONBOARDING_PATHS.includes(router.pathname);
  const subscriptionPath = SUBSCRIPTION_PATHS.includes(router.pathname);

  const setLoadingFlag = useSetAtom(unleashAtom);

  const { data: userCompanyData } = useMyUserCompanyQuery({
    options: {
      enabled: !pathIsPublic && !onboardingPath && !subscriptionPath,
      refetchOnWindowFocus: false,
      retry: false,
    },
  });

  const userCompanyEmail = userCompanyData?.data?.result?.email;
  const userCompanyEmailDomain =
    userCompanyData?.data?.result?.company?.emailDomain || '';

  useEffect(() => {
    const updateUnleashContext = async () => {
      setLoadingFlag({
        loading: true,
      });

      try {
        if (userCompanyEmail || userCompanyEmailDomain) {
          await unleashClient.updateContext({
            userId: userCompanyEmail,
            properties: {
              ed_appEnv: process.env.NEXT_PUBLIC_APP_ENV!,
              companyEmailDomain: userCompanyEmailDomain,
            },
          });
        }
      } finally {
        setLoadingFlag({
          loading: false,
        });
      }
    };

    updateUnleashContext();
  }, [userCompanyEmail, userCompanyEmailDomain]);

  return <FlagProvider unleashClient={unleashClient}>{children}</FlagProvider>;
};

export default CustomUnleashContextProvider;
