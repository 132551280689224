const NotificationIcon = ({
  className,
  notificationIsActive,
}: {
  className?: string;
  notificationIsActive: boolean;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3927 4.88685C10.3927 5.58459 10.5771 5.99585 10.983 6.46978C11.2905 6.81893 11.3888 7.26711 11.3888 7.75335C11.3888 8.23902 11.2292 8.70008 10.9095 9.07441C10.491 9.52316 9.90075 9.80964 9.29836 9.85944C8.42542 9.93386 7.55192 9.99652 6.66683 9.99652C5.78119 9.99652 4.90825 9.95903 4.03531 9.85944C3.43237 9.80964 2.84212 9.52316 2.42415 9.07441C2.10446 8.70008 1.94434 8.23902 1.94434 7.75335C1.94434 7.26711 2.04317 6.81893 2.35016 6.46978C2.76869 5.99585 2.94096 5.58459 2.94096 4.88685V4.65017C2.94096 3.71575 3.17396 3.10474 3.65378 2.50659C4.36715 1.63428 5.51064 1.11111 6.64199 1.11111H6.69168C7.84732 1.11111 9.02781 1.65946 9.72903 2.56926C10.184 3.15509 10.3927 3.74037 10.3927 4.65017V4.88685ZM5.04082 11.1449C5.04082 10.8651 5.29757 10.737 5.53499 10.6822C5.81272 10.6234 7.50505 10.6234 7.78277 10.6822C8.0202 10.737 8.27694 10.8651 8.27694 11.1449C8.26314 11.4113 8.10688 11.6474 7.891 11.7973C7.61106 12.0155 7.28253 12.1538 6.9391 12.2036C6.74916 12.2282 6.56253 12.2287 6.37922 12.2036C6.03523 12.1538 5.70671 12.0155 5.42732 11.7968C5.21088 11.6474 5.05463 11.4113 5.04082 11.1449Z"
        fill="#27125C"
      />
      {notificationIsActive && (
        <circle cx="9.16675" cy="2.16666" r="2" fill="#BC0B0B" />
      )}
    </svg>
  );
};

export default NotificationIcon;
