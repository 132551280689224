/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormInstance } from 'antd';
import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { jobPerkPredefinedValues } from '@/common/constants';
import { FixMeLater } from '@/common/types';
import { RecruitmentStep } from '@/modules/jobs/components/MainPage/JobPostForm/JobOpeningEditor/StepsContainer/index.type';
import {
  JobActivationType,
  JobStatus,
  PreScreeningQuestion,
} from '@/modules/jobs/types';

import { useStoreAuth } from '../../../common/contexts/useAuth';

export type State = {
  data: {
    cityId: string | null;
    workplaceType: string;
    employmentTypes: string | string[];
    salaryType: string;
    role: FixMeLater;
    startRange: number | null;
    endRange: number | null;
    applicationPlatform: string;
    externalPlatformUrl: string | null;
    description: string;
    responsibilities: string;
    requirements: string;
    perks: (string | null)[];
    customPerks: string;
    cvRequired: boolean;
    portofolioRequired: boolean;
    preScreeningQuestions: PreScreeningQuestion[];
    published: boolean;
    invitedEmails: string[];
    jobActivationType: JobActivationType;
    candidatePreference: {
      graduationDateRange: {
        start: string;
        end: string;
      } | null;
      major: string | null;
      skillIds: string[];
      university: string | null;
      minimumGpa: number | null;
      lastEducations: string[];
    };
    location: string | null;
    cityList: {
      label: string;
      value: string;
    }[];
    recruitmentSteps: RecruitmentStep[];
    companyName: string | null;
    retail: boolean;
    skills: string[];
    canChangeHiringTeamAccess: boolean;
    showSalaryToApplicants: boolean;
    hiringTeam: FixMeLater;
    careerFair: boolean;
    status?: JobStatus;
    reachMaxLimit?: boolean;
  };
  skillRecomendations: string[];
  suggestedRoles: string[];
  currentStep: number;
  initialRender: boolean;
  referrer: string;
  addEmailModal: {
    index: number | null;
    opened: boolean;
    type: string;
  };
  addHiringTeamEmailModal: {
    index: number | null;
    opened: false;
  };
  confirmModal: {
    opened: boolean;
  };
  deleteModal: {
    opened: boolean;
    index: number | null;
  };

  setTestModal: {
    opened: boolean;
    index: number | null;
    stepName: string | null;
  };
};

type ReducerAction =
  | {
      key: (typeof ACTION_TYPE)['setSetTestModal'];
      payload: {
        setTestModal: State['setTestModal'];
      };
    }
  | {
      key: (typeof ACTION_TYPE)[keyof typeof ACTION_TYPE];
      payload: FixMeLater;
    };

const JobPostContext = createContext<{
  state: State | null;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  dispatch: Dispatch<ReducerAction>;
  initialState: State | null;
  form: FormInstance<State['data']>;
  fetched: boolean;
  setFetched: Dispatch<SetStateAction<boolean>> | null;
}>({
  state: null,
  loading: false,
  setLoading: () => {},
  fetched: false,
  setFetched: null,
  dispatch: () => {},
  initialState: null,
  form: {} as FormInstance,
});

const ACTION_TYPE = {
  setData: 'SET_DATA',
  setCurrentStep: 'SET_CURRENT_STEP',
  setInitialRender: 'SET_INITIAL_RENDER',
  setSuggestedRoles: 'SET_SUGGESTED_ROLES',
  setReferrer: 'SET_REFERRER',
  setSkillRecommendations: 'SET_SKILL_RECOMMENDATIONS',
  resetState: 'RESET_STATE',
  setAddEmailModal: 'SET_ADD_EMAIL_MODAL',
  setAddHiringTeamEmailsModal: 'SET_ADD_HIRING_TEAM_EMAILS_MODAL',
  setDeleteModal: 'SET_DELETE_MODAL',
  setConfirmModal: 'SET_CONFIRM_MODAL',
  setSetTestModal: 'SET_SET_TEST_MODAL',
  setSeeExampleModal: 'SET_SEE_EXAMPLE_MODAL',
} as const;

export const initialStateJob: State = {
  data: {
    jobActivationType: '',
    cityId: null,
    role: null,
    workplaceType: 'remote',
    employmentTypes: 'fullTime',
    salaryType: 'paid',
    startRange: null,
    endRange: null,
    applicationPlatform: 'internal',
    externalPlatformUrl: null,
    description: '',
    responsibilities: '',
    requirements: '',
    perks: [
      ...(jobPerkPredefinedValues?.map((perk) => perk.value) ?? []),
      'customBenefits',
    ],
    customPerks: '',
    cvRequired: true,
    portofolioRequired: false,
    preScreeningQuestions: [],
    published: false,
    invitedEmails: [],
    candidatePreference: {
      graduationDateRange: null,
      major: null,
      skillIds: [],
      university: null,
      minimumGpa: null,
      lastEducations: [],
    },
    location: null,
    cityList: [],
    companyName: '',
    retail: false,
    skills: [],
    canChangeHiringTeamAccess: true,
    showSalaryToApplicants: false,
    recruitmentSteps: [],
    hiringTeam: [],
    careerFair: false,
  },
  skillRecomendations: [],
  suggestedRoles: [],
  currentStep: 0,
  initialRender: true,
  referrer: '',
  addEmailModal: {
    index: null,
    opened: false,
    type: 'invitedEmails',
  },
  addHiringTeamEmailModal: {
    index: null,
    opened: false,
  },
  confirmModal: {
    opened: false,
  },
  deleteModal: {
    opened: false,
    index: null,
  },
  setTestModal: {
    opened: false,
    index: null,
    stepName: null,
  },
};

const JobPostProvider = ({ children }) => {
  const { state: authState } = useStoreAuth();
  const [form] = Form.useForm<State['data']>();
  const router = useRouter();

  const reducer = (state, { key, payload }: ReducerAction) => {
    switch (key) {
      case ACTION_TYPE.setData:
        return { ...state, data: { ...state.data, ...payload.data } };
      case ACTION_TYPE.setCurrentStep:
        return {
          ...state,
          currentStep: payload.currentStep,
        };
      case ACTION_TYPE.setInitialRender:
        return {
          ...state,
          initialRender: payload.initialRender,
        };
      case ACTION_TYPE.setSuggestedRoles:
        return {
          ...state,
          suggestedRoles: payload.suggestedRoles,
        };
      case ACTION_TYPE.setReferrer:
        return {
          ...state,
          referrer: payload.referrer ?? '',
        };
      case ACTION_TYPE.setSkillRecommendations:
        return {
          ...state,
          skillRecommendations: payload.skillRecommendations,
        };
      case ACTION_TYPE.setAddEmailModal:
        return {
          ...state,
          addEmailModal: {
            ...state.addEmailModal,
            ...payload.addEmailModal,
          },
        };
      case ACTION_TYPE.setAddHiringTeamEmailsModal:
        return {
          ...state,
          addHiringTeamEmailModal: {
            ...state.addHiringTeamEmailModal,
            ...payload.addHiringTeamEmailModal,
          },
        };
      case ACTION_TYPE.setConfirmModal:
        return {
          ...state,
          confirmModal: {
            ...state.confirmModal,
            ...payload.confirmModal,
          },
        };
      case ACTION_TYPE.setDeleteModal:
        return {
          ...state,
          deleteModal: {
            ...state.deleteModal,
            ...payload.deleteModal,
          },
        };
      case ACTION_TYPE.setSetTestModal:
        return {
          ...state,
          setTestModal: {
            ...state.setTestModal,
            ...payload.setTestModal,
          },
        };
      case ACTION_TYPE.resetState:
        form.resetFields();
        return initialStateJob;
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, { ...initialStateJob });
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  const isInJobPostRoutes = (pathName) => {
    const JOB_POST_FORM_PATHS = ['/job-post/create', '/job-post/edit/[id]'];
    return JOB_POST_FORM_PATHS.includes(pathName);
  };

  useEffect(() => {
    const inJobPostFormPage = isInJobPostRoutes(router.pathname);
    if (!inJobPostFormPage) {
      dispatch({
        key: ACTION_TYPE.setReferrer,
        payload: {
          referrer: '',
        },
      });
    }
  }, [router.asPath]);

  return (
    <JobPostContext.Provider
      value={{
        state,
        loading,
        dispatch,
        setLoading,
        initialState: {
          ...initialStateJob,
          data: {
            ...initialStateJob.data,
            companyName: authState?.companyData?.company?.name ?? null,
          },
        },
        form,
        setFetched,
        fetched,
      }}
    >
      {children}
    </JobPostContext.Provider>
  );
};

const useJobPostForm = () => {
  const context = useContext(JobPostContext);

  if (!context) {
    throw new Error(
      'useJobPostForm has to be used within <JobPostContext.Provider>'
    );
  }

  return context;
};

export { ACTION_TYPE, JobPostProvider, useJobPostForm };
