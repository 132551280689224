export const generateSelectOptions = ({
  labelProperty,
  valueProperty,
  rawOptions,
}: {
  labelProperty: string;
  valueProperty: string;
  rawOptions: Record<string, unknown>[] | [];
}) => {
  return rawOptions.map((rawOption) => {
    return {
      label: rawOption[labelProperty],
      value: rawOption[valueProperty],
    };
  });
};

export const generateCurrentEnvironment = () => process.env.NEXT_PUBLIC_APP_ENV;
