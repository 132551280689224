import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { FixMeLater } from '@/common/types';
import { UserData } from '@/modules/auth/types';
import useMyUserCompanyQuery from '@/modules/company/hooks/queries/useMyUserCompanyQuery';
import { CompanyData } from '@/modules/company/types';
import { dataLayerPush } from '@/modules/core/gtm/utils';
import { Subscription } from '@/modules/subscription/types';
import { FILTER_ATOM_TALENT_SEARCH_KEY } from '@/modules/talent-hunt/atoms/filter';
import { KEYWORD_ATOM_TALENT_SEARCH_KEY } from '@/modules/talent-hunt/atoms/search';
import { TalentRecommendationLocalStorageKey } from '@/modules/talent-hunt/types/enums';

import {
  getLocalStorageValue,
  removeLocalStorageValue,
  setLocalStorageValue,
} from '../helpers';
import { LocalStorageAuthKey } from '../types/enums';

// constant name
export const AUTH = 'job-portal-auth';
export const LAST_REFRESH_TIMESTAMP = 'employer-last-refresh-timestamp';
export const LOGIN_COUNT = 'job-portal-login-counter';
export const UTILS = 'job-portal-utils';

type ContextValuesState = {
  userData: UserData | null;
  isDataFilled: boolean | null;
  companyData: CompanyData | null;
  loginCount: number | null;
  completeProfileModalSeen: FixMeLater | null;
  subscription: Subscription | null;
  needUpgradePlan: boolean | null;
  subscriptionUsage: FixMeLater;
};

export type ContextValues = {
  state: ContextValuesState;
  dispatch: FixMeLater;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const generateInitialStateAuth = (): ContextValuesState => {
  const localStorageAuth = getLocalStorageValue(LocalStorageAuthKey.AUTH);
  const localStorageUtils = getLocalStorageValue(LocalStorageAuthKey.UTILS);

  return {
    userData: localStorageAuth?.userData ?? null,
    isDataFilled: localStorageAuth?.isDataFilled ?? null,
    companyData: localStorageAuth?.companyData ?? null,
    loginCount: localStorageUtils?.loginCount ?? 0,
    completeProfileModalSeen:
      localStorageUtils?.completeProfileModalSeen ?? false,
    subscription: localStorageAuth?.subscription ?? null,
    needUpgradePlan: localStorageAuth?.needUpgradePlan ?? false,
    subscriptionUsage: localStorageAuth?.subscriptionUsage ?? null,
  };
};

export const initialStateAuth = generateInitialStateAuth();

const AuthContext = createContext<ContextValues>({
  state: initialStateAuth,
  dispatch: null,
  loading: false,
  setLoading: () => {},
});

export const removeRelatedWithAuth = () => {
  try {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(AUTH);
    }
  } catch (err) {
    return;
  }
};

export const removeExpireToken = () => {
  try {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(LAST_REFRESH_TIMESTAMP);
    }
  } catch (err) {
    return;
  }
};

export const convertToken = (tokenData) => {
  let token = {
    ...tokenData,
  };
  token.expire = Number(tokenData.expire) * 1000 + Date.now();
  token.refreshExpire = Number(tokenData.refreshExpire) * 1000 + Date.now();
  return token;
};

export const shouldRefreshStatus = (token) => {
  if (token && token.expire <= Date.now()) {
    if (token.refreshExpire > Date.now()) {
      return 'REFRESH';
    }
    return 'LOGOUT';
  } else {
    return 'ACTIVE';
  }
};

const reducer = (state, { key, ...payload }) => {
  const initial = {
    userData: null,
    isDataFilled: null,
    companyData: null,
    subscription: null,
    needUpgradePlan: false,
    subscriptionUsage: null,
  };
  switch (key) {
    case 'SET_AUTH_DATA':
      setLocalStorageValue(LocalStorageAuthKey.AUTH, {
        ...state,
        ...payload.data,
      });
      return {
        ...state,
        ...payload.data,
      };
    case 'DELETE_AUTH_DATA':
      // Remove HIDE_TALENT_RECOMMENDATION_BANNER_FOR_JOB_IDS from localStorage
      removeLocalStorageValue(
        TalentRecommendationLocalStorageKey.HIDE_TALENT_RECOMMENDATION_BANNER_FOR_JOB_IDS
      );

      /**
       * Temporarily being wrapped in try-catch block to prevent error
       * because the localStorage type is different (atom with storage)
       */
      try {
        localStorage.removeItem(FILTER_ATOM_TALENT_SEARCH_KEY);
        localStorage.removeItem(KEYWORD_ATOM_TALENT_SEARCH_KEY);
      } catch (err) {
        console.error(err);
      }

      setLocalStorageValue(LocalStorageAuthKey.AUTH, {
        ...state,
        ...initial,
      });
      return {
        ...state,
        ...initial,
      };
    case 'COMPLETE_COMPANY_PROFILE_MODAL_SEEN':
      setLocalStorageValue(LocalStorageAuthKey.UTILS, {
        loginCount:
          getLocalStorageValue(LocalStorageAuthKey.UTILS)?.loginCount ?? 0,
        completeProfileModalSeen: true,
      });
      return {
        ...state,
        completeProfileModalSeen: true,
      };
    case 'COMPLETE_COMPANY_PROFILE_MODAL_UNSEEN':
      setLocalStorageValue(LocalStorageAuthKey.UTILS, {
        loginCount:
          getLocalStorageValue(LocalStorageAuthKey.UTILS)?.loginCount ?? 0,
        completeProfileModalSeen: false,
      });
      return {
        ...state,
        completeProfileModalSeen: false,
      };
    case 'INCREASE_LOGIN_COUNTER':
      setLocalStorageValue(LocalStorageAuthKey.UTILS, {
        completeProfileModalSeen:
          getLocalStorageValue(LocalStorageAuthKey.UTILS)
            ?.completeProfileModalSeen ?? false,
        loginCount: state.loginCount + 1,
      });
      return {
        ...state,
        loginCount: state.loginCount + 1,
      };
    default:
      return state;
  }
};

/**
 * @description Send user data to Google Tag Manager on every login/opening ATS Dashboard
 */
const useTrackGoogleAnalytics = () => {
  const { data, isSuccess } = useMyUserCompanyQuery();

  useEffect(() => {
    if (isSuccess && !!data) {
      const { userId, email, name, companyId, company } = data.data.result;
      const payload = {
        userId,
        personal: {
          email,
          name,
        },
        company: {
          id: companyId,
          name: company?.name,
          subscriptionStatus: company?.subscription?.status,
        },
      };

      dataLayerPush({
        event: 'employer_sign_in',
        ...payload,
      });
    }
  }, [isSuccess]);
};

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, { ...initialStateAuth });

  useTrackGoogleAnalytics();
  useEffect(() => {
    const localStorageAuth = getLocalStorageValue(LocalStorageAuthKey.AUTH);
    if (localStorageAuth) {
      const dataUser = localStorageAuth;
      dispatch({ key: 'SET_AUTH_DATA', data: dataUser });
    } else {
      dispatch({ key: 'SET_AUTH_DATA', data: { ...initialStateAuth } });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ state, dispatch, loading, setLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * @deprecated use useMyUserCompanyQuery instead
 */
export const useStoreAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useStoreAuth has to be used within <Store.Provider>');
  }
  return context;
};
