import { message } from 'antd';
import useSWR from 'swr';

import { useStoreApi } from '@/common/contexts/useApi';
import { parseErrorMessage } from '@/common/utils';
import { getCompanyById } from '@/modules/company/services/CompanyService';

/**
 * @deprecated Use useCompanyQuery instead
 */
const useGetCompanyById = (companyId, swrOptions) => {
  const { axios } = useStoreApi();

  const { key, fetcher } = getCompanyById(axios, companyId);
  const swr = useSWR(companyId ? key : null, fetcher, {
    ...swrOptions,
    onError: (error) => {
      const errorMessage = parseErrorMessage(error);
      message.error(errorMessage);
    },
    revalidateOnFocus: false,
  });

  return {
    ...swr,
    result: swr?.data?.data?.result,
    isLoading: !swr.error && !swr.data,
  };
};

export default useGetCompanyById;
