const WarningIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 7C14 3.13401 10.866 0 7.00001 0C3.13402 0 1.33514e-05 3.13401 1.33514e-05 7C1.33514e-05 10.866 3.13402 14 7.00001 14C10.866 14 14 10.866 14 7ZM7.00039 7.74999C6.58618 7.74999 6.25039 7.4142 6.25039 6.99999L6.25039 3.39999C6.25039 2.98577 6.58618 2.64999 7.00039 2.64999C7.41461 2.64999 7.75039 2.98577 7.75039 3.39999L7.75039 6.99999C7.75039 7.4142 7.41461 7.74999 7.00039 7.74999ZM7.75039 9.904C7.75039 9.48979 7.41461 9.154 7.00039 9.154C6.58618 9.154 6.25039 9.48979 6.25039 9.904L6.25039 10.604C6.25039 11.0182 6.58618 11.354 7.00039 11.354C7.41461 11.354 7.75039 11.0182 7.75039 10.604L7.75039 9.904Z"
      fill="currentColor"
    />
  </svg>
);

WarningIcon.Close = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_403_21131)">
        <path
          d="M15.2462 5.92919C15.5706 5.60472 15.5706 5.07865 15.2462 4.75419C14.9217 4.42972 14.3956 4.42972 14.0712 4.75419L10.7074 8.11791C10.3169 8.50844 9.68374 8.50844 9.29322 8.11791L5.92949 4.75419C5.60503 4.42972 5.07896 4.42972 4.75449 4.75419C4.43002 5.07865 4.43002 5.60472 4.75449 5.92919L8.11822 9.29291C8.50874 9.68344 8.50874 10.3166 8.11822 10.7071L4.75449 14.0709C4.43003 14.3953 4.43002 14.9214 4.75449 15.2459C5.07896 15.5703 5.60502 15.5703 5.92949 15.2459L9.29322 11.8821C9.68374 11.4916 10.3169 11.4916 10.7074 11.8821L14.0712 15.2459C14.3956 15.5703 14.9217 15.5703 15.2462 15.2459C15.5706 14.9214 15.5706 14.3953 15.2462 14.0709L11.8824 10.7071C11.4919 10.3166 11.4919 9.68344 11.8824 9.29291L15.2462 5.92919Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_403_21131">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarningIcon;
