const CloseIconModal = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 5.34163L14.6583 4.16663L9.99996 8.82496L5.34163 4.16663L4.16663 5.34163L8.82496 9.99996L4.16663 14.6583L5.34163 15.8333L9.99996 11.175L14.6583 15.8333L15.8333 14.6583L11.175 9.99996L15.8333 5.34163Z"
        fill="#767676"
      />
    </svg>
  );
};

export default CloseIconModal;
