import { useEffect, useRef, useState } from 'react';

const Options = {
  root: null,
  rootMargin: '0px',
  threshold: 1,
};

/** @deprecated use https://ahooks-v2.js.org/hooks/dom/use-in-viewport/ instead */
export default function useObserver(ref) {
  const [intersecting, setIntersecting] = useState(false);
  const observerInstanceRef = useRef(null);

  const intersectionHandler = (entries) => {
    const [entry] = entries;
    setIntersecting(entry.isIntersecting);
  };

  const unobserve = () => {
    const observerInstance = observerInstanceRef.current;

    return observerInstance.unobserve(ref.current);
  };

  const observe = () => {
    const observerInstance = observerInstanceRef.current;

    return observerInstance.observe(ref.current);
  };

  useEffect(() => {
    const current = ref.current;
    const observer = (observerInstanceRef.current = new IntersectionObserver(
      intersectionHandler,
      Options
    ));

    if (current || observerInstanceRef.current) observer.observe(current);
    return () => {
      if (current || observerInstanceRef.current) observer.unobserve(current);
    };
  }, [ref]);

  return { intersecting, unobserve, observe, observerInstanceRef };
}
