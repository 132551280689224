import { getLocalStorageValue } from '@/common/helpers';
import { FixMeLater } from '@/common/types';
import { LocalStorageAuthKey } from '@/common/types/enums';
import { Profile } from '@/modules/auth/types';

export const parseTalentProfile = (profile) => {
  if (profile) {
    const tempProfile = {
      talentEmail: profile.profile?.email,
      talentName: profile.name,
      talentUserId: profile.userId,
      talentRank: profile.rank,
      talentHandphone: profile.profile?.handphone,
      talentCampus: profile.campus,
      talentMajoring: profile.majoring,
      talentBatch: profile.batch,
      talentGraduationYear: profile.graduationYear,
    };

    return tempProfile;
  }

  return {};
};

export const parseUserProfile = (profile) => {
  if (profile) {
    const tempProfile = {
      email: profile.email,
      name: profile.name,
      userId: profile.userId,
      type: 'employer',
      handphone: profile.handphone,
      role: profile.role,
      jobTitle: profile.jobTitle,
      companyId: profile.companyId,
      companyName: profile.companyName,
      companySector: profile.companySector,
      companyEmailDomain: profile.companyEmailDomain,
      companyRank: profile.companyRank,
    };

    return tempProfile;
  }

  return {};
};

export const getUserProfile = (profile?: Profile, company?: FixMeLater) => {
  const storedData = getLocalStorageValue(LocalStorageAuthKey.AUTH);
  const tempProfile = profile ?? storedData?.userData?.profile;
  const tempCompany = company ?? storedData?.companyData;
  let tempProfileData: {
    email?: string;
    name?: string;
    userId?: string;
    type?: string;
    handphone?: string;
    role?: string;
    jobTitle?: string;
    companyId?: string;
    companyName?: string;
    companySector?: string;
    companyEmailDomain?: string;
    companyRank?: string;
  } | null = null;

  // Profile Data
  if (tempProfile) {
    tempProfileData = tempProfile;

    if (tempCompany && tempProfileData) {
      tempProfileData.role = tempCompany?.role;
      tempProfileData.jobTitle = tempCompany?.jobTitle;
      tempProfileData.companyId = tempCompany?.companyId;
      tempProfileData.companyName = tempCompany?.company?.name;
      tempProfileData.companySector = tempCompany?.company?.sector;
      tempProfileData.companyEmailDomain = tempCompany?.company?.emailDomain;
      tempProfileData.companyRank = tempCompany?.company?.rank;
    }
  }

  if (tempProfileData) {
    return parseUserProfile(tempProfileData);
  } else {
    return {};
  }
};
