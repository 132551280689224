import { generateDepartmentPayload } from '@/modules/permissions/utils/generator/permissions';

import { OnBoardingFormValues, OnBoardingPayload } from '../../types';

export const generateCookieDomain = () => {
  const domain = 'dealls.com';

  if (typeof window !== 'undefined') {
    const deallsDomain = document.location.hostname.includes(domain);

    if (deallsDomain) {
      return domain;
    }

    return document.location.hostname;
  }

  return '';
};

const generateCompanySize = (value: string) => {
  let data: {
    start: number | null;
    end: number | null;
  } = {
    start: null,
    end: null,
  };

  if (value === 'small') {
    data.start = 1;
    data.end = 50;
  } else if (value === 'medium') {
    data.start = 50;
    data.end = 100;
  } else {
    data.start = 100;
    data.end = null;
  }

  return data;
};

export const generateOnboardingPayload = (
  fieldsValue: OnBoardingFormValues
) => {
  let payload: OnBoardingPayload = {
    handphone: fieldsValue.phoneNumber,
    employeeTargets: fieldsValue.expectation,
    jobIntegration: fieldsValue.jobIntegration,
    howToKnowDeall: fieldsValue.howToKnowDeall
      ? fieldsValue.howToKnowDeall?.split(' ')?.join('')?.split(',')
      : [],
    ...generateDepartmentPayload(fieldsValue.department),
  };

  if (!fieldsValue.isJoin) {
    const fundingStage =
      fieldsValue?.insight?.fundingStage?.value === 'other'
        ? fieldsValue.otherFunding!
        : fieldsValue?.insight?.fundingStage?.value;

    let company: OnBoardingPayload['company'] = {
      name: fieldsValue.companyName,
      sector: fieldsValue.companyIndustry?.label ?? '',
      logoUrl: fieldsValue.companyLogo,
      description: fieldsValue.companyDescription,
      website: fieldsValue.companyWebsite,
      size: generateCompanySize(fieldsValue.companySize?.value ?? ''),
      cityId: fieldsValue.companyLocation?.value!,
      cultures: fieldsValue?.cultures?.map?.((culture) => culture.value) ?? [],
      whatSpecial: fieldsValue.whatSpecial ?? null,
      insight: {
        fundingStage: fundingStage || null,
        fundingAmount: fieldsValue.insight.fundingAmount || null,
        benefits: fieldsValue.otherBenefits
          ? Array.from(
              new Set(
                [
                  ...(fieldsValue.otherBenefits.filter(Boolean) ?? []),
                  ...(fieldsValue?.insight?.benefits?.map?.(
                    (benefit) => benefit.value
                  ) ?? []),
                ].filter((benefit) => benefit !== 'other')
              )
            )
          : fieldsValue?.insight?.benefits?.map?.((benefit) => benefit.value),
        workingPolicies: fieldsValue?.insight?.workingPolicies?.map?.(
          (policy) => policy.value
        ),
      },
    };

    payload.company = company;
  } else {
    payload.useCompanyEmail = true;
  }
  return payload;
};
