import { message } from 'antd';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { COOKIE_BRIDGE_TOKEN_NAME } from '@/common/configs/auth';
import { HRIS_APP_URL } from '@/common/configs/hris';
import { useStoreApi } from '@/common/contexts/useApi';
import { useSidebar } from '@/common/contexts/useSidebar';
import { parseErrorMessage } from '@/common/utils';
import { generateBridgeToken } from '@/modules/auth/services/AuthService';
import { generateCookieDomain } from '@/modules/auth/utils/generator';

const useSwitchPlatform = () => {
  const router = useRouter();
  const { axios } = useStoreApi();
  const { setLoading } = useSidebar();
  const redirectToHRIS = async () => {
    const hrisWebPlatform = 'hris_employer_web';
    const redirectHRISParams = `?loginWithToken=true&platformOrigin=${hrisWebPlatform}`;

    try {
      const response = await generateBridgeToken({
        axiosInstance: axios,
        payload: {
          platform: hrisWebPlatform,
        },
      });
      const bridgeToken = response?.data?.data?.result;
      Cookies.set(COOKIE_BRIDGE_TOKEN_NAME, bridgeToken, {
        domain: generateCookieDomain(),
      });
      router.replace(`${HRIS_APP_URL}${redirectHRISParams}`);
    } catch (error) {
      const errorMessage = parseErrorMessage(error);
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return {
    redirectToHRIS,
  };
};

export default useSwitchPlatform;
