import Axios from 'axios';
import Cookies from 'js-cookie';
import { createContext, useContext } from 'react';

import {
  API_BASE_URL,
  COOKIE_TOKEN_NAME,
  HRIS_API_BASE_URL,
} from '@/common/configs/auth';
import { paramsSerializer } from '@/common/utils/formatter';

const intialContext = {
  axios: Axios,
};

export const ApiContext = createContext(intialContext);

export const ApiProvider = ({ children }) => {
  const baseURL = `${API_BASE_URL}/`;

  const axiosInstance = Axios.create({
    baseURL,
  });

  const accessToken = Cookies.get(COOKIE_TOKEN_NAME) || null;

  const token = accessToken ? JSON.parse(accessToken) : null;

  axiosInstance.interceptors.response.use(
    (res) => res,
    (err) => {
      if (typeof err.response === 'undefined') {
        return Promise.reject(
          new Error('A network error occured. Please try refreshing the page')
        );
      }
      return Promise.reject(err.response.data);
    }
  );

  if (token) {
    axiosInstance.interceptors.request.use((config) => {
      if (token) {
        config.headers.common['Authorization'] = `Bearer ${token}`;
      }

      return config;
    });
  }

  return (
    <ApiContext.Provider
      value={{
        axios: axiosInstance,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
/**
 * @deprecated Use apiInstance instead
 * @returns {{
 *  axios: AxiosInstance
 * }}
 */
export const useStoreApi = () => {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('useStoreApi has to be used within <StoreApi.Provider>');
  }

  return context;
};

export const apiEmpInstance = Axios.create({
  baseURL: HRIS_API_BASE_URL,
  headers: {},
  paramsSerializer: paramsSerializer,
});

apiEmpInstance.interceptors.request.use(async (config) => {
  const accessToken = Cookies.get(COOKIE_TOKEN_NAME) || null;

  const parsedToken = accessToken ? JSON.parse(accessToken) : null;

  if (!config.headers) {
    config.headers = {};
  }

  if (accessToken) {
    config.headers.Authorization = `Bearer ${parsedToken}`;
  }

  return config;
});

apiEmpInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    // If backend is down
    if (typeof err?.response === 'undefined') {
      return Promise.reject(
        new Error('A network error occured. Please try refreshing the page')
      );
    }

    // Handle when people login from other devices
    if (err?.response?.data?.messageId?.includes('TOKEN_INVALID')) {
      return Promise.reject(err.response.data);
    }

    return Promise.reject(err.response.data);
  }
);
