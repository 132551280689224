import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { BackendRes } from '@/common/types';

import MemberService from '../../services/MemberService';
import { UserCompanyKeys } from '../../utils/generator/keys';

export type UseUserCompanyQueryLastSeenProps = {
  queryParams?: Partial<{
    id: string;
  }>;
  options?: UseQueryOptions<
    BackendRes<{
      result: unknown;
    }>
  >;
};

const useUserCompanyLastSeenQuery = ({
  queryParams,
  options,
}: UseUserCompanyQueryLastSeenProps = {}) => {
  return useQuery({
    queryKey: [...UserCompanyKeys.lastSeenAt(), queryParams],
    queryFn: async () => {
      const response = await MemberService.patchUserCompanyLastSeen({
        queryParams,
      });

      return response.data;
    },
    ...options,
  });
};

export default useUserCompanyLastSeenQuery;
