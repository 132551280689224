import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import JSZipUtils from 'jszip-utils';

import CopyErrorMessage from '@/common/components/CopyErrorMessage';
import { TECHNICAL_TEST_PREVIEW_KEY } from '@/modules/jobs/constants/job-post';

import { eventNames } from '../../modules/core/mixpanel/constants';
import { applicantsGradeList } from '../constants';
import { FixMeLater } from '../types';
import { calculateDiffFromNow, formatThousands } from './formatter';
import { generateCurrentEnvironment } from './generator';

export const getWord = (words, length = 1) => {
  if (words) {
    return words.split(' ').slice(0, length).join(' ');
  }

  return words;
};

export const formatMio = (n, type, fixedNumber = 1) => {
  if (n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(fixedNumber) + 'K';
    if (n >= 1e6 && n < 1e9)
      return +(n / 1e6).toFixed(fixedNumber) + (type === 'id' ? 'jt' : 'M');
    if (n >= 1e9 && n < 1e12)
      return +(n / 1e9).toFixed(fixedNumber) + (type === 'id' ? 'M' : 'B');
    if (n >= 1e12) return +(n / 1e12).toFixed(fixedNumber) + 'T';
  }

  return 0;
};

export const roundOffToNearest = (n) => {
  if (n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e5) return Math.round(+(n / 1e3)) * 1e3;
    if (n >= 1e5 && n < 1e9) return Math.round(+(n / 1e5)) * 1e5;
    if (n >= 1e9 && n < 1e12) return Math.round(+(n / 1e9)) * 1e9;
    if (n >= 1e12) return Math.round(+(n / 1e12)) * 1e12;
  }

  return 0;
};

export const getLastFiveYearsList = () => {
  let listOfYear = [] as FixMeLater[];
  const currentYear = new Date().getFullYear();

  listOfYear.push({ label: currentYear, value: currentYear });
  Array(4)
    .fill(null)
    .forEach((_, idx) => {
      let prevYear = currentYear - (idx + 1);
      listOfYear.push({ label: prevYear, value: prevYear });
    });
  return listOfYear;
};

export const parseErrorMessage = (error) => {
  const currentEnv = generateCurrentEnvironment();
  const copyErrorMessageVisible = currentEnv !== 'production';
  const errorMessage = error?.messageId
    ? error?.messageId
    : error?.message
    ? error?.message
    : '-';

  const errorId = error?.errorId ?? 'Unknown ID';
  const errorDescription =
    error?.data?.errors && error?.data?.errors?.length > 0
      ? error?.data?.errors[0]?.message
      : '';

  return (
    <div className="flex flex-col items-start">
      <div className="flex max-w-[500px] items-start gap-1">
        <div>
          <div>
            <span className="font-medium">{errorMessage}</span> ({errorId})
          </div>
          <div>{`${errorDescription}`}</div>
        </div>
        {copyErrorMessageVisible && (
          <CopyErrorMessage environment={currentEnv as string} error={error} />
        )}
      </div>
    </div>
  );
};

export const camelize = (string) => {
  return string
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const convertCamelize = (string) => {
  const result = string.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const parseHtmlString = (htmlString) => {
  if (htmlString) {
    let clean = sanitizeHtmlString(htmlString);
    return parse(clean);
  }
  return htmlString;
};

export const sanitizeHtmlString = (htmlString: string) =>
  DOMPurify.sanitize(htmlString, {
    ALLOWED_TAGS: [
      'b',
      'i',
      'em',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'em',
      'strong',
      'a',
      'span',
      'p',
      'br',
      'u',
      'ul',
      'ol',
      'li',
      's',
      'spellcheck',
      'pre',
    ],
    ALLOWED_ATTR: ['href', 'target', 'class', 'rel'],
  });

export const parseCompanySizePayloadValue = (value) => {
  if (typeof value === 'string') {
    switch (value) {
      case 'small':
        return {
          start: 1,
          end: 50,
        };
      case 'medium':
        return {
          start: 50,
          end: 100,
        };
      case 'large':
        return {
          start: 100,
          end: null,
        };
    }
  }
  if (value?.key?.start) {
    return {
      start: value?.key?.start,
      end: value?.key?.end,
    };
  }
};

export const parseLocationIdPayloadValue = (value) => {
  // This helper is used to build correct CityId value when during company profile update submission
  if (typeof value === 'number') return value;
  if (value?.key) return value.key;
};

export const parseCreatedDate = (createdDate) => {
  let value = calculateDiffFromNow(createdDate);
  if (value.type === 'now') return 'now';
  else return `${value.diff} ${value.type} ago`;
};

export const parseSalaryRange = (range) => {
  if (range && range?.start && range?.end) {
    return `IDR ${formatThousands(range?.start)}-${formatThousands(
      range?.end
    )}`;
  }

  return '-';
};

export const removeEmptyAttributes = (object) => {
  if (!object) return;

  return Object.entries(object).reduce(
    (accumulator, [key, value]) =>
      value ? ((accumulator[key] = value), accumulator) : accumulator,
    {}
  );
};

export const parseTime = (time) => {
  if (time) {
    if (time <= 3600000) {
      const minutes = Math.floor(time / 60000);
      return `${minutes} Minutes`;
    } else if (time <= 86400000) {
      const hours = Math.floor(time / 3600000);
      return `${hours} Hours`;
    } else {
      const days = Math.floor(time / 86400000);
      return `${days} Days`;
    }
  } else {
    return false;
  }
};

export const getCharacterFromLast = (string, number) => {
  return string.slice(-number);
};

export const trackStatusCandidate = (status, analytics) => {
  if (status === 'approached')
    analytics.track(eventNames.clickConsiderApplicant);
  if (status === 'accepted')
    analytics.track(eventNames.clickShortlistApplicant);
  if (status === 'rejected') analytics.track(eventNames.clickRejectApplicant);
};

export const changeFirstLetterToUpperCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const replaceSpecificWord = (string, word, replaceWord) => {
  return string.replace(word, replaceWord);
};

export const replaceAllText = (string, word, replaceWord) => {
  return string.replace(new RegExp(word, 'g'), replaceWord);
};

export const parseHTMLToText = (html) => {
  return html.replace(/<[^>]+>/g, '');
};

export const replaceAllTextByObject = (string, object) => {
  return Object.entries(object).reduce((accumulator, [key, value]) => {
    return accumulator.replaceAll(key, value);
  }, string);
};

export const getInputSelection = (el) => {
  var start = 0,
    end = 0,
    normalizedValue,
    range,
    textInputRange,
    len,
    endRange;

  if (
    typeof el.selectionStart == 'number' &&
    typeof el.selectionEnd == 'number'
  ) {
    start = el.selectionStart;
    end = el.selectionEnd;
  } else {
    // @ts-ignore - fix this later
    range = document.selection.createRange();

    if (range && range.parentElement() == el) {
      len = el.value.length;
      normalizedValue = el.value.replace(/\r\n/g, '\n');

      // Create a working TextRange that lives only in the input
      textInputRange = el.createTextRange();
      textInputRange.moveToBookmark(range.getBookmark());

      // Check if the start and end of the selection are at the very end
      // of the input, since moveStart/moveEnd doesn't return what we want
      // in those cases
      endRange = el.createTextRange();
      endRange.collapse(false);

      if (textInputRange.compareEndPoints('StartToEnd', endRange) > -1) {
        start = end = len;
      } else {
        start = -textInputRange.moveStart('character', -len);
        start += normalizedValue.slice(0, start).split('\n').length - 1;

        if (textInputRange.compareEndPoints('EndToEnd', endRange) > -1) {
          end = len;
        } else {
          end = -textInputRange.moveEnd('character', -len);
          end += normalizedValue.slice(0, end).split('\n').length - 1;
        }
      }
    }
  }

  return {
    start: start,
    end: end,
  };
};

export const insertTextBySpecificSelection = (string, start, end, text) => {
  return string.substring(0, start) + text + string.substring(end);
};

export const deleteTextBySpecificSelection = (string, start, end) => {
  return string.substring(0, start) + string.substring(end);
};

export const setSelectionInput = (el, start, end) => {
  if (el.setSelectionRange) {
    el.focus();
    el.setSelectionRange(start, end);
  } else if (el.createTextRange) {
    var range = el.createTextRange();
    range.collapse(true);
    range.moveEnd('character', end);
    range.moveStart('character', start);
    range.select();
  }
};

export const getUniqueObjectsFromAnArrayOfObject = (array, key) => {
  return array.filter(
    (obj, index, self) =>
      self.findIndex((item) => item[key] === obj[key]) === index
  );
};
export const getExperience = (experience) => {
  /*
    0 = Fresh Grad
    1 = 1st Year Of College
    2 = 2nd Year Of College
    3 = 3rd Year Of College
    4 = 4th Year Of College
    5 = Junior
    6 = Mid Level
    7 = Senior
  */

  let isFreshGrad = false,
    is1stYearOfCollege = false,
    is2ndYearOfCollege = false,
    is3rdYearOfCollege = false,
    is4thYearOfCollege = false,
    isJunior = false,
    isMidLevel = false,
    isSenior = false,
    isExperienceEmpty = false;

  switch (experience) {
    case 0:
      isFreshGrad = true;
      break;
    case 1:
      is1stYearOfCollege = true;
      break;
    case 2:
      is2ndYearOfCollege = true;
      break;
    case 3:
      is3rdYearOfCollege = true;
      break;
    case 4:
      is4thYearOfCollege = true;
      break;
    case 5:
      isJunior = true;
      break;
    case 6:
      isMidLevel = true;
      break;
    case 7:
      isSenior = true;
      break;
    default:
      isExperienceEmpty = true;
      break;
  }

  return {
    isFreshGrad,
    is1stYearOfCollege,
    is2ndYearOfCollege,
    is3rdYearOfCollege,
    is4thYearOfCollege,
    isJunior,
    isMidLevel,
    isSenior,
    isExperienceEmpty,
  };
};

export const daysToMilliseconds = (days) => {
  return days * 24 * 60 * 60 * 1000;
};

export const millisecondsToDays = (milliseconds) => {
  return milliseconds / (1000 * 60 * 60 * 24);
};

export const hoursToMilliseconds = (hours) => {
  return hours * 60 * 60 * 1000;
};

export const millisecondsToHours = (milliseconds) => {
  return milliseconds / (1000 * 60 * 60);
};

export const validateEmail = (text = '') => {
  let pattern = new RegExp(
    '^[\\w\\-\\.]+@[\\w\\-]+(\\.[\\w\\-]+)*\\.[\\w\\-]{2,4}$',
    'g'
  );

  return pattern.test(text);
};

export const getImageRatio = (image) => {
  return new Promise((resolve) => {
    const img = document.createElement('img');
    img.src = image;

    img.onload = () => {
      const { width, height } = img;
      const _ratio = (width / height).toFixed(1);
      resolve(_ratio);
    };
  });
};

export const cleanArray = (arr) => {
  return arr.filter((item) => item);
};

// delete falsy values of object
export const cleanObject = (obj) => {
  if (obj) {
    return Object.keys(obj).reduce((acc, key) => {
      if (obj[key]) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  }
};

// delete falsy values of object if the result is empty object return null
export const cleanObjectIfEmpty = (obj) => {
  if (obj) {
    const cleanedObject = cleanObject(obj);
    if (cleanedObject && Object.keys(cleanedObject).length === 0) {
      return null;
    }
    return cleanedObject;
  }
};

/**
 * @return {{
 *  id : string;
 *  customStep : boolean;
 *  stepValue : import('@/modules/jobs/types/applicantList').TabStatus;
 *  recruitmentStepId : string | undefined;
 * }}
 */
export const isCustomStep = (key) => {
  if (!key)
    return {
      id: undefined,
      customStep: false,
      recruitmentStepId: undefined,
      stepValue: undefined,
    };
  const [stepValue, recruitmentStepId] = key?.split?.('-') ?? [];
  const customStep = !!recruitmentStepId && recruitmentStepId !== 'undefined';

  return {
    id: customStep ? `${stepValue}-${recruitmentStepId}` : stepValue,
    customStep,
    stepValue,
    recruitmentStepId: customStep ? recruitmentStepId : undefined,
  };
};

export const removeTechnicalTestPreviewStorage = () => {
  try {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(TECHNICAL_TEST_PREVIEW_KEY);
    }
  } catch (err) {
    return;
  }
};

export const removeTalentHuntDatabaseFilterStorage = () => {
  try {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('talent-hunt-database-values');
    }
  } catch (err) {
    return;
  }
};

export const parseDocumentFileName = (fileUrl) => {
  const fileDecoded = decodeURIComponent(fileUrl);
  const split = fileDecoded?.split('/');
  return split
    ?.pop()
    ?.replace(/%20/g, ' ')
    .replace(/[^A-Za-z0-9.()-_[]]/g, '');
};

export const filterDuplicates = <T,>(arr: T[], property: string): T[] => {
  const uniqueValues = new Set();
  return arr.filter((obj) => {
    const value = obj[property];
    if (uniqueValues.has(value)) {
      return false; // Duplicate found, exclude from the filtered array
    }
    uniqueValues.add(value);
    return true; // Unique value, include in the filtered array
  });
};

export const downloadFileFromUrl = (url: string, fileName: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = fileName;

  a.click();
  a.remove();
};

export const downloadFileFromBlob = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  downloadFileFromUrl(url, fileName);
  window.URL.revokeObjectURL(url);
};

export const REGEX_URL_WITH_PREFIX =
  /^(http:\/\/|https:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;

export const formatQueryParams = <QueryParams,>({
  query,
  queryParams = {},
  formatFn,
}: {
  queryParams: Partial<QueryParams> | undefined;
  query: keyof QueryParams;
  formatFn: (queryParam: QueryParams[keyof QueryParams]) => string | void;
}) => {
  const queryParam = queryParams[query];

  if (queryParam) {
    return {
      ...queryParams,
      [query]: formatFn(queryParam),
    };
  }

  return queryParams;
};

export const capitalize = (str: string) => {
  const arr = str.split('') ?? [];

  for (let i = 0; i < str.length; i++) {
    const char = arr[i];
    if (char) arr[i] = char.charAt(0).toUpperCase() + char.slice(1);
  }
  return arr.join('');
};

const removeDotsFromURL = (url: string) => {
  const parts = url.split('.');
  const lastPart = parts.pop();
  const cleanedParts = parts
    .map((part) => part.trim())
    .filter((part) => part !== '');
  const result = cleanedParts.join(' ') + '.' + lastPart;
  return result;
};

export const fetchAndAddToZip = async (zip, url) => {
  return new Promise<void>((resolve, reject) => {
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (!url) return resolve();
      if (err) {
        reject(err);
      }
      const decodedFileName = decodeURIComponent(
        decodeURIComponent(url?.split('/')?.pop())
      );
      const formattedFileName = removeDotsFromURL(decodedFileName);
      zip.file(formattedFileName, data, {
        binary: true,
      });
      resolve(data);
    });
  });
};

export const parseEligibilityLabel = (eligibilty, labelKey = 'tableLabel') => {
  const numberEligibility = Number(eligibilty);

  if (numberEligibility === 0) return 'Freshgrad';
  if (numberEligibility === 8) return 'Others';

  if (numberEligibility) {
    const value = applicantsGradeList.find(
      (item) => item.value === numberEligibility
    );
    if (value) return value[labelKey];
  }
};

export const generateLocalStorageKey = (
  key: string,
  version: string = '1.0.0'
) => {
  return `ed-${key}-v${version}`;
};

/**
 * Splits an array into chunks of a specified size.
 * @param arr The array to be split.
 * @param chunkSize The size of each chunk.
 * @returns An array of chunks.
 */
export const splitArrayIntoChunks = <T,>(
  arr: T[],
  chunkSize: number
): T[][] => {
  const chunkedArray: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunkedArray.push(arr.slice(i, i + chunkSize));
  }
  return chunkedArray;
};

export const splitAndGroupArray = <T,>({
  arr,
  maxElementsPerSubarray,
  groupSize,
}: {
  arr: T[];
  maxElementsPerSubarray: number;
  groupSize: number;
}): T[][][] => {
  const subArrays = splitArrayIntoChunks(arr, maxElementsPerSubarray);
  const groupedSubarrays = splitArrayIntoChunks(subArrays, groupSize);

  return groupedSubarrays;
};

// Calculate the number of lines of a text
// Used in PDF generation where we need to calculate the number of lines of a text without rendering it
export const calculateLines = ({
  text,
  containerWidth,
  fontSize = 14,
}: {
  text: string;
  containerWidth: number;
  fontSize: number;
}) => {
  let lines = 1; // Initiating number of lines with 1

  // widths & avg value based on `Helvetica` font.
  // NOTE: This is not accurate for Inter , but it's the best we can do for now
  // If time is available, we can calculate the width of each character in Inter font (one by one :D)
  const widths = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0.278125, 0.278125, 0.35625, 0.55625, 0.55625,
    0.890625, 0.6671875, 0.1921875, 0.334375, 0.334375, 0.390625, 0.584375,
    0.278125, 0.334375, 0.278125, 0.303125, 0.55625, 0.55625, 0.55625, 0.55625,
    0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.278125, 0.278125,
    0.5859375, 0.584375, 0.5859375, 0.55625, 1.015625, 0.6671875, 0.6671875,
    0.7234375, 0.7234375, 0.6671875, 0.6109375, 0.778125, 0.7234375, 0.278125,
    0.5, 0.6671875, 0.55625, 0.834375, 0.7234375, 0.778125, 0.6671875, 0.778125,
    0.7234375, 0.6671875, 0.6109375, 0.7234375, 0.6671875, 0.9453125, 0.6671875,
    0.6671875, 0.6109375, 0.278125, 0.35625, 0.278125, 0.478125, 0.55625,
    0.334375, 0.55625, 0.55625, 0.5, 0.55625, 0.55625, 0.278125, 0.55625,
    0.55625, 0.2234375, 0.2421875, 0.5, 0.2234375, 0.834375, 0.55625, 0.55625,
    0.55625, 0.55625, 0.334375, 0.5, 0.278125, 0.55625, 0.5, 0.7234375, 0.5,
    0.5, 0.5, 0.35625, 0.2609375, 0.3546875, 0.590625,
  ];

  const avg = 0.5293256578947368;

  text
    .split('')
    .map((c) =>
      c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg
    )
    .reduce((cur = 0, acc = 0) => {
      if ((acc + cur) * fontSize > containerWidth) {
        lines++;
        cur = acc;
      }
      return acc + cur;
    }, 0);

  return lines;
};
