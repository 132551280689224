import { AxiosRequestConfig } from 'axios';

import { BackendRes, ISODateString } from '@/common/types';
import { apiEmpInstance } from '@/modules/core/axios/configs';

export type Company = {
  id: string;
  name: string;
  address: string;
  npwp?: string;
  legalName?: string;
  website?: string;
  logo: string;
  slug: string;
  verified: boolean;
  setupDone: boolean;
  emailDomain: string;
};

export type RequestJoinCompanyStatus = 'approved' | 'pending' | 'rejected';

export type RequestJoinCompanyResponse = {
  status: RequestJoinCompanyStatus;
  userId: string;
  canResend: boolean;
  updatedAt: ISODateString;
  createdAt: ISODateString;
  emails: { name: string; email: string }[];
};

export type CreateAdminQuery = Partial<{
  from: 'session' | 'job-portal';
}>;

export type JoinCompanyPayload = {
  companyId: string;
  note: string;
  userId: string;
  from: CreateAdminQuery;
};

export const requestHRISAccessPermission = (props: {
  payload: CreateAdminQuery;
}) => {
  const { payload } = props;
  return apiEmpInstance.post('/v2/hris/joinrequest/one', {
    ...payload,
  });
};

export const getHRISCompanyData = (props: {
  params: Partial<{ email_domain_eq: string }>;
}) => {
  const { params } = props;
  return apiEmpInstance.get<BackendRes<{ doc: Company }>>(
    '/v2/hris/company/one',
    {
      params: {
        ...params,
      },
    }
  );
};

export type Admin = {
  companyId: string;
  createdAt: string;
  id: string;
  name: string;
  role: string;
  updatedAt: string;
  emailDomain: string;
  logo: string;
};

export const getHRISAdminData = (
  props: {
    params: { user_id_eq: string };
  } & AxiosRequestConfig
) => {
  const { params, ...options } = props;
  return apiEmpInstance.get<BackendRes<{ doc: Admin }>>('/v2/hris/admin/one', {
    params: {
      ...params,
    },
    ...options,
  });
};

export const getHRISAccessPermissionRequestStatus = (
  props: {
    params: Partial<{
      user_id_eq: string;
      with: string;
    }>;
  } & AxiosRequestConfig
) => {
  const { params, ...options } = props;
  return apiEmpInstance.get<BackendRes<{ doc: RequestJoinCompanyResponse }>>(
    '/v2/hris/joinrequest/one',
    {
      params: {
        ...params,
      },
      ...options,
    }
  );
};

export const createHRISAdmin = (props: {
  payload: { userId: string } & CreateAdminQuery;
}) => {
  const { payload } = props;
  return apiEmpInstance.post('/v2/hris/admin/action/create-from-external', {
    ...payload,
  });
};
