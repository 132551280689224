import { AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { apiInstance } from '@/modules/core/axios/configs';

const PermissionService = {
  getPermissionList: ({ axiosConfig }: { axiosConfig: AxiosRequestConfig }) => {
    return apiInstance.get(`/v1/job-portal/permission`, axiosConfig);
  },
};

export default PermissionService;

export const _getCompanyMemberByPermissionResource = (
  instance,
  { params = {} } = {}
) => {
  const key = `/v1/job-portal/permission/user-company`;

  const fetcher = async (fetcherParams) => {
    try {
      const response = await instance.get(key, {
        params: fetcherParams ? fetcherParams : params,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat' }),
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return { key, fetcher };
};

export const bulkUpdateAccessPermissions = (instance, data) => {
  return instance.put(`/v1/job-portal/user-company/access-permission/bulk`, {
    ...data,
  });
};

export const requestAccessPermission = (instance, data) => {
  return instance.post(`/v1/job-portal/company/change-request`, {
    ...data,
  });
};

export const deleteRequestAccessPermission = (
  instance,
  { companyChangeRequestId }
) => {
  return instance.delete(
    `/v1/job-portal/company/change-request/${companyChangeRequestId}`
  );
};
