import moment, { Moment } from 'moment';

import { TimeFilterOptionValue } from '../../types';

export const generateLabelAndValueByTimeFilter = (
  timeFilterType: TimeFilterOptionValue
): {
  label: string;
  value: [Date, Date] | null;
  timeFilterValue: TimeFilterOptionValue;
} => {
  const currentDate = moment(); // Current date and time

  // Calculate the start date for the last 7 days
  const startDateLast7Days = currentDate.clone().subtract(7, 'days');

  // Calculate the start date for the last 30 days
  const startDateLast30Days = currentDate.clone().subtract(30, 'days');

  if (timeFilterType === 'allTime') {
    return {
      label: 'All Time',
      value: null,
      timeFilterValue: 'allTime',
    };
  }

  if (timeFilterType === 'daily') {
    const label = `Today (${currentDate.format('D MMM YYYY')})`;

    return {
      label: label,
      value: [currentDate.toDate(), currentDate.toDate()],
      timeFilterValue: 'daily',
    };
  }

  if (timeFilterType === 'weekly') {
    const label = `Last 7 Days (${moment(startDateLast7Days).format(
      'D MMM'
    )} - ${moment(currentDate).format('D MMM YYYY')})`;

    return {
      label: label,
      value: [startDateLast7Days.toDate(), currentDate.toDate()],
      timeFilterValue: 'weekly',
    };
  }

  if (timeFilterType === 'monthly') {
    const label = `Last 30 Days (${moment(startDateLast30Days).format(
      'D MMM'
    )} - ${moment(currentDate).format('D MMM YYYY')})`;

    return {
      label: label,
      value: [startDateLast30Days.toDate(), currentDate.toDate()],
      timeFilterValue: 'monthly',
    };
  }

  return {
    label: '',
    value: null,
    timeFilterValue: 'custom',
  };
};

export const fillEmptyDateXAxis = <T>(props: {
  startDate: Moment;
  endDate: Moment;
  data: T[];
  propsToFill: string[];
}) => {
  const { startDate, endDate, data, propsToFill } = props;

  const docs: (
    | T
    | {
        date: string;
      }
  )[] = [];

  while (startDate <= endDate) {
    const date = startDate.format('YYYY-MM-DD');

    const dataFound = data.find((item) => item['date'] === date);

    const dataToFill = propsToFill.reduce((acc, prop) => {
      acc[prop] = dataFound ? dataFound[prop] : 0;
      return acc;
    }, {});

    docs.push({
      date: date,
      ...dataToFill,
    });

    startDate.add(1, 'days');
  }

  return docs as T[];
};
