const EditSquare = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.90195 8.12948L4.83985 10.6028C4.83718 10.7348 4.88726 10.8622 4.97941 10.9569C5.07223 11.0522 5.19911 11.1055 5.33132 11.1055H7.78867C8.29951 11.1055 8.78029 10.9069 9.14222 10.5455L14.0649 5.63077C14.4536 5.24343 14.6666 4.72809 14.6666 4.18008C14.6666 3.63341 14.4536 3.1174 14.0649 2.73006L13.267 1.93338C12.4657 1.13337 11.1629 1.13337 10.3622 1.93338L5.4622 6.82546C5.11363 7.17346 4.91464 7.6368 4.90195 8.12948ZM5.88489 8.15414C5.89157 7.91481 5.98773 7.6888 6.15801 7.51947L9.95889 3.72408L11.2076 4.9721C11.3038 5.06743 11.4293 5.11543 11.5555 5.11543C11.681 5.11543 11.8072 5.06743 11.9034 4.9721C12.095 4.77943 12.095 4.46942 11.9034 4.27742L10.654 3.03007L11.0574 2.62739C11.4747 2.21072 12.1538 2.21072 12.5718 2.62739L13.3698 3.42474C13.7871 3.84141 13.7871 4.51942 13.3698 4.93676L8.44708 9.8515C8.27079 10.0275 8.03708 10.1235 7.78867 10.1235H5.83615L5.88489 8.15414ZM1.82552 8.29541C2.09663 8.29541 2.31699 8.07541 2.31699 7.80474V5.29203C2.31699 3.49867 3.41145 2.33999 5.10555 2.33999H7.55689C7.82867 2.33999 8.04903 2.12065 8.04903 1.84932C8.04903 1.57864 7.82867 1.35864 7.55689 1.35864H5.10555C2.8492 1.35864 1.33339 2.93933 1.33339 5.29203V7.80474C1.33339 8.07541 1.55375 8.29541 1.82552 8.29541ZM14.1722 7.6066C13.9005 7.6066 13.6808 7.82594 13.6808 8.09728V10.7333C13.6808 12.5267 12.5857 13.6847 10.8909 13.6847H5.10542C3.41132 13.6847 2.31753 12.5267 2.31753 10.7333C2.31753 10.4626 2.09717 10.2426 1.82539 10.2426C1.55428 10.2426 1.33325 10.4626 1.33325 10.7333C1.33325 13.086 2.84906 14.6667 5.10542 14.6667H10.8909C13.1479 14.6667 14.6637 13.086 14.6637 10.7333V8.09728C14.6637 7.82594 14.4434 7.6066 14.1722 7.6066Z"
        fill="#5F5F5F"
      />
    </svg>
  );
};

EditSquare.Primary = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1101 1.34014C12.0691 1.2802 13.0148 1.61319 13.7274 2.26585C14.3801 2.97845 14.7131 3.92414 14.6598 4.8898V11.11C14.7197 12.0757 14.3801 13.0214 13.7341 13.734C13.0215 14.3866 12.0691 14.7196 11.1101 14.6597H4.88983C3.92416 14.7196 2.97846 14.3866 2.26586 13.734C1.6132 13.0214 1.2802 12.0757 1.34014 11.11V4.8898C1.2802 3.92414 1.6132 2.97845 2.26586 2.26585C2.97846 1.61319 3.92416 1.2802 4.88983 1.34014H11.1101ZM7.32067 11.2299L11.8027 6.73456C12.209 6.32165 12.209 5.65568 11.8027 5.24943L10.937 4.38366C10.524 3.97075 9.85806 3.97075 9.44516 4.38366L8.99895 4.83652C8.93235 4.90312 8.93235 5.01634 8.99895 5.08294C8.99895 5.08294 10.0579 6.13518 10.0778 6.16182C10.1511 6.24174 10.1977 6.34829 10.1977 6.46817C10.1977 6.70792 10.0046 6.90771 9.75817 6.90771C9.64495 6.90771 9.53839 6.8611 9.46513 6.78784L8.35294 5.68232C8.29966 5.62904 8.20643 5.62904 8.15315 5.68232L4.97641 8.85903C4.75663 9.0788 4.6301 9.37183 4.62344 9.68484L4.58348 11.2632C4.58348 11.3498 4.61012 11.4297 4.67006 11.4896C4.73 11.5496 4.80991 11.5829 4.89649 11.5829H6.46155C6.78122 11.5829 7.08758 11.4563 7.32067 11.2299Z"
        fill="#6014C4"
      />
    </svg>
  );
};

EditSquare.Large = ({ fillWithCurrentColor = false }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.57692 2.32422H6.46108C3.89858 2.32422 2.29192 4.13839 2.29192 6.70672V13.6351C2.29192 16.2034 3.89108 18.0176 6.46108 18.0176H13.8144C16.3852 18.0176 17.9844 16.2034 17.9844 13.6351V10.2784"
        stroke={fillWithCurrentColor ? 'currentColor' : '#484848'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.35649 9.10074L13.584 2.87324C14.3598 2.09824 15.6173 2.09824 16.3932 2.87324L17.4073 3.88741C18.1832 4.66324 18.1832 5.92158 17.4073 6.69658L11.1498 12.9541C10.8107 13.2932 10.3507 13.4841 9.87066 13.4841H6.74899L6.82733 10.3341C6.83899 9.87074 7.02816 9.42908 7.35649 9.10074Z"
        stroke={fillWithCurrentColor ? 'currentColor' : '#484848'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6377 3.83545L16.4427 7.64045"
        stroke={fillWithCurrentColor ? 'currentColor' : '#484848'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditSquare;
