export enum PreRegistLocalStorageKey {
  PRE_REGIST = 'pre-regist',
}

export enum LocalStorageAuthKey {
  AUTH = 'job-portal-auth',
  LAST_REFRESH_TIMESTAMP = 'employer-last-refresh-timestamp',
  LOGIN_COUNT = 'job-portal-login-counter',
  UTILS = 'job-portal-utils',
}
