const ImageIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1402 5.26613V10.7001C14.1402 12.7135 12.8802 14.1335 10.8668 14.1335H5.10016C3.08683 14.1335 1.8335 12.7135 1.8335 10.7001V5.26613C1.8335 3.2528 3.0935 1.83347 5.10016 1.83347H10.8668C12.8802 1.83347 14.1402 3.2528 14.1402 5.26613Z"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.521 10.9539L4.53966 9.8786C4.89366 9.50326 5.48366 9.48526 5.85966 9.8386C5.871 9.84993 6.48433 10.4733 6.48433 10.4733C6.85433 10.8499 7.459 10.8559 7.83566 10.4866C7.86033 10.4626 9.39166 8.60526 9.39166 8.60526C9.78633 8.12593 10.495 8.05726 10.975 8.4526C11.007 8.47926 12.4537 9.96393 12.4537 9.96393"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87529 6.08873C6.87529 6.73473 6.35195 7.25807 5.70595 7.25807C5.05995 7.25807 4.53662 6.73473 4.53662 6.08873C4.53662 5.44273 5.05995 4.9194 5.70595 4.9194C6.35195 4.92007 6.87529 5.44273 6.87529 6.08873Z"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ImageIcon.Primary = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.11071 14.6667H10.8885C13.1487 14.6667 14.6666 13.0813 14.6666 10.7222V5.27781C14.6666 2.91874 13.1487 1.33333 10.8891 1.33333H5.11071C2.85118 1.33333 1.33325 2.91874 1.33325 5.27781V10.7222C1.33325 13.0813 2.85118 14.6667 5.11071 14.6667ZM5.66582 7.33333C4.74673 7.33333 3.99992 6.58551 3.99992 5.66667C3.99992 4.74782 4.74673 4 5.66582 4C6.58425 4 7.33173 4.74782 7.33173 5.66667C7.33173 6.58551 6.58425 7.33333 5.66582 7.33333ZM13.2138 9.95597C13.437 10.5284 13.3211 11.2164 13.0824 11.7833C12.7995 12.4575 12.2578 12.968 11.5754 13.1909C11.2724 13.29 10.9546 13.3333 10.6375 13.3333H5.01901C4.45991 13.3333 3.96517 13.1992 3.55959 12.9494C3.30551 12.7926 3.2606 12.4307 3.44897 12.1961C3.76406 11.8039 4.07511 11.4104 4.38885 11.0134C4.98684 10.2539 5.38974 10.0337 5.83755 10.227C6.01923 10.3068 6.20157 10.4266 6.38928 10.5531C6.88939 10.893 7.58458 11.3602 8.50032 10.8531C9.12699 10.5021 9.49045 9.90009 9.80697 9.37581L9.81226 9.36704C9.83469 9.33027 9.85692 9.29349 9.87908 9.25681L9.8791 9.25679C9.98547 9.08079 10.0904 8.90714 10.2091 8.74716C10.358 8.54695 10.9097 7.92088 11.6243 8.3667C12.0795 8.6474 12.4623 9.02717 12.8719 9.43378C13.0281 9.58927 13.1394 9.76608 13.2138 9.95597Z"
        fill="#6014C4"
      />
    </svg>
  );
};

export default ImageIcon;
