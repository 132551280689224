import { Modal } from 'antd';
import clsx from 'clsx';
import Link from 'next/link';
import { useState } from 'react';

import useWindowSize from '@/common/hooks/useWindowSize';
import CloseIcon from '@/icons/CloseIcon';
import jobPostNavigationImage from '@/modules/notifications/images/job-post-navigation.png';
import TechnicalTestImage from '@/modules/notifications/images/technical-test.png';

import * as styles from './index.module.scss';

const CustomStepAnnouncementModal = ({ size }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [width] = useWindowSize();

  return (
    <>
      <button
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        type="button"
        className={clsx(
          'inline-flex w-auto text-left text-[10px] font-bold text-violet-600',
          {
            'md:text-sm': size === 'large',
          }
        )}
      >
        See Preview
      </button>

      <Modal
        visible={isOpen}
        closable={false}
        footer={null}
        className={clsx(styles.custom_step_announcement_modal, 'relative')}
        centered={true}
        width={width > 1024 ? '554px' : '328px'}
        zIndex={10000}
      >
        <div className="relative flex min-h-[460px] justify-center bg-violet-50 lg:min-h-[585px]">
          <img
            className="absolute top-[24px] left-[-5px] h-[34px] w-[196px] lg:left-[9px]"
            src={jobPostNavigationImage.src}
            alt="Job Post Navigation Image"
          />
          <img
            className="absolute bottom-[5.5rem] h-auto w-[85%] lg:bottom-0 lg:h-auto lg:w-[474px]"
            style={{
              filter:
                'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.08))',
            }}
            src={TechnicalTestImage.src}
            alt="Technical Test Image"
          />
          <button
            onClick={() => setIsOpen((isOpen) => !isOpen)}
            type="button"
            className="absolute top-4 right-4"
          >
            <CloseIcon />
          </button>
          <div className="absolute bottom-0 z-[1] flex w-full">
            <div className="absolute bottom-[30px] z-[2] mx-auto flex w-full flex-col items-center justify-center lg:bottom-[19px]">
              <h1 className="w-[80%] text-center text-sm font-bold leading-[130%] text-neutral-1000 lg:w-full lg:text-base">
                Customize your hiring flow to track candidates’ progress
              </h1>
              <p className="mx-auto mb-5 mt-2 w-[80%] text-center text-[11px] font-normal leading-[130%] text-neutral-900 lg:max-w-[477px] lg:text-[13px]">
                You can add/edit steps based on your recruitment flow when
                making or editing your job post
              </p>

              <TryNowLink />
            </div>

            <Curve className="h-[315px] lg:block lg:h-auto lg:w-full" />
            <div className="absolute bottom-0 block h-[190px] w-full bg-white lg:hidden" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export const TryNowLink = () => {
  return (
    <Link
      href="/job-post/create"
      type="button"
      className="flex h-9 w-24 items-center justify-center rounded-[35px] bg-violet-600 text-xs font-bold text-blumine-0 hover:text-white lg:h-9 lg:text-xs">
      
        Try Now
      
    </Link>
  );
};

const Curve = (props) => {
  return (
    <svg
      width="554"
      height="210"
      viewBox="0 0 554 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M554 0C350.282 91.3362 99.784 38.0568 0 0V210H554V0Z"
        fill="white"
      />
    </svg>
  );
};

export default CustomStepAnnouncementModal;
