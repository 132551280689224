import { debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';

import {
  MIXPANEL_HOST_NAME,
  MIXPANEL_PROJECT_TOKEN,
  MIXPANEL_PROXY,
} from '@/modules/core/mixpanel/configs';
import { getUserProfile } from '@/modules/core/mixpanel/utils';

const useAnalytics = () => {
  const checkEnv = () => {
    if (typeof window !== 'undefined') {
      return window?.location?.hostname === MIXPANEL_HOST_NAME;
    } else {
      return false;
    }
  };

  const env_check = checkEnv();

  const projectToken = MIXPANEL_PROJECT_TOKEN;

  mixpanel.init(projectToken, {
    debug: env_check ? false : true,
    api_host: MIXPANEL_PROXY,
  });

  const analytics = {
    // Identify
    identify: (id) => {
      let tempUserId = id;
      if (!tempUserId) {
        const userProfile = getUserProfile();
        if (userProfile?.userId) tempUserId = userProfile?.userId;
      }
      if (tempUserId) mixpanel.identify(tempUserId);
    },

    // Alias
    alias: (id) => mixpanel.alias(id),

    // Track
    track: debounce((name, withUserProfile = true, props) => {
      let tempOption = {};
      if (withUserProfile) {
        const userProfile = getUserProfile();
        tempOption = userProfile;
      }

      if (props) {
        tempOption = { ...tempOption, ...props };
      }

      if (name) mixpanel.track(name, tempOption);
    }, 500),

    // People
    people: {
      set: (props) => {
        mixpanel.people.set(props);
      },
    },

    // Reset
    reset: () => {
      mixpanel.reset();
    },
  };

  return {
    analytics,
  };
};

export default useAnalytics;
