import { SVGProps } from 'react';

const ActivityIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.8291 9.855L6.82452 7.26156L9.10065 9.04951L11.0534 6.5293"
        stroke="#313131"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="13.3303"
        cy="2.801"
        r="1.28146"
        stroke="#313131"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9492 2.08008H5.10404C3.09641 2.08008 1.85156 3.50189 1.85156 5.50952V10.8978C1.85156 12.9054 3.072 14.3211 5.10404 14.3211H10.8401C12.8477 14.3211 14.0926 12.9054 14.0926 10.8978V6.20517"
        stroke="#313131"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ActivityIcon.Primary = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4531 2.94065C11.4531 2.05398 12.1731 1.33398 13.0597 1.33398C13.9464 1.33398 14.6664 2.05398 14.6664 2.94065C14.6664 3.82732 13.9464 4.54732 13.0597 4.54732C12.1731 4.54732 11.4531 3.82732 11.4531 2.94065ZM8.88634 9.84018L10.813 7.35418L10.7863 7.36752C10.893 7.22085 10.913 7.03418 10.8397 6.86752C10.767 6.70085 10.6063 6.58752 10.4337 6.57418C10.253 6.55418 10.0737 6.63418 9.96634 6.78085L8.35367 8.86752L6.50634 7.41418C6.39301 7.32752 6.25967 7.29352 6.12634 7.30752C5.99367 7.32752 5.87367 7.40018 5.79301 7.50685L3.82034 10.0742L3.77967 10.1342C3.66634 10.3469 3.71967 10.6202 3.91967 10.7675C4.01301 10.8275 4.11301 10.8675 4.22634 10.8675C4.38034 10.8742 4.52634 10.7935 4.61967 10.6675L6.29301 8.51352L8.19301 9.94085L8.25301 9.98018C8.46634 10.0935 8.73301 10.0409 8.88634 9.84018ZM10.2997 2.52085C10.273 2.68752 10.2597 2.85418 10.2597 3.02085C10.2597 4.52085 11.473 5.73352 12.9663 5.73352C13.133 5.73352 13.293 5.71418 13.4597 5.68752V11.0669C13.4597 13.3275 12.1263 14.6675 9.85967 14.6675H4.93367C2.66634 14.6675 1.33301 13.3275 1.33301 11.0669V6.13418C1.33301 3.86752 2.66634 2.52085 4.93367 2.52085H10.2997Z"
        fill="#6913D8"
      />
    </svg>
  );
};

export default ActivityIcon;
