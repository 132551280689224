const HomeIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M6.10489 13.8476V11.8031C6.10488 11.2831 6.52883 10.8606 7.05409 10.8571H8.97814C9.50591 10.8571 9.93375 11.2806 9.93375 11.8031V11.8031V13.854C9.93363 14.2955 10.2896 14.6563 10.7354 14.6667H12.0181C13.2968 14.6667 14.3334 13.6405 14.3334 12.3746V12.3746V6.55857C14.3266 6.06056 14.0904 5.59291 13.6921 5.2887L9.30523 1.79021C8.5367 1.18105 7.44422 1.18105 6.67569 1.79021L2.3081 5.29505C1.90825 5.59803 1.67167 6.06645 1.66675 6.56492V12.3746C1.66675 13.6405 2.70333 14.6667 3.98202 14.6667H5.26472C5.72165 14.6667 6.09206 14.3 6.09206 13.8476V13.8476"
        stroke="#5F5F5F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

HomeIcon.Primary = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M6.08993 13.8489V11.8104C6.08993 11.2901 6.51486 10.8682 7.03904 10.8682H8.95515C9.20687 10.8682 9.44828 10.9675 9.62627 11.1442C9.80427 11.3209 9.90426 11.5605 9.90426 11.8104V13.8489C9.90267 14.0652 9.98812 14.2733 10.1417 14.4268C10.2952 14.5803 10.5041 14.6667 10.722 14.6667H12.0293C12.6398 14.6682 13.2259 14.4286 13.6582 14.0005C14.0905 13.5725 14.3334 12.9913 14.3334 12.3852V6.57792C14.3334 6.08832 14.1148 5.62391 13.7365 5.30979L9.28943 1.78392C8.51585 1.16572 7.40749 1.18568 6.65701 1.83133L2.31142 5.30979C1.91524 5.61465 1.67845 6.08043 1.66675 6.57792V12.3793C1.66675 13.6426 2.69834 14.6667 3.97086 14.6667H5.24827C5.7009 14.6667 6.06875 14.3042 6.07203 13.8548L6.08993 13.8489Z"
        fill="#6014C4"
        stroke="#6014C4"
      />
    </svg>
  );
};

export default HomeIcon;
