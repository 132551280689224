import Cookies from 'js-cookie';

import {
  COOKIE_REFRESH_TOKEN_NAME,
  COOKIE_TOKEN_NAME,
} from '@/common/configs/auth';

const useToken = () => {
  const tempRefreshToken = Cookies.get(COOKIE_REFRESH_TOKEN_NAME);
  const tempAccessToken = Cookies.get(COOKIE_TOKEN_NAME);

  const refreshToken = tempRefreshToken ? JSON.parse(tempRefreshToken) : null;

  const token = tempAccessToken ? JSON.parse(tempAccessToken) : null;

  const saveToken = (token) => {
    Cookies.set(COOKIE_TOKEN_NAME, JSON.stringify(token.token), {
      expires: new Date(token.refreshExpire),
      secure: true,
    });

    Cookies.set(COOKIE_REFRESH_TOKEN_NAME, JSON.stringify(token.refreshToken), {
      expires: new Date(token.refreshExpire),
      secure: true,
    });
  };

  return {
    refreshToken,
    token,
    saveToken,
  };
};

export default useToken;
