import { useLayoutEffect, useState } from 'react';

type useWindowsSize = () => [number, number];

/** @deprecated use https://ahooks-v2.js.org/hooks/dom/use-size instead */
const useWindowSize: useWindowsSize = () => {
  const [size, setSize] = useState<[number, number]>([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};

export default useWindowSize;
