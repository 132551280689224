import _ from 'lodash';
import { createContext, useContext, useMemo, useReducer } from 'react';

import { getLocalStorageValue } from '@/common/helpers';
import { FixMeLater } from '@/common/types';

import { VALID_TALENT_DB_SORT_PARAMS } from '../constants';
import { TalentDatabaseLocalStorageKeys } from '../types/enums';

export const actionTypes = {
  vacancyOwnersFilter: 'FILTER_VACANCY_OWNERS',
  deselectFilter: 'DESELECT_FILTER',
  applyFilterAndSort: 'APPLY_FILTER_AND_SORT',
  clearAllSorterAndFilters: 'CLEAR_ALL_SORTER_AND_FILTERS',
  searchData: 'SEARCH_DATA',
};

export const TalentDatabaseFilterContext = createContext<
  FixMeLater & {
    dispatch: FixMeLater;
    params: FixMeLater;
    isFilterActive: boolean;
  }
>({});

export const talentDatabaseValues =
  typeof window !== 'undefined' &&
  getLocalStorageValue(TalentDatabaseLocalStorageKeys.TALENT_DATABASE_VALUES);

let initialState = {
  ...talentDatabaseValues,
  ...(talentDatabaseValues?.sorter &&
    _.isEmpty(talentDatabaseValues?.sorter) && {
      sorter: { name: 'talentDb', direction: 'desc' },
    }),
};

const handlePreviousSorterVersion = (initialState) => {
  const validSortParam = VALID_TALENT_DB_SORT_PARAMS.includes(
    initialState.sorter?.name
  );

  if (!validSortParam) {
    const newInitialStateValue = {
      ...initialState,
      sorter: {
        ...initialState.sorter,
        name: 'talentDb',
        direction: 'asc',
      },
    };

    return newInitialStateValue;
  }

  return initialState;
};

initialState = { ...handlePreviousSorterVersion(initialState) };

const talentDatabaseReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.vacancyOwnersFilter:
      return {
        ...state,
        jobAuthorId: action.payload.jobAuthorId,
      };
    case actionTypes.applyFilterAndSort: {
      const columnFilters = action.payload.selectedFilters?.filter(
        (selectedFilter) => selectedFilter?.column === action.payload.column
      );

      const payloads = {
        ...state,
        ...(action.payload?.column && {
          [action.payload.column]: columnFilters,
        }),
        sorter: action?.payload?.sorterInfo ?? {
          name: 'talentDb',
          direction: 'asc',
        },
      };

      try {
        localStorage.setItem(
          'talent-database-values',
          JSON.stringify(payloads)
        );
      } catch (err) {
        console.error(err);
      }

      return {
        ...payloads,
      };
    }
    case actionTypes.searchData: {
      const payloads = {
        ...state,
        search: action.payload.search,
      };

      try {
        localStorage.setItem(
          'talent-database-values',
          JSON.stringify(payloads)
        );
      } catch (err) {
        console.error(err);
      }

      return {
        ...state,
        search: action.payload.search,
      };
    }
    case actionTypes.clearAllSorterAndFilters: {
      const initState = {
        sorter: { name: 'talentDb', direction: 'asc' },
      };

      try {
        localStorage.setItem(
          'talent-database-values',
          JSON.stringify(initState)
        );
      } catch (err) {
        console.error(err);
      }
      return initState;
    }
  }
};

export const TalentDatabaseFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(talentDatabaseReducer, initialState);

  const params = useMemo(() => {
    let temp: {
      jobRoleIds?: string[];
      eligibilities?: string[];
      salaries?: string[];
      jobSeekingStatus?: string[];
      jobAuthorId?: string[];
      search?: string;
      campuses?: string[];
      names?: string[];
      selectAllName?: boolean;
      sortParam?: string;
      sortBy?: string;
    } = {};

    // Filter params
    if (state['appliedRoles']?.length > 0) {
      temp.jobRoleIds = state['appliedRoles']?.map((filter) => filter?.value);
    }

    if (state['experience']?.length > 0) {
      temp.eligibilities = state['experience']?.map((filter) => filter?.value);
    }

    if (state['minSalary']?.length > 0) {
      temp.salaries = state['minSalary']?.map((filter) => filter?.value);
    }

    if (state['jobSeekingStatus']?.length > 0) {
      temp.jobSeekingStatus = state['jobSeekingStatus']?.map(
        (filter) => filter?.value
      );
    }

    if (state['jobAuthorId']?.length > 0) {
      temp.jobAuthorId = state['jobAuthorId'];
    }

    if (state['search']) {
      temp.search = state['search'];
    }

    if (state['campus']) {
      temp.campuses = state['campus']?.map((filter) => filter?.value);
    }

    if (state['name']) {
      temp.names = state['name']?.map((filter) => filter?.value);
      // should be turned into dynamic later
      temp.selectAllName = false;
    }

    //Sorter Param
    if (state.sorter) {
      if (state?.sorter?.name) {
        temp['sortParam'] = state.sorter.name;
      }
      if (state?.sorter?.direction) {
        temp['sortBy'] = state.sorter.direction;
      }
    }

    return temp;
  }, [state]);

  const isFilterActive = useMemo(() => {
    let activeFilters: (string | boolean | string[])[] = [];

    const filters = Object.values(_.omit(state, 'sorter'));

    filters.forEach((val) => {
      if (Array.isArray(val) && val?.length > 0) {
        activeFilters.push(val);
      }
    });

    const isFiltered = activeFilters.length > 0;
    const isSorted =
      state?.sorter?.name !== undefined &&
      state?.sorter?.name !== 'talentDb' &&
      state?.sorter?.direction !== undefined;

    return isFiltered || isSorted;
  }, [state]);

  const value = useMemo(
    () => ({
      filters: _.omit(state, 'sorter'),
      sorterInfo: state.sorter,
      dispatch,
      params,
      isFilterActive,
    }),
    [state, dispatch]
  );

  return (
    <TalentDatabaseFilterContext.Provider value={value}>
      {children}
    </TalentDatabaseFilterContext.Provider>
  );
};

export const useTalentDatabaseFilter = () =>
  useContext(TalentDatabaseFilterContext);
