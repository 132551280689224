export const PERMISSION_ACTIONS = {
  view: 'view',
  manage: 'manage',
  create: 'create',
  denied: 'denied',
} as const;

export const PERMISSION_RESOURCES = {
  jobPost: 'jobPost',
  talentHunt: 'talentHunt',
  talentDb: 'talentDb',
  userCompany: 'userCompany',
  postAJob: 'postAJob',
  jobPostSalary: 'jobPostSalary',
  analyticsGeneral: 'analyticsGeneral',
} as const;

export const PERMISSION_ERROR_MESSAGES = {
  accessDenied: 'Access Denied',
};

export const DEPARTMENTS = {
  ceoOffice: {
    label: 'CEO Office',
    value: 'ceo-office',
    id: '631ee84ac806367a165f1217',
  },
  hr: {
    label: 'Human Resource',
    value: 'hr',
    id: '631ee839c806367a165f1216',
  },
};

export const ACTIONS_LABEL = {
  jobPost: {
    manage: 'Editor',
    view: 'Viewer',
    create: 'Allow',
    denied: 'Denied',
    viewSalaryWithViewJob: 'Viewer + See Salary',
    viewSalaryWithManageJob: 'Editor + See Salary',
  },
  talentDb: {
    manage: 'Allow',
    view: 'Viewer',
    denied: 'Denied',
  },
  talentHunt: {
    manage: 'Allow',
    view: 'Viewer',
    denied: 'Denied',
  },
  userCompany: {
    manage: 'Editor',
    view: 'Viewer',
    denied: 'Denied',
  },
  jobPostSalary: {
    manage: 'Allow',
    view: 'Viewer',
    denied: 'Denied',
  },
  analyticsGeneral: {
    view: 'Allow',
    denied: 'Denied',
  },
};

export const VIEW_ONLY_ERROR =
  'Your permission is view only. Contact Admin if you need access to edit';
