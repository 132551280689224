import {
  AnalyticsJobType,
  TimeFilterOption,
  TimeFilterOptionValue,
} from '../types';

export const timeFilterOptions: TimeFilterOption[] = [
  {
    label: 'All Time',
    value: 'allTime',
  },
  {
    label: 'Today',
    value: 'daily',
  },
  {
    label: 'Last 7 Days',
    value: 'weekly',
  },
  {
    label: 'Last 30 Days',
    value: 'monthly',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];

export const JOB_FILTER_TYPE: Record<AnalyticsJobType, AnalyticsJobType> = {
  allJob: 'allJob',
  allActiveJob: 'allActiveJob',
  selectedJob: 'selectedJob',
} as const;

export const HORIZONTAL_LABEL_Y_AXIS_WIDTH = 120;
export const HORIZONTAL_CHART_GAP = 16;
export const VERTICAL_BAR_PADDING = 32;

export const VERTICAL_CHART_GAP = 16;
export const VERTICAL_Y_AXIS_LABEL_WIDTH = 48;

export const PREDEFINED_COLORS_RECRUITMENT_STEPS = [
  '#6913D8',
  '#5072FF',
  '#FFD934',
  '#76C3EF',
  '#F4A42A',
  '#3ADB9A',
];

export const LINE_CHART_TICK_Y_PLACEHOLDER = [0, 2, 4, 6, 8, 10];

export const ANALYTICS_NEW_RELEASE_DATE_STRG_IDENTIFIER = 'analyticsLaunchDate';
export const ANALYTICS_NEW_RELEASE_CLICK_STATUS_STRG_IDENTIFIER =
  'analyticsButtonClicked';

export const RECORDER_START_DATE = '2023-10-22';

export const TIME_FILTER_LABEL_MAP: Record<TimeFilterOptionValue, string> = {
  allTime: 'All Time',
  daily: 'Today',
  monthly: 'Last 30 Days',
  weekly: 'Last 7 Days',
  custom: 'Custom',
};
