import { Collapse, message, Modal } from 'antd';
import clsx from 'clsx';
import { FC, useState } from 'react';

import Button from '@/common/components/Button';
import { useStoreAuth } from '@/common/contexts/useAuth';
import { createHRISAdmin } from '@/common/services/HRISService';
import { parseErrorMessage } from '@/common/utils';
import CloseIcon from '@/icons/CloseIcon';
import useSwitchPlatform from '@/modules/auth/hooks/useSwitchPlatform';
import ArrowBottomIcon from '@/modules/settings/icons/ArrowBottomIcon';

import * as styles from './index.module.scss';

const { Panel } = Collapse;

type InitialAccessConfirmationModalProps = {
  opened: boolean;
  onCancel: () => void;
};

const InitialAccessConfirmationModal: FC<
  InitialAccessConfirmationModalProps
> = ({ opened = false, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const { state } = useStoreAuth();
  const { company, userId = null } = state?.companyData ?? {};
  const { name = null } = company ?? {};

  const { redirectToHRIS } = useSwitchPlatform();

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const response = await createHRISAdmin({
        payload: {
          userId: String(userId),
          from: 'job-portal',
        },
      });
      if (response) {
        redirectToHRIS();
      }
    } catch (err) {
      const errorMessage = parseErrorMessage(err);
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={opened}
      width={480}
      footer={null}
      className={styles['hris_initial_access_confirmation_modal']}
      closeIcon={<CloseIcon />}
      onCancel={() => onCancel()}
    >
      <div className="px-6 py-4">
        <div className="text-[15px] font-bold leading-[130%] text-neutral-1000">
          Try Deall HRIS for Free Now!
        </div>
        <div className="mt-3">
          <p className="text-xs leading-[150%] text-neutral-900">
            Please do keep in mind that you&apos;ll be automatically assigned as
            the <strong>Super Admin</strong> there, as you&apos;re the first one
            {name && (
              <span>
                {` `}from <strong>{name}</strong>
              </span>
            )}{' '}
            who uses it.
          </p>
        </div>
        <div className="mt-3">
          <Collapse
            ghost
            expandIconPosition="right"
            expandIcon={({ isActive }) => {
              return (
                <ArrowBottomIcon
                  fill="#6913D8"
                  className={clsx(
                    'transition-all',
                    {
                      'top-0 -translate-y-1/2': !isActive,
                    },
                    {
                      '-translate-y-1/2 !rotate-180': isActive,
                    }
                  )}
                />
              );
            }}
          >
            <Panel key="1" header="What is Super Admin?" className="pb-0">
              <div className="mt-1 text-[11px] leading-[16.5px] text-neutral-700">
                Super Admin has unrestricted access to all features on Deall
                HRIS Admin Dashboard, including setting up other Admins&apos;
                access.
              </div>
            </Panel>
          </Collapse>
        </div>
        <div className="mt-4 flex items-center justify-end gap-2 border-t border-neutral-200 pt-4">
          <Button
            className="!h-[32px] w-[80px] !text-xs font-bold"
            type="outlineViolet"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="!h-[32px] w-[80px] !text-xs"
            onClick={handleConfirm}
            disabled={loading}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InitialAccessConfirmationModal;
