import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useEffect } from 'react';

import { NotificationKeys } from '../../generator/keys';
import { getNotificationStatus } from '../../services/NotificationService';
import { NotificationStatus } from '../../types';

export type UseNotificationStatusQueryProps = {
  options?: UseQueryOptions<NotificationStatus>;
};

const useNotificationStatusQuery = ({
  options,
}: UseNotificationStatusQueryProps = {}) => {
  const queryClient = useQueryClient();
  const { isFetching, ...rest } = useQuery({
    queryKey: [...NotificationKeys.status()],
    queryFn: async () => {
      const res = await getNotificationStatus();
      return res;
    },
    // refetchInterval 60 seconds
    refetchInterval: 60 * 1000,
    ...options,
  });

  useEffect(() => {
    if (!isFetching) {
      queryClient.invalidateQueries(NotificationKeys.list());
    }
  }, [isFetching]);

  return { ...rest, isFetching };
};

export default useNotificationStatusQuery;
