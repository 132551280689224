export default function ArrowDownIcon({ className, color }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.4122 12.7462C14.0878 13.0706 13.5619 13.0709 13.2372 12.7469L10.7063 10.2215C10.316 9.83204 9.68401 9.83204 9.29366 10.2215L6.76282 12.7469C6.43807 13.0709 5.91221 13.0706 5.58782 12.7462C5.26318 12.4216 5.26318 11.8952 5.58782 11.5706L9.29289 7.86551C9.68342 7.47499 10.3166 7.47499 10.7071 7.86551L14.4122 11.5706C14.7368 11.8952 14.7368 12.4216 14.4122 12.7462Z"
        fill={color}
      />
    </svg>
  );
}
