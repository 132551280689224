/* eslint-disable no-unused-vars */
import { Divider, Dropdown, message } from 'antd';
import { FC, useState } from 'react';

import { useStoreAuth } from '@/common/contexts/useAuth';
import { useSidebar } from '@/common/contexts/useSidebar';
import {
  getHRISAdminData,
  getHRISCompanyData,
} from '@/common/services/HRISService';
import { parseErrorMessage } from '@/common/utils';
import ArrowDownIcon from '@/icons/ArrowDownIcon';
import useSwitchPlatform from '@/modules/auth/hooks/useSwitchPlatform';

type Key = 'hris' | 'ats';

const PLATFORMS = [
  {
    key: 'ats',
    label: 'ATS',
  },
  {
    key: 'hris',
    label: 'HRIS',
  },
] as Array<{ key: Key; label: string; href: string }>;

type PlatformSwitcherProps = {
  setRequestCompanyModal: (value: boolean) => void;
  setSuperAdminConfirmationModal: (value: boolean) => void;
};

const PlatformSwitcher: FC<PlatformSwitcherProps> = ({
  setSuperAdminConfirmationModal,
  setRequestCompanyModal,
}) => {
  const DEFAULT_PLATFORM = 'ats';
  const [value] = useState<Key>(DEFAULT_PLATFORM);
  const { state } = useStoreAuth();
  const { redirectToHRIS } = useSwitchPlatform();
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const { setLoading } = useSidebar();

  const handleChangePlatform = async (key: Key) => {
    setLoading(true);
    setDropdownOpened(false);
    if (key === DEFAULT_PLATFORM) {
      return;
    }
    const responses = await Promise.allSettled([
      getHRISCompanyData({
        params: {
          email_domain_eq: state?.companyData?.company?.emailDomain,
        },
      }),
      getHRISAdminData({
        params: {
          user_id_eq: String(state?.companyData?.userId),
        },
      }),
    ]);

    const HRISCompanyData = responses[0];
    const HRISAdminData = responses[1];

    if (
      HRISCompanyData.status === 'rejected' &&
      HRISCompanyData.reason?.code !== 404
    ) {
      setLoading(false);
      const errorMessage = parseErrorMessage(HRISCompanyData.reason);
      message.error(errorMessage);
      return;
    }

    if (
      HRISAdminData.status === 'rejected' &&
      HRISAdminData.reason?.code !== 404
    ) {
      setLoading(false);
      const errorMessage = parseErrorMessage(HRISAdminData.reason);
      message.error(errorMessage);
      return;
    }

    // Company does not exist in HRIS
    if (HRISCompanyData.status === 'rejected') {
      setSuperAdminConfirmationModal(true);
      return;
    }

    // User is not part of the HRIS company
    if (
      HRISCompanyData.status === 'fulfilled' &&
      HRISAdminData.status === 'rejected'
    ) {
      setRequestCompanyModal(true);
    }

    // User is part of HRIS company
    if (
      HRISCompanyData.status === 'fulfilled' &&
      HRISAdminData.status === 'fulfilled'
    ) {
      // Redirect to destination
      redirectToHRIS();
    }
  };

  return (
    <div className="mt-5">
      <Dropdown
        dropdownRender={() => (
          <CustomDropdownRender onClickDropdownItem={handleChangePlatform} />
        )}
        open={dropdownOpened}
        onOpenChange={(opened) => {
          if (opened !== dropdownOpened) {
            setDropdownOpened(opened);
          }
        }}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentElement as HTMLElement
        }
        trigger={['click']}
      >
        <div className="flex w-full cursor-pointer items-center justify-between rounded bg-white py-[6px] pl-3 pr-[14px]">
          <div className="text-[11px] font-semibold text-neutral-900">
            {PLATFORMS.find((app) => app.key === value)?.label}
          </div>
          <ArrowDownIcon className="rotate-180" color="#313131" />
        </div>
      </Dropdown>
    </div>
  );
};

const CustomDropdownRender = ({
  onClickDropdownItem,
}: {
  onClickDropdownItem: (_: Key) => void;
}) => {
  return (
    <div className="rounded bg-white px-2 py-3">
      {PLATFORMS.map((platform, index) => (
        <>
          <div
            role="button"
            className="flex items-center justify-between !text-[11px] font-medium"
            onClick={() => onClickDropdownItem(platform.key)}
          >
            {platform.label}
          </div>
          {index === 0 ? <Divider className="my-3 " /> : null}
        </>
      ))}
    </div>
  );
};

export default PlatformSwitcher;
