import 'antd/dist/antd.css';
import '@/common/styles/global.scss';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'swiper/css';

import { AppProps } from 'next/app';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';

import ProvidersWithErrorBoundary from '@/common/components/Providers';
import MainLayout from '@/common/layouts/MainLayout';
import { AppPropsWithLayout } from '@/common/types';
import { RouteGuard } from '@/modules/auth/components/RouteGuard';
import { GTM_ID } from '@/modules/core/gtm/configs';

import { HJID, HJSV } from '../modules/core/hotjar/configs';

const Page = ({
  Component,
  pageProps,
}: Pick<AppProps, 'pageProps' | 'Component'>) => {
  return (
    <RouteGuard>
      <Component {...pageProps} />
    </RouteGuard>
  );
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
    hotjar.initialize(HJID, HJSV);
  }, []);

  const getLayout =
    Component.getLayout ?? ((page) => <MainLayout>{page}</MainLayout>);

  return (
    <>
      <ProvidersWithErrorBoundary pageProps={pageProps}>
        {getLayout(<Page pageProps={pageProps} Component={Component} />)}
      </ProvidersWithErrorBoundary>
    </>
  );
};

export default App;
