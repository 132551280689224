import { createContext, PropsWithChildren, useContext, useState } from 'react';

type SidebarContextType = {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  mobileCollapsed: boolean;
  setMobileCollapsed: (value: boolean) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
};

const SidebarContext = createContext<SidebarContextType>(
  {} as SidebarContextType
);

export const SidebarProvider = ({ children }: PropsWithChildren) => {
  const [collapsed, setCollapsed] = useState(false);
  const [mobileCollapsed, setMobileCollapsed] = useState(true);
  const [loading, setLoading] = useState(false);

  return (
    <SidebarContext.Provider
      value={{
        collapsed,
        setCollapsed,
        mobileCollapsed,
        setMobileCollapsed,
        loading,
        setLoading,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  return useContext(SidebarContext);
};
