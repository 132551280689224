import Cookies from 'js-cookie';

import {
  COOKIE_REFRESH_TOKEN_NAME,
  COOKIE_TOKEN_NAME,
} from '@/common/configs/auth';
import { generateCookieDomain } from '@/modules/auth/utils/generator';

export const signOut = (callback) => {
  if (typeof window !== 'undefined') {
    const cookieDomain = generateCookieDomain();
    const cookieOptions = { domain: cookieDomain };

    Cookies.remove(COOKIE_TOKEN_NAME);
    Cookies.remove(COOKIE_REFRESH_TOKEN_NAME);
    Cookies.remove(COOKIE_TOKEN_NAME, cookieOptions);
    Cookies.remove(COOKIE_REFRESH_TOKEN_NAME, cookieOptions);

    if (typeof callback === 'function') {
      callback();
    }
  }
};
