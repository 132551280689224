const TwoUserIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.39434 10.1379C8.85367 10.1379 10.9557 10.5105 10.9557 11.9992C10.9557 13.4879 8.86767 13.8712 6.39434 13.8712C3.93434 13.8712 1.83301 13.5019 1.83301 12.0125C1.83301 10.5232 3.92034 10.1379 6.39434 10.1379Z"
      stroke="#5F5F5F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.39422 8.0132C4.77955 8.0132 3.47021 6.70453 3.47021 5.08987C3.47021 3.4752 4.77955 2.16653 6.39422 2.16653C8.00822 2.16653 9.31755 3.4752 9.31755 5.08987C9.32355 6.69853 8.02355 8.0072 6.41488 8.0132H6.39422Z"
      stroke="#5F5F5F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9888 7.2544C12.0561 7.1044 12.8781 6.1884 12.8801 5.07973C12.8801 3.98707 12.0834 3.0804 11.0388 2.90907"
      stroke="#5F5F5F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.397 9.82146C13.431 9.97546 14.153 10.3381 14.153 11.0848C14.153 11.5988 13.813 11.9321 13.2636 12.1408"
      stroke="#5F5F5F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

TwoUserIcon.Primary = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47507 5.17494C9.47507 6.93745 8.03279 8.34988 6.23305 8.34988C4.43396 8.34988 2.99103 6.93745 2.99103 5.17494C2.99103 3.41243 4.43396 2 6.23305 2C8.03279 2 9.47507 3.41243 9.47507 5.17494ZM1.3335 11.9449C1.3335 10.3134 3.59051 9.90516 6.23305 9.90516C8.88994 9.90516 11.1326 10.3274 11.1326 11.9602C11.1326 13.5918 8.87559 14 6.23305 14C3.57616 14 1.3335 13.5777 1.3335 11.9449ZM10.7824 5.2325C10.7824 6.13004 10.5072 6.96754 10.0245 7.66321C9.97422 7.73476 10.0186 7.83122 10.106 7.84656C10.2273 7.86636 10.3519 7.8785 10.4791 7.88105C11.7446 7.91363 12.8803 7.11574 13.1941 5.91412C13.6592 4.13117 12.2945 2.53029 10.5561 2.53029C10.3676 2.53029 10.1869 2.54945 10.0108 2.58459C9.98662 2.5897 9.96052 2.60119 9.94748 2.62164C9.93052 2.64783 9.94291 2.68169 9.95987 2.70404C10.4824 3.42144 10.7824 4.29471 10.7824 5.2325ZM12.8783 9.13487C13.729 9.29777 14.288 9.62931 14.5196 10.1129C14.7159 10.509 14.7159 10.9689 14.5196 11.365C14.1654 12.1137 13.0225 12.3545 12.5783 12.4165C12.4863 12.4293 12.4126 12.3526 12.4224 12.2619C12.6494 10.187 10.8444 9.20322 10.3774 8.97708C10.3578 8.96622 10.3532 8.95089 10.3552 8.94066C10.3565 8.93428 10.365 8.92405 10.38 8.92214C11.3904 8.90297 12.4772 9.03904 12.8783 9.13487Z"
      fill="#6014C4"
    />
  </svg>
);

export default TwoUserIcon;
