const AddJobIcon = (props) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" width="20" height="20" rx="6" fill="#F4F2FF" />
      <path
        d="M14 10C14 10.2761 13.7761 10.5 13.5 10.5H12C11.4477 10.5 11 10.9477 11 11.5V13C11 13.2761 10.7761 13.5 10.5 13.5C10.2239 13.5 10 13.2761 10 13V11.5C10 10.9477 9.55228 10.5 9 10.5H7.5C7.22386 10.5 7 10.2761 7 10C7 9.72386 7.22386 9.5 7.5 9.5H9C9.55228 9.5 10 9.05228 10 8.5V7C10 6.72386 10.2239 6.5 10.5 6.5C10.7761 6.5 11 6.72386 11 7V8.5C11 9.05228 11.4477 9.5 12 9.5H13.5C13.7761 9.5 14 9.72386 14 10Z"
        fill="#6913D8"
      />
    </svg>
  );
};

export default AddJobIcon;
