export const CompanyKeys = {
  all: ['company'] as const,
  me: () => [...CompanyKeys.all, 'me'] as const,
  one: ({ id }: { id?: string }) => {
    return [...CompanyKeys.all, 'one', id] as const;
  },
  emailDomain: (domain: string) => {
    return [...CompanyKeys.all, 'emailDomain', domain] as const;
  },
  byDomain: ({ domain }: { domain?: string }) => {
    return [...CompanyKeys.all, 'byDomain', domain] as const;
  },
};
