const NotificationActiveIcon = ({ color, active, ...props }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0001 29.335C29.1092 29.335 31.9355 28.5512 32.2084 25.4056C32.2084 22.2621 30.238 22.4642 30.238 18.6072C30.238 15.5945 27.3824 12.1667 23.0001 12.1667C18.6178 12.1667 15.7622 15.5945 15.7622 18.6072C15.7622 22.4642 13.7917 22.2621 13.7917 25.4056C14.0658 28.5631 16.892 29.335 23.0001 29.335Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5879 32.5953C24.11 34.2363 21.8047 34.2557 20.3127 32.5953"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* if there is no notification hide this */}
      {active ? <circle cx="29" cy="15" r="5" fill="#CD1B1B" /> : null}
    </svg>
  );
};

export default NotificationActiveIcon;
