import { Form, message, Modal } from 'antd';
import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';

import Button from '@/common/components/Button';
import CustomInput from '@/common/components/CustomInput';
import { useStoreAuth } from '@/common/contexts/useAuth';
import {
  Company,
  getHRISAccessPermissionRequestStatus,
  getHRISCompanyData,
  requestHRISAccessPermission,
} from '@/common/services/HRISService';
import { BackendError } from '@/common/types';
import { parseErrorMessage } from '@/common/utils';
import CheckCircleIcon from '@/icons/application-switcher/CheckCircleIcon';
import CloseOutlined from '@/icons/application-switcher/CloseOutlined';
import CloseIconModal from '@/modules/permissions/icons/CloseIconModal';

import * as styles from './index.module.scss';

type MessageContentProps = {
  canResendAt: string;
};

const MessageContent: FC<MessageContentProps> = ({ canResendAt }) => {
  return (
    <div className="relative grid grid-cols-[14px_632px] gap-2">
      <button
        className="absolute right-0 top-0"
        onClick={() => {
          message.destroy('hris-request-sent');
        }}
      >
        <CloseOutlined />
      </button>
      <span className="mt-[3px]">
        <CheckCircleIcon />
      </span>
      <div className="text-left">
        <div className="text-[13px] font-bold leading-[19.5px] text-neutral-1000">
          Your access request had been sent to (HRIS Super Admin&apos;s Email).
        </div>
        <div className="mr-[32px] mt-[2px] text-[11px] leading-[16.5px] text-neutral-1000">
          You&apos;ll be notified when your company&apos;s HRIS Admin had
          responded, you can re-request if there is no response until{' '}
          {canResendAt}
        </div>
      </div>
    </div>
  );
};

type RequestHRISAccessModalProps = {
  opened: boolean;
  onCancel: () => void;
};

const RequestHRISAccessModal: FC<RequestHRISAccessModalProps> = ({
  opened = false,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<Company | null>(null);
  const [form] = Form.useForm();
  const maxLengthNotes = 300;
  const watchNotes = Form.useWatch('note', form);
  const { state } = useStoreAuth();
  const { userId = null } = state?.companyData ?? {};

  const [requestAccessData, setRequestAccessData] = useState<{
    userId: string;
    status: string;
    canResend: boolean;
    updatedAt: string;
    emails: {
      name: string;
      email: string;
    }[];
  }>({
    userId: '',
    status: '',
    updatedAt: '',
    canResend: true,
    emails: [],
  });

  const canResendRequestAt = useMemo(() => {
    return requestAccessData.updatedAt
      ? moment(requestAccessData.updatedAt)
          .add(3, 'd')
          .format('DD MM YYYY HH:mm')
      : '';
  }, [requestAccessData.updatedAt]);

  const superAdminHRIS = requestAccessData.emails?.[0]?.email || '';

  const getHRISRequestAccessStatus = async () => {
    setLoading(true);
    try {
      const HRISCompany = await getHRISCompanyData({
        params: {
          email_domain_eq: state?.companyData?.company?.emailDomain,
        },
      });
      const HRISCompanyData = HRISCompany?.data?.data?.doc;
      setCompany(HRISCompanyData);
      const response = await getHRISAccessPermissionRequestStatus({
        params: {
          user_id_eq: state?.companyData?.userId,
          with: 'emails',
        },
      });
      const requestData = response?.data?.data?.doc;
      setRequestAccessData(requestData);
    } catch (error) {
      const tempError = error as BackendError;
      if (tempError.code !== 404) {
        const errorMessage = parseErrorMessage(error);
        message.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSendRequest = async (values) => {
    setLoading(true);
    try {
      const HRISCompanyId = company?.id;
      const result = await requestHRISAccessPermission({
        payload: {
          userId,
          companyId: HRISCompanyId,
          ...(values.note && {
            note: values.note,
          }),
          from: 'job-portal',
        },
      });

      if (result) {
        onCancel();
        message.open({
          key: 'hris-request-sent',
          className: styles['request_sent_message'],
          content: <MessageContent canResendAt={canResendRequestAt} />,
          duration: 10,
        });
      }
    } catch (error) {
      const errorMessage = parseErrorMessage(error);
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (opened) {
      getHRISRequestAccessStatus();
    }
  }, [opened]);

  return (
    <Modal
      open={opened}
      width={480}
      footer={null}
      className={styles['hris_request_modal']}
      closeIcon={<CloseIconModal />}
      onCancel={() => {
        onCancel();
      }}
    >
      <Form form={form} onFinish={handleSendRequest}>
        <div className="px-6 py-4">
          <RequestJoinCompanyHeader
            canResend={!!requestAccessData.canResend}
            superAdminEmail={superAdminHRIS}
            canResendAt={canResendRequestAt}
            companyName={company?.name || ''}
            status={requestAccessData.status}
          />
          {requestAccessData.canResend || !requestAccessData.status ? (
            <div className="mt-3">
              <CustomInput
                type="text-area"
                name="note"
                label="Notes (optional)"
                labelStyle={{
                  fontWeight: 500,
                  fontSize: '12px',
                  lineHeight: '100%',
                  color: '#313131',
                }}
                inputProps={{
                  maxLength: maxLengthNotes,
                }}
                placeholder="Supposed to be the HR Manager in-charge to manage the payroll."
                formClass="!mt-[8px] !mb-0"
                inputClass="placeholder:!text-neutral-300 !min-h-[120px] placeholder:!leading-[150%] placeholder:!text-xs !p-4 !text-xs !bg-neutral-50 !rounded-[6px] !border-neutral-150 !border !rounded-[6px]"
              />
              <span className="mt-1 block self-end text-right text-[10px] leading-[100%] text-neutral-600">
                {watchNotes?.length ?? 0}/{maxLengthNotes}
              </span>
            </div>
          ) : null}
          <div className="mt-4 flex items-center justify-end gap-2 border-t border-neutral-200 pt-4">
            <Button
              className="!h-[32px] w-[80px] !text-xs font-bold"
              type="outlineViolet"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="!h-[32px] w-[80px] !text-xs"
              htmlType="submit"
              disabled={loading || !requestAccessData.canResend}
            >
              Send
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

const RequestJoinCompanyHeader: FC<{
  canResend: boolean;
  superAdminEmail: string;
  companyName: string;
  canResendAt: string;
  status?: string;
}> = ({ canResend, superAdminEmail, canResendAt, status, companyName }) => {
  if (!canResend && status) {
    return (
      <>
        <div className="text-[15px] font-bold leading-[19.5px] text-neutral-1000">
          Your access request already sent to {superAdminEmail}
        </div>
        <div className="mt-3">
          <p className="text-xs leading-[18px] text-neutral-900">
            You&apos;ll be notified when{' '}
            <strong>{companyName ? `${companyName}'s` : null}</strong> HRIS
            Admin had responded, you can re-request if there is no response
            until <strong>{canResendAt}</strong>
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="text-[15px] font-bold leading-[19.5px] text-neutral-1000">
        Request Access to Deall&apos;s HRIS Admin Dashboard
      </div>
      <div className="mt-3">
        <p className="text-xs leading-[18px] text-neutral-900">
          Contact your Super Admin for permission to access Deall HRIS.
        </p>
      </div>
    </>
  );
};

export default RequestHRISAccessModal;
