/* eslint-disable @typescript-eslint/no-explicit-any */
interface Unsubscribe {
  (): void;
}
class VirtualLocalStorage {
  constructor(private hash: any = {}) {}

  getItem = (key: any) => this.hash[key];
  setItem = (key: any, value: any) => (this.hash[key] = value);
  removeItem = (key: any) => delete this.hash[key];
  clear = () => (this.hash = {});
  length = Object.keys(this.hash).length;
  key = (i: any) => Object.keys(this.hash)[i];
}

export const getDefaultAtomStorage = () => {
  // if window.localStorage is not available, use virtual local storage
  try {
    return window.localStorage;
  } catch (e) {
    return new VirtualLocalStorage() as Storage;
  }
};

export class LocalSyncStorage<Value> {
  private storage: Storage;

  constructor() {
    this.storage = getDefaultAtomStorage();
  }

  getItem(key: string, initialValue: Value): Value {
    const storedValue = this.storage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : initialValue;
  }

  setItem(key: string, newValue: Value): void {
    this.storage.setItem(key, JSON.stringify(newValue));
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  subscribe?(
    key: string,
    callback: (value: Value) => void,
    initialValue: Value
  ): Unsubscribe {
    const storageEventListener = (event: StorageEvent) => {
      if (event.key === key) {
        const newValue =
          event.newValue !== null ? JSON.parse(event.newValue) : initialValue;
        callback(newValue);
      }
    };

    // Add the event listener
    window.addEventListener('storage', storageEventListener);

    // Return the unsubscribe function
    return () => {
      // Remove the event listener when unsubscribed
      window.removeEventListener('storage', storageEventListener);
    };
  }
}

export default VirtualLocalStorage;
