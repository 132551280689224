export const MemberKeys = {
  all: ['member'] as const,
  invited: () => [...MemberKeys.all, 'invited'] as const,
  allInfinity: () => [...MemberKeys.all, 'infinity'] as const,
  invitedInfinity: () => [...MemberKeys.invited(), 'infinity'] as const,
};

export const UserCompanyKeys = {
  all: ['user-company'] as const,
  lastSeenAt: () => [...UserCompanyKeys.all, 'last-seen-at'] as const,
};
