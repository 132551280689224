import { Dispatch, SetStateAction, useState } from 'react';

import { PERMISSION_RESOURCES } from '@/modules/manage-team/constants';
import useGatePermission from '@/modules/permissions/hooks/useGatePermission';
import { GatePermission } from '@/modules/permissions/utils/generator/permissions';

import { Action, Resource } from '../types';

type PermissionPayload = {
  type: string;
  data: {
    resource: Resource;
    action: Action;
    page: {
      name: string;
      url: string;
    };
  };
};

type UseGeneralPermissionModalReturn = {
  canAccessTalentDB: boolean;
  canAccessTalentSearch: boolean;
  canAccessAnalytics: boolean;
  setRequestPermissionModalVisible: Dispatch<SetStateAction<boolean>>;
  setPermissionPayload: Dispatch<SetStateAction<PermissionPayload | null>>;
  setPermissionResource: Dispatch<SetStateAction<string | null>>;
  requestPermissionModalVisible: boolean;
  generatePermissionPayload: (path: string) => PermissionPayload | null;
  generatePermissionResource: (path: string) => string | null;
  permissionPayload: PermissionPayload | null;
  permissionResource: string | null;
};

const useGeneralPermissionModal = (): UseGeneralPermissionModalReturn => {
  const [requestPermissionModalVisible, setRequestPermissionModalVisible] =
    useState(false);
  const [permissionPayload, setPermissionPayload] =
    useState<PermissionPayload | null>(null);
  const [permissionResource, setPermissionResource] = useState<string | null>(
    null
  );

  const {
    granted: canManageTalentSearch,
    readyCheckPermission: readyCheckPermissionTalentSearch,
  } = useGatePermission({
    action: 'manage',
    resource: 'talentHunt',
  });

  const {
    granted: canManageTalentDB,
    readyCheckPermission: readyCheckPermissionTalentDB,
  } = useGatePermission({
    action: 'manage',
    resource: 'talentDb',
  });

  const {
    granted: canViewAnalyticsGeneral,
    readyCheckPermission: readyCheckPermissionAnalyticsGeneral,
  } = useGatePermission({
    action: 'view',
    resource: 'analyticsGeneral',
  });

  const canAccessTalentSearch =
    canManageTalentSearch && readyCheckPermissionTalentSearch;

  const canAccessTalentDB = canManageTalentDB && readyCheckPermissionTalentDB;

  const canAccessAnalytics =
    canViewAnalyticsGeneral && readyCheckPermissionAnalyticsGeneral;

  const generatePayload = (props: { resource: Resource; action: Action }) => {
    const { resource, action } = props;

    return {
      type: 'permission',
      data: {
        resource,
        action,
        page: {
          name: GatePermission.getPageName({ resource }),
          url: GatePermission.getPageUrl({ resource }),
        },
      },
    };
  };

  const generatePermissionPayload = (path: string) => {
    const exploreTalentsPage = path?.includes('/explore-talents');
    const talentDatabasePage = path?.includes('/talent-database');
    const analyticsPage = path?.includes('/analytics');

    if (exploreTalentsPage) {
      return generatePayload({
        resource: 'talentHunt',
        action: 'manage',
      });
    }

    if (talentDatabasePage) {
      return generatePayload({
        resource: 'talentDb',
        action: 'manage',
      });
    }

    if (analyticsPage) {
      return generatePayload({
        resource: 'analyticsGeneral',
        action: 'view',
      });
    }

    return null;
  };

  const generatePermissionResource = (path: string) => {
    if (path?.includes('/explore-talents')) {
      return PERMISSION_RESOURCES.talentHunt;
    }

    if (path?.includes('/talent-database')) {
      return PERMISSION_RESOURCES.talentDb;
    }

    return null;
  };

  return {
    canAccessTalentDB,
    canAccessTalentSearch,
    canAccessAnalytics,
    setRequestPermissionModalVisible,
    requestPermissionModalVisible,
    generatePermissionPayload,
    generatePermissionResource,
    setPermissionPayload,
    setPermissionResource,
    permissionPayload,
    permissionResource,
  };
};

export default useGeneralPermissionModal;
