export const GTM_EVENT_NAMES = {
  EMPLOYER_SIGN_IN: 'employer_sign_in',
} as const;

type GTM_KEYS = typeof GTM_EVENT_NAMES;
type GTM_VALUE = GTM_KEYS[keyof GTM_KEYS];
type Payload = Record<string, unknown>;

/**
 * @description Push data to Google Tag Manager dataLayer
 * @link https://analytics.google.com/analytics/web/#/p316726631/reports/home
 * @link https://tagmanager.google.com/#/container/accounts/6064910290/containers/98039529/workspaces/102
 */
export const dataLayerPush = (data: { event: GTM_VALUE } & Payload) => {
  if (typeof window === 'undefined') return;
  if (!window.dataLayer) return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};
