import { message } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import { useRouter } from 'next/router';

import { NOTIFICATION_ACTION } from '@/common/constants';
import { useStoreApi } from '@/common/contexts/useApi';
import { parseErrorMessage } from '@/common/utils';
import {
  generateComponentBasedOnAction,
  getRedirectRouteBasedOnAction,
  updateLastSeenNotification,
  updateNotificationReadStatus,
} from '@/modules/notifications/services/NotificationService';

const Default = ({ data, defaultImage, size = 'large' }) => {
  const getDateNotification = (date) => {
    const result = moment(date).format('DD MMM');
    return result;
  };
  const router = useRouter();
  const { axios } = useStoreApi();

  const notifData = JSON.parse(data.notification?.data);

  const handleClickNotification = async ({ action, notifData, data }) => {
    try {
      await updateNotificationReadStatus(axios, data?._id);
      await updateLastSeenNotification(axios);
      const redirectURL = getRedirectRouteBasedOnAction(action, notifData);

      if (redirectURL) return router.push(redirectURL);
    } catch (error) {
      const errorMessage = parseErrorMessage(error);
      message.error(errorMessage);
    }
  };

  const imageUrl = defaultImage ? defaultImage : notifData?.companyLogoUrl;

  return (
    <div
      className={clsx(
        'border-b-solid relative flex cursor-pointer border-b border-b-neutral-200',
        {
          'py-3 px-4 md:py-4 md:px-8': size === 'large',
          'py-3 px-4': size === 'small',
          'bg-white': data.isRead,
          'bg-violet-0': data?.isRead === false,
          'cursor-default':
            data?.notification?.action ===
            NOTIFICATION_ACTION.employerCustomStepAnnouncementReceived,
        }
      )}
      onClick={() =>
        handleClickNotification({
          action: data.notification?.action,
          notifData,
          data,
        })
      }
    >
      {imageUrl && (
        <>
          <img
            src={imageUrl}
            alt="notification image"
            className={clsx('rounded-lg object-contain', {
              'mr-4 max-h-[48px] w-auto max-w-[48px]': size === 'large',
              'mr-4 max-h-[28px] w-auto max-w-[28px]': size === 'small',
            })}
          />
        </>
      )}
      <div className="flex w-full flex-col">
        <span
          className={clsx(
            'w-[calc(100%_-_35px)] text-xs font-bold leading-[18px] text-neutral-900',
            {
              'max-w-[90%] md:text-sm md:leading-[21px]': size === 'large',
              'max-w-[85%]': size === 'small',
            }
          )}
        >
          {data.notification?.title}
        </span>
        <p
          className={clsx(
            'mt-1 w-full max-w-[616px] text-xs leading-[18px] text-neutral-900',
            {
              'md:text-sm md:leading-[18.2px]': size === 'large',
            }
          )}
        >
          {data.notification?.body}
        </p>
        {generateComponentBasedOnAction({
          action: data.notification?.action,
          notifData: notifData,
          size,
        })}
      </div>

      <span
        className={clsx(
          'absolute top-[14px] right-[16px] block text-[10px] font-medium leading-[15px] text-neutral-500 ',
          {
            'md:top-[16px] md:right-[32px] md:text-xs md:leading-[15.6px]':
              size === 'large',
          }
        )}
      >
        {getDateNotification(data.createdAt)}
      </span>
    </div>
  );
};

export default Default;
