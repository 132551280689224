import { AxiosRequestConfig } from 'axios';

import { BackendRes } from '@/common/types';
import { Member } from '@/modules/company/types';
import { apiInstance } from '@/modules/core/axios/configs';

import { InvitedMember, PatchUserCompanyMutationPayload } from '../types';

const MemberService = {
  getMembers: ({ axiosConfig }: { axiosConfig?: AxiosRequestConfig }) => {
    const { companyId, ...params } = axiosConfig?.params || {};

    return apiInstance.get<
      BackendRes<{
        result: {
          docs: Member[];
          hasNextPage: boolean;
          hasPrevPage: boolean;
          totalDocs: number;
          totalPages: number;
        };
      }>
    >(`/v1/job-portal/company/${companyId}/member`, {
      ...axiosConfig,
      params,
    });
  },

  getInvitedMembers: ({
    axiosConfig,
  }: {
    axiosConfig?: AxiosRequestConfig;
  }) => {
    return apiInstance.get<
      BackendRes<{
        result: {
          docs: InvitedMember[];
          hasNextPage: boolean;
          hasPrevPage: boolean;
          totalDocs: number;
          totalPages: number;
        };
      }>
    >(`/v1/job-portal/invited-user-company`, axiosConfig);
  },

  patchUserCompany: ({
    axiosConfig,
    userId,
    payload,
  }: {
    axiosConfig?: AxiosRequestConfig;
    userId: string;
    payload: Omit<PatchUserCompanyMutationPayload, 'userId'>;
  }) => {
    return apiInstance.patch(
      `/v1/job-portal/user-company/${userId}`,
      payload,
      axiosConfig
    );
  },
  patchUserCompanyLastSeen: ({
    axiosConfig,
    payload,
    queryParams,
  }: {
    axiosConfig?: AxiosRequestConfig;
    payload?: unknown;
    queryParams?: Partial<{
      id: string;
    }>;
  }) => {
    return apiInstance.patch(
      `/v1/job-portal/user-company/${queryParams?.id}/last-seen-at`,
      payload,
      axiosConfig
    );
  },
};

export default MemberService;
