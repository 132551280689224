import { atomWithStorage } from 'jotai/utils';

import { Option } from '../components/v2/Filters/CheckableItem';

export const FILTER_ATOM_TALENT_SEARCH_KEY = 'ed_talent-search-filter.';
export const DEFAULT_TALENT_SEARCH_FILTER = {
  pastIndustry: [],
  skills: [],
  campus: [],
  yoe: [],
  location: [],
  limit: 10,
};

export type FilterAtom = {
  pastIndustry: Option[];
  skills: Option[];
  campus: Option[];
  yoe: Option[];
  location: Option[];
  limit: number;
};

export const filterAtom = atomWithStorage<FilterAtom>(
  FILTER_ATOM_TALENT_SEARCH_KEY,
  DEFAULT_TALENT_SEARCH_FILTER
);
