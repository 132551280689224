const CloseOutlined = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_408_6885)">
        <path
          d="M15.2458 5.92913C15.5703 5.60466 15.5703 5.07859 15.2458 4.75413C14.9213 4.42966 14.3953 4.42966 14.0708 4.75413L10.7071 8.11785C10.3165 8.50838 9.68338 8.50838 9.29285 8.11785L5.92913 4.75413C5.60466 4.42966 5.07859 4.42966 4.75413 4.75413C4.42966 5.07859 4.42966 5.60466 4.75413 5.92913L8.11785 9.29285C8.50838 9.68338 8.50838 10.3165 8.11785 10.7071L4.75413 14.0708C4.42966 14.3953 4.42966 14.9213 4.75413 15.2458C5.07859 15.5703 5.60466 15.5703 5.92913 15.2458L9.29285 11.8821C9.68338 11.4915 10.3165 11.4915 10.7071 11.8821L14.0708 15.2458C14.3953 15.5703 14.9213 15.5703 15.2458 15.2458C15.5703 14.9213 15.5703 14.3953 15.2458 14.0708L11.8821 10.7071C11.4915 10.3165 11.4915 9.68338 11.8821 9.29285L15.2458 5.92913Z"
          fill="#313131"
        />
      </g>
      <defs>
        <clipPath id="clip0_408_6885">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseOutlined;
