import { useEventListener, useKeyPress } from 'ahooks';
import { Timeout } from 'ahooks/lib/useRequest/src/types';
import _ from 'lodash';
import {
  Context,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

import { message } from '@/common/components/Message';
import { trackEvent } from '@/modules/core/mixpanel/services/MixpanelService';
import { getUserProfile } from '@/modules/core/mixpanel/utils';

export type DelayActionProps = {
  action: Function;
  beforeAction: Function;
  actionMessage?: string;
  onUndoAction: Function;
  messageContent?: ReactNode;
};

type DelayActionItem = {
  id: string;
  undoAction: Function;
  timeoutId: Timeout;
};

export type ActionContextType = {
  delayAction: (props: DelayActionProps) => void;
  undoAction: () => void;
  delayActionList: DelayActionItem[];
};

export const DelayActionContext = createContext<ActionContextType | null>(null);

export const DelayActionProvider = ({ children }) => {
  const [delayActionList, setDelayActionList] = useState<DelayActionItem[]>([]);

  const userProfile = getUserProfile();

  const delayAction = ({
    action,
    beforeAction,
    actionMessage = 'Action delayed',
    onUndoAction,
    messageContent,
  }: DelayActionProps) => {
    const actionId = _.uniqueId('action_');

    const withUndo = !messageContent;

    const delay = withUndo ? 3 : 4; // seconds

    const destroyToast = message.primary({
      content: messageContent ? (
        messageContent
      ) : (
        <div className="flex justify-between">
          <span>{actionMessage}</span>
          <button
            type="button"
            data-undoactionid={actionId}
            className="undo-action-button ml-4 shrink-0 text-[13px] font-semibold text-violet-600"
          >
            Undo(Z)
          </button>
        </div>
      ),
      duration: 0, // destroy toast manually when timeout is reached
      containerClassName: 'max-w-[456px] min-w-[456px]',
    });

    beforeAction && beforeAction();

    // execute action immediately
    if (!withUndo) action();

    const timeoutId = setTimeout(() => {
      // destroy toast
      destroyToast();

      // execute action
      if (withUndo) action();

      // remove undo action from list
      if (withUndo) {
        setDelayActionList(
          delayActionList.filter((action) => action.id !== actionId)
        );
      }
      clearTimeout(timeoutId);
    }, delay * 1000);

    if (withUndo) {
      // add undo action to list
      setDelayActionList([
        ...delayActionList,
        {
          id: actionId,
          undoAction: onUndoAction,
          timeoutId,
        },
      ]);
    }
  };

  const undoAction = (actionId?: string) => {
    // undo all actions
    if (delayActionList.length > 0) {
      // reset toast
      message.destroy();
      message.primary({
        content: 'Action undone',
        duration: 2,
      });

      if (actionId) {
        // undo action with specific id
        const action = delayActionList.find((action) => action.id === actionId);
        if (!action) return;

        trackEvent({
          eventName: 'ed_UndoProcess',
          eventData: userProfile,
        })
          .then(() => console.log('Track Undo'))
          .catch(() => console.log('fail track'));

        action.undoAction();
        clearTimeout(action.timeoutId);

        setDelayActionList(
          delayActionList.filter((action) => action.id !== actionId)
        );
      } else {
        // undo all actions
        delayActionList.forEach((item) => {
          item.undoAction();
          clearTimeout(item.timeoutId);
        });

        setDelayActionList([]);
      }
    }
  };

  useKeyPress('z', () => {
    undoAction();
  });

  // undo action when click on undo button
  useEventListener(
    'click',
    (e) => {
      if (!e?.target) return;

      const actionId = (e?.target as HTMLButtonElement)?.dataset?.undoactionid;

      // check if element is undo button
      if (!actionId) return;

      undoAction(actionId);
    },
    {
      target: document.querySelector('.undo-action-button') as HTMLElement,
    }
  );

  const value = useMemo(() => {
    const temp: ActionContextType = {
      delayAction,
      undoAction,
      delayActionList,
    };
    return temp;
  }, [delayAction, undoAction, delayActionList]);

  return (
    <DelayActionContext.Provider value={value}>
      {children}
    </DelayActionContext.Provider>
  );
};

export const InvitationContent = () => {
  return (
    <div className="text-[13px] leading-[150%] text-neutral-1000">
      <div className="mb-0.5 font-semibold">Invitation Sent!</div>
      <div className="mb-0.5 font-normal">
        Umumnya perusahaan akan mendapatkan 1 dari 4 balasan dari undangan yang
        dikirimkan.{' '}
        <a
          href={`http://wa.me/6281399101814?text=${encodeURI(
            'Hi Dealls Product team, saya ada isu dengan fitur Talent Invitation'
          )}`}
          className="font-semibold text-violet-500 underline"
          target="_blank"
        >
          Mohon kontak kami
        </a>{' '}
        jika Anda memerlukan bantuan.
      </div>
    </div>
  );
};

/**
 * Hook for delay action and undo action
 */
const useDelayAction = () => {
  const context = useContext(DelayActionContext as Context<ActionContextType>);
  return context;
};

export default useDelayAction;
