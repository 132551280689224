import { useFlag } from '@unleash/proxy-client-react';
import { Badge } from 'antd';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { sidebarAtom } from '@/common/atoms/sidebarAtom';
import PlatformSwitcher from '@/common/components/PlatformSwitcher';
import InitialAccessConfirmationModal from '@/common/components/PlatformSwitcher/Modal/InitialAccessConfirmation';
import RequestHRISAccessModal from '@/common/components/PlatformSwitcher/Modal/RequestHRISAccessModal';
import { UNLEASH_TOGGLES } from '@/common/constants';
import { useSidebar } from '@/common/contexts/useSidebar';
import useGenerateSidebarMenus from '@/common/hooks/useGenerateSidebarMenus';
import useWindowSize from '@/common/hooks/useWindowSize';
import ArrowCircleIcon from '@/icons/menu/ArrowCircleIcon';
import ArrowLeftIcon from '@/icons/menu/ArrowLeftIcon';
import NotificationIcon from '@/icons/menu/NotificationIcon';
import { SidebarLogo } from '@/icons/menu/SidebarLogo';
import useAnalyticsBadge from '@/modules/analytics/hooks/useAnalyticsBadge';
import Tooltip from '@/modules/jobs/components/ApplicantList/Tooltip';
import useNotificationStatusQuery from '@/modules/notifications/hooks/queries/useNotificationStatusQuery';
import RequestPermissionModal from '@/modules/permissions/components/Departments/Modal/RequestPermission';
import useGeneralPermissionModal from '@/modules/permissions/hooks/useGeneralPermissionModal';

import containerStyles from './index.module.scss';

type OverlayProps = {
  show: boolean;
  onClick?: (e: React.MouseEvent) => void;
};

type SidebarProps = {
  hide: boolean;
};

type NavLinkProps = PropsWithChildren<{
  className?: string;
  href: string;
  onClick: (e: React.MouseEvent, path: string) => void;
  onActive: (href: string, path: string) => void;
}>;

type WrapWithNavItemBadgeProps = {
  condition: undefined | boolean;
  children: ReactNode;
};

const MOBILE_VIEW_WIDTH = 998;

const MotionArrowCircleIcon = motion(ArrowCircleIcon, {
  forwardMotionProps: true,
});

const MotionSidebarLogoCollapsed = motion(SidebarLogo.Collapsed, {
  forwardMotionProps: true,
});

const Overlay = ({ show, onClick }: OverlayProps) => (
  <AnimatePresence initial={false}>
    {show && (
      <motion.div
        key="overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        exit={{ opacity: 0, transition: { duration: 0.3 } }}
        className="fixed inset-0 z-[1000] h-screen w-full bg-neutral-1000/60 transition-opacity 998:hidden"
        onClick={onClick}
      />
    )}
  </AnimatePresence>
);

const wrapWithNavItemBadge = ({
  condition,
  children,
}: WrapWithNavItemBadgeProps) => {
  return condition ? (
    <Badge
      count={
        <div className="rounded-full bg-violet-500 px-2 py-[2px] text-[8px] font-medium leading-[13px] text-white">
          New
        </div>
      }
      size="small"
      offset={[17, 0]}
    >
      {children}
    </Badge>
  ) : (
    children
  );
};

const Sidebar = ({ hide }: SidebarProps) => {
  const router = useRouter();
  const HRIS_FEATURE_ENABLED = useFlag(UNLEASH_TOGGLES.ed_hrisFeature);

  const MENUS = useGenerateSidebarMenus();
  const [width] = useWindowSize();
  const { data: notificationStatus } = useNotificationStatusQuery();
  const {
    setReleaseDate: setAnalyticsReleaseDate,
    labelEnabled: newAnalyticsLabelEnabled,
    turnOffNewBadge: turnOffAnalyticNewBadge,
  } = useAnalyticsBadge();
  const {
    collapsed,
    mobileCollapsed,
    setCollapsed,
    setMobileCollapsed,
    setLoading,
  } = useSidebar();
  const {
    permissionPayload,
    permissionResource,
    canAccessTalentDB,
    canAccessTalentSearch,
    canAccessAnalytics,
    setRequestPermissionModalVisible,
    requestPermissionModalVisible,
    generatePermissionPayload,
    generatePermissionResource,
    setPermissionPayload,
    setPermissionResource,
  } = useGeneralPermissionModal();

  const [activeNavLink, setActiveNavLink] = useState(router.pathname);
  const [showRequestHRISAccessModal, setShowRequestHRISAccessModal] =
    useState(false);
  const [showSuperAdminConfirmationModal, setShowSuperAdminConfirmationModal] =
    useState(false);

  const handleClickLink = (e, path) => {
    e.preventDefault();

    if (width <= MOBILE_VIEW_WIDTH) {
      setMobileCollapsed(true);
    }

    const talentSearchPage = path.includes('/explore-talents');
    const talentDBPage = path.includes('/talent-database');
    const analyticsPage = path.includes('/analytics');

    const shouldOpenGeneralPermissionModal =
      (talentDBPage && !canAccessTalentDB) ||
      (talentSearchPage && !canAccessTalentSearch) ||
      (analyticsPage && !canAccessAnalytics);

    if (shouldOpenGeneralPermissionModal) {
      const payload = generatePermissionPayload(path);
      setPermissionPayload(payload);
      const resource = generatePermissionResource(path);
      setPermissionResource(resource);
      setRequestPermissionModalVisible(true);
      return;
    }

    router.push(path);
  };

  useEffect(() => {
    if (width <= MOBILE_VIEW_WIDTH) setCollapsed(false);
    if (width > MOBILE_VIEW_WIDTH) setMobileCollapsed(true);
  }, [width]);

  // Note: only used for test purposes, will be deleted after the Jira task is done
  useEffect(() => {
    if (router?.isReady) {
      const analyticsReleaseDate = router?.query?.['analytics-release-date'];

      if (analyticsReleaseDate) {
        setAnalyticsReleaseDate(analyticsReleaseDate as string);
      }
    }
  }, [router]);

  if (hide) return null;

  const MODIFIED_MENUS = MENUS.map((menu) => {
    if (menu.menu) {
      const newSubmenu = menu.menu.map((submenu) => {
        if (submenu.name === 'Analytics') {
          return {
            ...submenu,
            withNewBadge: newAnalyticsLabelEnabled,
            beforeChange: newAnalyticsLabelEnabled
              ? turnOffAnalyticNewBadge
              : null,
          };
        }

        return { ...submenu };
      });

      return { ...menu, menu: newSubmenu };
    }

    return { ...menu };
  });

  return (
    <>
      <RequestPermissionModal
        isVisible={requestPermissionModalVisible}
        setIsVisible={setRequestPermissionModalVisible}
        requestedPermission={permissionPayload}
        resource={permissionResource}
      />

      {HRIS_FEATURE_ENABLED ? (
        <RequestHRISAccessModal
          opened={showRequestHRISAccessModal}
          onCancel={() => {
            setShowRequestHRISAccessModal(false);
            setLoading(false);
          }}
        />
      ) : null}

      {HRIS_FEATURE_ENABLED ? (
        <InitialAccessConfirmationModal
          opened={showSuperAdminConfirmationModal}
          onCancel={() => {
            setShowSuperAdminConfirmationModal(false);
            setLoading(false);
          }}
        />
      ) : null}

      <Overlay
        show={!mobileCollapsed}
        onClick={() => setMobileCollapsed(true)}
      />

      <motion.div
        animate={{
          width: collapsed && width > MOBILE_VIEW_WIDTH ? '56px' : '167px',
          transition: {
            duration: 0.3,
          },
        }}
        id="sidebar"
        className={clsx(
          'sticky inset-y-0 left-0 z-[2] shrink-0 overflow-hidden bg-[#F7F5FA] 998:block 998:w-[167px]',
          {
            'opened !fixed !z-[1000] !block !w-[200px] transition-all duration-500':
              !mobileCollapsed && width <= MOBILE_VIEW_WIDTH,
            '!fixed !z-[1000] w-[200px] translate-x-[-200px] overflow-hidden transition-all duration-500':
              mobileCollapsed && width <= MOBILE_VIEW_WIDTH,
          }
        )}
      >
        <div className="fixed w-[inherit] py-[24px] 998:py-[26px]">
          <LogoLink
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            setMobileCollapsed={setMobileCollapsed}
            width={width}
          />
          {HRIS_FEATURE_ENABLED && (!mobileCollapsed || !collapsed) ? (
            <div className="px-4">
              <PlatformSwitcher
                setRequestCompanyModal={setShowRequestHRISAccessModal}
                setSuperAdminConfirmationModal={
                  setShowSuperAdminConfirmationModal
                }
              />
            </div>
          ) : null}

          <ul
            className={clsx(
              'mt-[43px] flex flex-col gap-y-[39px] 998:px-5',
              width <= MOBILE_VIEW_WIDTH && 'px-[24px]'
            )}
          >
            {MODIFIED_MENUS.map((item) => {
              return (
                <li key={item.id} className="relative">
                  {item.section ? (
                    <>
                      <NavSection show={!collapsed}>{item.section}</NavSection>
                      <NavSeperator show={collapsed} />

                      {item.menu &&
                        item.menu.map((submenu, index) => {
                          const lastSubMenu = item?.menu?.length === index + 1;

                          return wrapWithNavItemBadge({
                            condition: submenu?.withNewBadge,
                            children: (
                              <div
                                className={`flex items-center justify-between ${
                                  lastSubMenu ? 'mb-0' : 'mb-[14px]'
                                }`}
                                key={submenu.id}
                              >
                                <NavLink
                                  href={submenu.path!}
                                  onClick={(e, path) => {
                                    submenu.beforeChange?.();
                                    handleClickLink(e, path);
                                  }}
                                  onActive={(href) => {
                                    setActiveNavLink(href);
                                  }}
                                >
                                  <NavLinkIcon
                                    tooltipTitle={submenu.name!}
                                    showTooltip={collapsed}
                                  >
                                    {submenu.icon!({
                                      isActive: activeNavLink === submenu.path,
                                    })}
                                  </NavLinkIcon>

                                  <NavLinkName
                                    show={!collapsed || !mobileCollapsed}
                                  >
                                    {submenu.name}
                                  </NavLinkName>
                                </NavLink>
                              </div>
                            ),
                          });
                        })}
                    </>
                  ) : (
                    <div className="flex items-center justify-between">
                      {item.path === '/' ? (
                        <NavLink
                          href={item.path!}
                          onActive={(href) => {
                            setActiveNavLink(href);
                          }}
                          onClick={handleClickLink}
                        >
                          <NavLinkIcon
                            tooltipTitle={item.name!}
                            showTooltip={collapsed}
                          >
                            {item.icon!({
                              isActive: activeNavLink === item.path,
                            })}
                          </NavLinkIcon>
                          <NavLinkName show={!collapsed || !mobileCollapsed}>
                            {item.name}
                          </NavLinkName>
                        </NavLink>
                      ) : (
                        <div>
                          <NavLink
                            href={item.path!}
                            onActive={(href) => {
                              setActiveNavLink(href);
                            }}
                            onClick={handleClickLink}
                          >
                            <NavLinkIcon tooltipTitle={item.name!}>
                              {item.icon!({
                                isActive: activeNavLink === item.path,
                              })}
                            </NavLinkIcon>
                            <NavLinkName show={!collapsed || !mobileCollapsed}>
                              {item.name}
                            </NavLinkName>
                          </NavLink>
                        </div>
                      )}

                      {item.path === '/' && (
                        <NotificationLink
                          collapsed={collapsed}
                          notificationStatus={notificationStatus}
                        />
                      )}
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </motion.div>
    </>
  );
};

export const LogoLink = ({
  collapsed,
  setCollapsed,
  width,
  setMobileCollapsed,
}: {
  collapsed: boolean;
  width: number;
  setCollapsed: (value: boolean) => void;
  setMobileCollapsed: (value: boolean) => void;
}) => {
  const { collapseButtonColor } = useAtomValue(sidebarAtom);

  const arrowCircleProps = {
    animate: {
      rotateZ: collapsed ? 180 : 0,
      width: collapsed ? 16 : 24,
      height: collapsed ? 16 : 24,
      transition: { duration: 0.5 },
    },
    className: clsx(
      'absolute right-[-10px] top-1 z-[1000]',
      collapsed && containerStyles.arrow_circle_icon_collapsed
    ),
    onClick: () => setCollapsed(!collapsed),
    variant: collapseButtonColor,
    style:
      collapseButtonColor === 'white'
        ? {
            borderRadius: '50%',
            boxShadow:
              '0px 2px 12px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.08)',
          }
        : {},
  };

  return (
    <motion.div
      animate={{
        paddingLeft: collapsed ? 12 : 20,
        paddingRight: collapsed ? 12 : 20,
        transition: { duration: 0.3 },
      }}
      className={clsx(
        'relative cursor-pointer',
        !collapsed && 'flex items-center justify-between '
      )}
    >
      <Link href="/" passHref legacyBehavior>
        {collapsed && width > MOBILE_VIEW_WIDTH ? (
          <a>
            <MotionSidebarLogoCollapsed />
          </a>
        ) : (
          <a>
            <SidebarLogo />
          </a>
        )}
      </Link>
      {width > MOBILE_VIEW_WIDTH && (
        <MotionArrowCircleIcon {...arrowCircleProps} />
      )}
      {width <= MOBILE_VIEW_WIDTH && (
        <ArrowLeftIcon
          className={'text-neutral-800'}
          onClick={() => setMobileCollapsed(true)}
        />
      )}
    </motion.div>
  );
};

export const NotificationLink = ({
  notificationStatus,
  collapsed,
}: {
  collapsed: boolean;
  notificationStatus?: { hasNotification: boolean };
}) => {
  return (
    <Link href="/notifications" passHref legacyBehavior>
      <motion.a
        variants={{
          notCollapsed: { translate: 0 },
          collapsed: {
            translateX: '-15px',
            translateY: '29px',
            scale: 1.15,
          },
        }}
        animate={collapsed ? 'collapsed' : 'notCollapsed'}
      >
        <NavLinkIcon tooltipTitle="Notifications" showTooltip>
          <span>
            <NotificationIcon
              notificationIsActive={notificationStatus?.hasNotification!}
            />
          </span>
        </NavLinkIcon>
      </motion.a>
    </Link>
  );
};

export const NavLink = ({
  children,
  href,
  className,
  onClick,
  onActive,
  ...props
}: NavLinkProps) => {
  const router = useRouter();
  const isActive = router.pathname === href;

  return (
    <Link
      href={href}
      className={clsx(
        className,
        'flex cursor-pointer items-center gap-2 text-[11px] font-medium leading-[130%]',
        {
          'font-semibold text-violet-500 hover:text-violet-500': isActive,
          'text-neutral-1000 hover:text-neutral-1000': !isActive,
        }
      )}
      onClick={(e) => {
        onClick(e, href);
        onActive(href, router.pathname);
      }}
      {...props}
    >
      {children}
    </Link>
  );
};

const NavLinkName = ({
  show,
  children,
}: PropsWithChildren<{ show: boolean }>) => (
  <AnimatePresence initial={false}>
    {show && (
      <motion.span
        variants={{
          hidden: {
            width: 0,
            opacity: 0,
            transition: {
              duration: 0.1,
            },
          },
          show: {
            opacity: 1,
            width: 'auto',
            transition: {
              delay: 0.1,
              duration: 0.1,
            },
          },
        }}
        initial="hidden"
        animate="show"
        exit="hidden"
        className="whitespace-nowrap"
      >
        {children}
      </motion.span>
    )}
  </AnimatePresence>
);

const NavLinkIcon = ({
  tooltipTitle,
  showTooltip = false,
  children,
}: PropsWithChildren<{
  tooltipTitle: string;
  showTooltip?: boolean;
}>): JSX.Element => {
  if (showTooltip) {
    return (
      <Tooltip title={tooltipTitle} placement="right">
        {children}
      </Tooltip>
    );
  }

  return <>{children}</>;
};

const NavSection = ({
  show,
  children,
}: PropsWithChildren<{ show: boolean }>) => (
  <motion.span
    animate={{
      opacity: show ? 1 : 0,
      transition: { duration: 0.15, delay: 0.05 },
    }}
    className={clsx(
      'mb-[14px] block whitespace-nowrap text-[10px] font-medium leading-[130%] text-neutral-600'
    )}
  >
    {children}
  </motion.span>
);

const NavSeperator = ({ show }: { show: boolean }) => (
  <AnimatePresence>
    {show && (
      <motion.hr
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0, delay: 0.2 },
        }}
        exit={{ opacity: 0, transition: { duration: 0 } }}
        className="absolute top-2 mb-[21px] w-full border-[1px] bg-neutral-300"
      />
    )}
  </AnimatePresence>
);

export default Sidebar;
