import {
  AutoComplete,
  AutoCompleteProps,
  Form,
  FormItemProps,
  FormRule,
  Input,
  InputProps,
} from 'antd';
import clsx from 'clsx';
import React, {
  CSSProperties,
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactNode,
} from 'react';

import * as styles from './index.module.scss';

type CustomInputProps = Partial<{
  handleChange: () => {};
  label: string | ReactNode;
  name: string | (string | number)[];
  isRequired: boolean;
  rules: FormRule[];
  containerStyle: CSSProperties;
  formStyle: CSSProperties;
  inputStyle: CSSProperties;
  formClass: string;
  inputClass: string;
  labelStyle: CSSProperties;
  inputProps: InputProps;
  formProps: FormItemProps;
  dependencies: FormItemProps['dependencies'];
  disabled: boolean;
  type: string;
  extraText: string;
  handleSelectAutoComplete: () => {};
  onSearch: () => {};
  optionList: AutoCompleteProps['options'];
  placeholder: InputProps['placeholder'];
  containerClassName: string;
  floatingLabel: boolean;
  resetCustomAutoCompleteStyles: boolean;
  containerProps: Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'style' | 'className'
  >;
  extraTextClassName: string;
  labelClassName: string;
}>;

const CustomInput: FC<CustomInputProps> = ({
  handleChange = () => {},
  label = undefined,
  name = '',
  isRequired = false,
  rules = [],
  containerStyle = {},
  formStyle = {},
  inputStyle = {},
  formClass = '',
  inputClass = '',
  labelStyle = {},
  inputProps = {},
  formProps = {},
  dependencies = undefined,
  disabled = false,
  type = 'text',
  extraText = undefined,
  handleSelectAutoComplete = () => {},
  onSearch = () => {},
  optionList = [],
  placeholder,
  containerClassName = '',
  floatingLabel = false,
  resetCustomAutoCompleteStyles = false,
  containerProps = {},
  extraTextClassName = '',
  labelClassName = '',
}) => {
  return (
    <div
      className={clsx(containerClassName, {
        [styles['custom_autocomplete']]: !resetCustomAutoCompleteStyles,
        [styles['custom_input_floating_label']]: floatingLabel,
        [styles['custom_input_text']]: type === 'text',
        [styles['custom_textarea']]: type === 'text-area',
      })}
      style={containerStyle}
      {...containerProps}
    >
      {label && (
        <h4
          className={clsx(
            'flex flex-row text-base text-neutral-900',
            !extraText && 'mb-1',
            labelClassName
          )}
          style={{
            ...labelStyle,
          }}
        >
          {label}
          <span className={clsx(`text-danger-500`, !isRequired && 'hidden')}>
            *
          </span>
        </h4>
      )}
      <h3
        className={clsx(
          extraTextClassName,
          'mb-2 text-xs font-light text-neutral-600',
          !extraText && 'hidden'
        )}
      >
        {extraText}
      </h3>
      <Form.Item
        name={name}
        rules={rules}
        style={formStyle}
        className={formClass}
        dependencies={dependencies}
        {...formProps}
      >
        {type === 'password' ? (
          <Input.Password
            className={inputClass}
            placeholder={placeholder}
            style={{
              borderRadius: '8px',
              padding: '8px',
              height: '36px',
              ...inputStyle,
            }}
            type={type}
            onChange={handleChange}
            {...inputProps}
          />
        ) : type === 'text-area' ? (
          <Input.TextArea
            className={inputClass}
            placeholder={placeholder}
            style={{
              borderRadius: '8px',
              padding: '8px',
              ...inputStyle,
            }}
            rows={4}
            // @ts-ignore
            onChange={handleChange}
            {...inputProps}
          />
        ) : type === 'autocomplete' ? (
          // @ts-ignore
          <AutoComplete
            options={optionList}
            style={{
              borderRadius: '8px',
              height: '36px',
              ...inputStyle,
            }}
            placeholder={placeholder}
            onSelect={handleSelectAutoComplete}
            onSearch={onSearch}
            onChange={handleChange}
            {...inputProps}
          />
        ) : (
          <Input
            className={inputClass}
            placeholder={placeholder}
            style={{
              borderRadius: '8px',
              padding: '8px',
              height: '36px',
              lineHeight: '36px',
              ...inputStyle,
            }}
            type={type}
            disabled={disabled}
            onChange={handleChange}
            {...inputProps}
          />
        )}
      </Form.Item>
    </div>
  );
};
export default CustomInput;
