const ErrorPlaceholder = () => {
  const templateMessage = `Hello, \nI'm reaching out because I'm experiencing an issue with page ${window?.location?.href} and was wondering if you could help me troubleshoot.\n\nThe problem I'm encountering is: `;
  return (
    <div className="flex h-full min-h-screen items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="mt-[30px] text-center text-[24px] font-bold text-neutral-800 md:text-[32px]">
          Oopss...
        </div>
        <div className="mt-[10px] text-center text-[18px] md:text-[24px]">
          We&apos;re sorry, but there seems to be a problem with the page
          you&apos;re trying to access. <br /> Please try again later or contact
          our support team for assistance. Thank you for your patience.
        </div>

        <div className="mb-40 mt-4 text-lg ">
          <a
            href={`http://wa.me/6281399101814?text=${encodeURI(
              templateMessage
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold"
            style={{ textDecoration: 'underline' }}
          >
            Contact us
          </a>{' '}
          if you need any help
        </div>
      </div>
    </div>
  );
};

export default ErrorPlaceholder;
