import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';

import {
  API_BASE_URL,
  COOKIE_TOKEN_NAME,
  HRIS_API_BASE_URL,
} from '@/common/configs/auth';
import { paramsSerializer } from '@/common/utils/formatter';

const createAxiosInstance = (baseUrl: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseUrl,
    headers: {},
    paramsSerializer,
  });

  instance.interceptors.request.use((config) => {
    const accessToken = Cookies.get(COOKIE_TOKEN_NAME) || null;

    const token = accessToken ? JSON.parse(accessToken) : null;

    if (!config.headers) {
      config.headers = {};
    }

    if (accessToken) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error.response) {
        return Promise.reject(
          new Error('A network error occurred. Please try refreshing the page.')
        );
      }

      const { data } = error.response;
      return Promise.reject(data);
    }
  );

  return instance;
};

export const apiInstance = createAxiosInstance(API_BASE_URL);
export const apiEmpInstance = createAxiosInstance(HRIS_API_BASE_URL);
