// @ts-nocheck
import { Form, message, Modal, notification } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import { useState } from 'react';

import Button from '@/common/components/Button';
import CustomInput from '@/common/components/CustomInput';
import { useStoreApi } from '@/common/contexts/useApi';
import { useStoreAuth } from '@/common/contexts/useAuth';
import { parseErrorMessage } from '@/common/utils';
import CloseIcon from '@/icons/CloseIcon';
import useGetCompanyById from '@/modules/company/hooks/queries/useGetCompanyById';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '@/modules/manage-team/constants';
import GoldCloseIcon from '@/modules/permissions/icons/GoldCloseIcon';
import WarningIcon from '@/modules/permissions/icons/WarningIcon';
import { requestAccessPermission } from '@/modules/permissions/services/PermissionService';

import AccessRequestedModal from '../AccessRequestedInfo';
import styles from './index.module.scss';

type RequestPermissionModalProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  requestedPermission: unknown;
  resource: unknown;
  onlyForResource?: boolean;
  action?: unknown;
  onCancel?: () => void;
};

const RequestPermissionModal = ({
  isVisible,
  setIsVisible,
  requestedPermission,
  resource,
  onlyForResource = false,
  action,
  onCancel,
}: RequestPermissionModalProps) => {
  const [
    isRequestedPermissionModalVisible,
    setIsRequestedPermissionModalVisible,
  ] = useState(false);
  const [form] = Form.useForm();
  const maxLengthNotes = 300;
  const [isSubmiting, setIsSubmitting] = useState(false);
  const { axios } = useStoreApi();
  const { state: authState } = useStoreAuth();
  const companyId = authState?.companyData?.company?.id;

  const { data } = useGetCompanyById(companyId);
  const company = data?.data?.result;

  const watchNotes = Form.useWatch('notes', form);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const bodyValues = {
        type: requestedPermission?.type,
        ...(values?.notes && {
          note: values?.notes,
        }),
        data: {
          resource: requestedPermission?.data?.resource,
          action: requestedPermission?.data?.action,
          ...(requestedPermission?.data?.allowedCriteria && {
            allowedCriteria: requestedPermission?.data?.allowedCriteria,
          }),
          page: {
            name: requestedPermission?.data?.page?.name,
            url: requestedPermission?.data?.page?.url,
          },
        },
      };

      const response = await requestAccessPermission(axios, bodyValues);

      if (response) {
        setIsRequestedPermissionModalVisible(true);
        setIsVisible(false);
      }
    } catch (error) {
      const hasRequested =
        error?.messageId === 'COMPANY_CHANGE_REQUEST_ALREADY_CREATED' ||
        error?.code === 400;

      // Warning Re-Request
      if (hasRequested) {
        setIsVisible(false);
        return notification.warning({
          top: 48,
          key: 'warning',
          duration: 5,
          maxCount: 1,
          placement: 'top',
          message: (() => {
            return (
              <p className="w-full max-w-[592px] text-sm leading-[130%] text-neutral-800">
                <span className="inline-block text-warning-800">
                  Access Denied!
                </span>{' '}
                You can re-request if there is still no respond until{' '}
                <span className="inline-block font-bold">
                  {moment(error?.data?.requestExpiredAt).format('DD MMMM YYYY')}
                </span>{' '}
                In the meantime, you can contact{' '}
                <span className="inline-block font-bold">
                  {error?.data?.ownerEmail}
                </span>{' '}
                to follow up.
              </p>
            );
          })(),
          closeIcon: <GoldCloseIcon />,
          icon: <WarningIcon />,
          className: clsx('!w-[704px]', styles['warning_request_access_modal']),
        });
      }

      const errorMessage = parseErrorMessage(error);
      message.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClickCancel = () => {
    onCancel && onCancel();
    setIsVisible(false);
  };

  const generateTitleByResource = (resource) => {
    switch (resource) {
      case PERMISSION_RESOURCES.jobPost: {
        if (action === PERMISSION_ACTIONS.create) {
          return 'You don’t have permission to post a job';
        }

        return 'You don’t have access to this page';
      }
      default:
        return 'You don’t have access to this page';
    }
  };

  const generateSubTitleByResource = (resource) => {
    switch (resource) {
      case PERMISSION_RESOURCES.jobPost: {
        return 'Request permission to the Job Admin';
      }
      default:
        return 'Request permission to the Admin';
    }
  };

  const generatePlaceholderNotesByResource = (resource) => {
    switch (resource) {
      case PERMISSION_RESOURCES.jobPost: {
        if (onlyForResource && !action) {
          return 'I need access to this page to download the portfolio of a candidate';
        }

        if (action === PERMISSION_ACTIONS.create) {
          return 'I need access to post a Marketing job opening';
        }

        return 'Please explain why you need to access this page';
      }
      case PERMISSION_RESOURCES.talentHunt: {
        return 'I need access as I am currently hunting for a candidate to fill a position under my department';
      }
      case PERMISSION_RESOURCES.talentDb: {
        return `I need access to this page to see all of my job's applicants`;
      }
      default:
        return 'Please explain why you need to access this page';
    }
  };

  return (
    <>
      <AccessRequestedModal
        isVisible={isRequestedPermissionModalVisible}
        setIsVisible={setIsRequestedPermissionModalVisible}
        onCancel={onCancel}
      />
      <Modal
        className={clsx(styles['request_permission_modal'])}
        open={isVisible}
        maskClosable={false}
        width={400}
        footer={null}
        onCancel={handleClickCancel}
        closeIcon={<CloseIcon.Medium />}
      >
        <div className="p-4">
          <div>
            <span className="block text-base font-bold leading-[130%] text-neutral-1000">
              {generateTitleByResource(resource)}
            </span>
            <span className="mt-1 flex flex-col text-xs font-medium leading-[150%] text-neutral-600">
              <span>{generateSubTitleByResource(resource)}</span>
              {company?.owner?.email && (
                <span className="block">({company?.owner?.email})</span>
              )}
            </span>
            <div className="mt-[16px]">
              <Form form={form} onFinish={handleSubmit}>
                <div className="flex flex-col">
                  <CustomInput
                    type="text-area"
                    name="notes"
                    label="Notes (optional)"
                    labelStyle={{
                      fontWeight: 500,
                      fontSize: '12px',
                      lineHeight: '100%',
                      color: '#313131',
                    }}
                    inputProps={{
                      maxLength: maxLengthNotes,
                    }}
                    placeholder={generatePlaceholderNotesByResource(resource)}
                    formClass="!mt-[8px] !mb-0"
                    inputClass="placeholder:!text-neutral-300 !min-h-[120px] placeholder:!leading-[150%] placeholder:!text-xs !p-4 !text-xs !bg-neutral-50 !rounded-[6px] !border-neutral-150 !border !rounded-[6px]"
                  />
                  <span className="mt-1 block self-end text-[10px] leading-[100%] text-neutral-600">
                    {watchNotes?.length ?? 0}/{maxLengthNotes}
                  </span>
                </div>

                <div className="mt-[16px] flex items-center justify-end space-x-[16px]">
                  <Button
                    htmlType="button"
                    className="!h-max !w-max !bg-transparent !p-0 !text-xs !font-bold !leading-[100%] !text-violet-500 hover:!text-violet-500"
                    onClick={handleClickCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isSubmiting}
                    htmlType="submit"
                    className="!h-[36px] !w-[108px] !rounded-[71px] !text-xs hover:!bg-violet-500 hover:!text-white disabled:!bg-neutral-300 disabled:!text-white hover:disabled:!bg-neutral-300"
                  >
                    Send
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RequestPermissionModal;
