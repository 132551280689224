import { Switch, Tabs } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import {
  NOTIFICATION_CATEGORY,
  notificationCategories,
} from '@/common/constants';
import { getLocalStorageValue, setLocalStorageValue } from '@/common/helpers';
import NotificationList from '@/modules/notifications/components/NotificationList';

import { NotificationLocalStorageKey } from '../../types/enums';
import styles from './index.module.scss';

const Notifications = ({ size = 'large' }) => {
  const [onlyShowUnread, setOnlyShowUnread] = useState(false);
  const [activeTab, setActiveTab] = useState(NOTIFICATION_CATEGORY.forYou);

  const { TabPane } = Tabs;

  const handleChangeSwitch = (checked) => {
    setOnlyShowUnread(checked);
    setLocalStorageValue(
      NotificationLocalStorageKey.NOTIFICATION_ONLY_SHOW_UNREAD,
      checked
    );
  };

  useEffect(() => {
    const onlyShowUnreadValue = getLocalStorageValue(
      NotificationLocalStorageKey.NOTIFICATION_ONLY_SHOW_UNREAD
    );
    if (onlyShowUnreadValue) {
      setOnlyShowUnread(onlyShowUnreadValue);
    }
  }, []);

  return (
    <div
      className={clsx('mx-auto w-full px-0 md:px-3 lg:px-0', {
        'mt-[100px] max-w-[819px] pb-[100px]': size === 'large',
        'max-w-full': size === 'small',
      })}
    >
      <div
        className={clsx('flex w-full justify-between', {
          'px-4 md:px-0': size === 'large',
          'sticky top-0 z-50 bg-white px-4 pb-3 pt-4': size === 'small',
        })}
      >
        <h1
          className={clsx('font-bold leading-[130%] text-neutral-1000', {
            'text-base md:text-lg md:leading-[23.4px]': size === 'large',
            'text-sm': size === 'small',
          })}
        >
          Notifications
        </h1>
        <div className="flex items-center gap-2">
          <span className="text-[10px] leading-[130%] text-neutral-800 md:text-xs md:font-medium md:leading-[15.6px]">
            Only show unread
          </span>
          <Switch
            className={clsx(styles.custom_switch)}
            checked={Boolean(onlyShowUnread)}
            onChange={handleChangeSwitch}
          />
        </div>
      </div>
      <div className={clsx('flex w-full justify-between px-3 md:px-0')}>
        <Tabs
          className={clsx(
            {
              [styles.custom_tabs_small as string]: size === 'small',
              [styles.custom_tabs as string]: size === 'large',
            },
            '!mt-0 w-full !pt-0'
          )}
          activeKey={activeTab}
          onChange={(tab) => setActiveTab(tab)}
        >
          {notificationCategories.map((category) => {
            return (
              <TabPane
                className="!mt-0 w-full !pt-0"
                tab={category.label}
                key={category.value}
              >
                <NotificationList
                  onlyShowUnread={onlyShowUnread}
                  category={category.value}
                  size={size}
                />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default Notifications;
