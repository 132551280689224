import React from 'react';

const ProgessiveIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.375 32.5H5.375V7.5"
        stroke="url(#paint0_linear_28_1760)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.8755 10L20.3755 22.5L15.3755 17.5L5.37549 27.5"
        stroke="url(#paint1_linear_28_1760)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.8755 16.25V10H26.6255"
        stroke="url(#paint2_linear_28_1760)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_28_1760"
          x1="20.375"
          y1="7.5"
          x2="20.375"
          y2="32.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_28_1760"
          x1="19.1255"
          y1="10"
          x2="19.1255"
          y2="27.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_28_1760"
          x1="29.7505"
          y1="10"
          x2="29.7505"
          y2="16.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProgessiveIcon;
