const ShortAnswerIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33337 4.66663H12.6667"
        stroke="#1A1A1A"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.33337 8H10"
        stroke="#1A1A1A"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.33337 11.3334H7.33337"
        stroke="#1A1A1A"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ShortAnswerIcon;
