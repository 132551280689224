export const APPLICANT_STATUS = {
  accepted: 'accepted', // alias shortlisted
  notProcessed: 'notProcessed',
  review: 'review',
  approached: 'approached', // alias considered or on hold
  rejected: 'rejected',
  rejectedBySystem: 'rejectedBySystem',
  expired: 'expired',
  waitListed: 'waitListed',
  hrInterview: 'hrInterview',
  userInterview: 'userInterview',
  techTestSent: 'techTestSent',
  techTestSubmitted: 'techTestSubmitted',
  offered: 'offered',
  curatedInProgress: 'curatedInProgress',
  custom: 'custom',
  differentJob: 'differentJob',
} as const;

export type ApplicationStatus =
  (typeof APPLICANT_STATUS)[keyof typeof APPLICANT_STATUS];

export const APPLICANT_LIST_TAB_STATUS = {
  notProcessed: 'notProcessed',
  rejected: 'rejected',
  accepted: 'accepted',
  techTest: 'techTest',
  custom: 'custom',
  approached: 'approached',
} as const;

export const APPLICANT_LIST_ACTION_TYPE = {
  deselectSorter: 'DESELECT_SORTER',
  setSorter: 'SET_SORT_INFO',
  patchFilters: 'PATCH_FILTERS',
  toggleFilters: 'TOGGLE_FILTERS',
  setInitialState: 'SET_INITIAL_STATE',
  setSearch: 'SET_SEARCH',
  setPagination: 'SET_PAGINATION',
  setQuickFilterRanks: 'SET_QUICK_FILTER_RANKS',
  setActiveTab: 'SET_ACTIVE_TAB',
} as const;

export const RANK = {
  'top1%': 'top1%',
  'top5%': 'top5%',
  'top10%': 'top10%',
  'top25%': 'top25%',
  others: 'others',
} as const;

export const FROM_RECOMMENDATION_LIST = [
  {
    label: 'Applicant',
    value: 'false',
  },
  {
    label: 'Dealls Recommendation',
    value: 'true',
  },
] as const;

export const RANK_LIST = [
  { label: 'Top 1%', value: RANK['top1%'] },
  { label: 'Top 5%', value: RANK['top5%'] },
  { label: 'Top 10%', value: RANK['top10%'] },
  { label: 'Top 25%', value: RANK['top25%'] },
  { label: 'Others', value: RANK['others'] },
] as const;

export const TECH_TEST_STATUS_LIST = [
  {
    value: 'waitConfirm',
    label: 'Awaiting confirmation',
  },
  {
    value: 'waitSubmit',
    label: 'Awaiting submission',
  },
  {
    value: 'notConfirm',
    label: 'Didn’t confirm',
  },
  {
    value: 'notSubmit',
    label: 'Didn’t submit',
  },
  {
    value: 'submitted',
    label: 'Submitted',
  },
];

export const ELIGIBILITY = {
  freshgrad: 0,
  uni1StYear: 1,
  uni2StYear: 2,
  uni3StYear: 3,
  uni4StYear: 4,
  year1_3: 5,
  year4_5: 6,
  year5: 7,
  others: 8,
} as const;

export const ELIGIBILITY_LIST_FOR_FILTER = [
  {
    label: 'FG',
    labelFilter: 'Freshgraduate',
    filterKey: 'freshgrad',
    values: [ELIGIBILITY.freshgrad],
  },
  {
    label: '1-3',
    labelFilter: '1-3',
    filterKey: 'year1_3',
    values: [ELIGIBILITY['year1_3']],
  },
  {
    label: '4-5',
    labelFilter: '4-5',
    filterKey: 'year4_5',
    values: [ELIGIBILITY['year4_5']],
  },
  {
    label: '5+',
    labelFilter: '5+',
    filterKey: 'year5',
    values: [ELIGIBILITY['year5']],
  },
  {
    label: 'NFY',
    labelFilter: 'Non-Final Year',
    filterKey: 'nfy',
    values: [
      ELIGIBILITY.uni1StYear,
      ELIGIBILITY.uni2StYear,
      ELIGIBILITY.uni3StYear,
    ],
  },
  {
    label: 'Others',
    labelFilter: 'Others',
    filterKey: 'others',
    values: [ELIGIBILITY.others],
  },
  {
    label: 'FY',
    labelFilter: 'Final Year',
    filterKey: 'fy',
    values: [ELIGIBILITY.uni4StYear],
  },
] as const;

export const TALENT_RECOMMENDATION_ELIGIBILITY_LABEL = {
  nfy: 'Non-Final Year',
  fy: 'Final Year',
  fg: 'Freshgraduate',
  '': 'N/A',
} as const;

export const TECH_TEST_STATUS = {
  waitConfirm: 'waitConfirm',
  notConfirm: 'notConfirm',
  waitSubmit: 'waitSubmit',
  notSubmit: 'notSubmit',
  submitted: 'submitted',
} as const;

export const DEFAULT_TECH_STEP_SUBJECT =
  'Case Study Invitation for {Job Title} at {Company Name}';

export const DEFAULT_TECH_STEP_MESSAGE = `
<p>Hi {Talent Name},</p>
<p>I'm {Your Name} from {Company Name}.</p>
<p>First of all, congrats for making it through the next step of our recruitment process!</p>
<p>The next step is we'd like to get to know you better through a case study assessment. All of the resources to work on this test have been provided below. Please confirm if you’re participating in the case study by clicking the confirmation button. The attachment & countdown to submit will be visible after your confirmation.</p>
<p>If you have any questions, feel free to reach out to my email __ or WhatsApp at ___.</p>
<p>Wishing you the best of luck!</p>
<pre>Best Regards,
{Your Name}
{Company Name}</pre>
`;

export const DEFAULT_TECH_STEP_REJECTION_SUBJECT =
  'Your application to {Job Title} at {Company Name} is Rejected';

export const DEFAULT_TECH_STEP_REJECTION_MESSAGE =
  "We thank you for your interest in joining as {Job Title} at {Company Name}.<p></p>It was a very competitive process as Deall is a job platform specialized for curated high-achieving talents, so it's unfortunate that your application has not been able to move to the next stage this time.<p></p>The good news are:<br/><ul><li> Your profile is stored in the company's database as reference shall there be any opportunity that suits you in the future</li><li>There are {Total Jobs} of jobs that match your profile with priority slots still available if you wish to apply</li></ul><p></p>We are committed to help job-seekers like you find jobs easier & faster – so feel free to let us know anytime if there's anything we can do to help you land better opportunities. In the meantime, we're dropping the link for you to explore other opportunities down below. Deall wishes you the best for your career 😄";

export const DEFAULT_EMAIL_SHORTLIST_SUBJECT =
  'Your application to {Job Title} at {Company Name} is Shortlisted';

export const DEFAULT_EMAIL_SHORTLIST_MESSAGE =
  '<p>Congratulations! We’re super happy for you 😆</p><p>Your application for {Job Title} at {Company Name} has been shortlisted to {Step Name}.</p><p> For further updates, the recruiter will reach out to you personally &amp; you can track your application progress via Deall Jobs.</p><p>P.S. If by chance you find this email in your Spam/Promotion, please move it to your Primary inbox so you don’t miss any update about your job application.</p>';
export const DEFAULT_EMAIL_REJECT_SUBJECT =
  'Your application to {Job Title} at {Company Name} is Rejected';

export const DEFAULT_EMAIL_REJECT_MESSAGE =
  "<p>We thank you for your interest in joining as {Job Title} at {Company Name}.</p><p>It was a very competitive process as Deall is a job platform specialized for curated high-achieving talents, so it's unfortunate that your application has not been able to move to the next stage this time.</p><p>The good news are:</p><ul><li>Your profile is stored in the company's database as reference shall there be any opportunity that suits you in the future</li><li>There are {Total Jobs} of jobs that match your profile with priority slots still available if you wish to apply</li></ul><p>We are committed to help job-seekers like you find jobs easier &amp; faster – so feel free to let us know anytime if there's anything we can do to help you land better opportunities. In the meantime, we're dropping the link for you to explore other opportunities down below. Deall wishes you the best for your career 😄</p>";

export const DEFAULT_EMAIL_MOVE_SUBJECT =
  '{Company Name} has moved your {Old Job Title} application to another role';

export const DEFAULT_EMAIL_MOVE_MESSAGE =
  '<p>Hi {Talent Name},</p><p>We are very excited to inform you that your application has been moved from {Old Job Title} to {New Job Title} by {Company Name}. We hope this new role aligns better with your skills and experience.</p><p>As of now, you have progressed to {New Step Name} in the application process, you can check out the details here.</p>';
export const DEFAULT_EMAIL_PROCESS_BACK_MESSAGE =
  '<p>Good news, {Talent Name}!</p><p>We would like to let you know that although your application for {Job Title} at {Company Name} was initially not successful, {Company Name} have re-evaluated your qualifications and would like to consider you again for the same role.</p><p>As of now, you have progressed to {Step Name} in the application process, you can check out the details here.</p>';

export const DEFAULT_EMAIL_PROCESS_DIFFERENT_JOB_BACK_MESSAGE =
  '<p>Hi {Talent Name},</p><p>We are very excited to inform you that your application has been moved from {Old Job Title} to {New Job Title} by {Company Name}. We hope this new role aligns better with your skills and experience.</p><p>As of now, you have progressed to {New Step Name} in the application process, you can check out the details here.</p>';
export const DEFAULT_EMAIL_PROCESS_BACK_SUBJECT =
  '{Company Name} has moved your {Job Title} application to another role';

export const HIDE_TALENT_RECOMMENDATION_BANNER_FOR_JOB_IDS =
  'hideTalentRecommendationBannerForJobIds';
export const SUGGESTED_PRESCREENING_QUESTION = {
  TOEFL: '64844628827a42e574746f16',
  BIRTHDATE: '64844687827a42e574746f17',
  IPK: '648446cf827a42e574746f18',
};

export const APPLICANT_LIST_SORT_LABEL = {
  deadline: {
    title: 'Deadline',
    sortBy: {
      asc: 'Oldest to Most Recent',
      desc: 'Most Recent to Oldest',
    },
  },
  studentEligibility: {
    title: 'Years of Experience',
    sortBy: {
      asc: 'Lowest to Highest',
      desc: 'Highest to Lowest',
    },
  },
  applicantlist: {
    title: 'Submission Date',
    sortBy: {
      asc: 'Most Recent to Oldest',
      desc: 'Oldest to Most Recent',
    },
  },
  createdAt: {
    title: 'Application Date',
    sortBy: {
      desc: 'Recent to Oldest',
      asc: 'Oldest to Recent',
    },
  },
  salary: {
    title: 'Salary',
    sortBy: {
      asc: 'Lowest to Highest',
      desc: 'Highest to Lowest',
    },
  },
  ipk: {
    title: 'GPA/IPK',
    sortBy: {
      asc: 'Lowest to Highest',
      desc: 'Highest to Lowest',
    },
  },
  toefl: {
    title: 'TOEFL',
    sortBy: {
      asc: 'Lowest to Highest',
      desc: 'Highest to Lowest',
    },
  },
  age: {
    title: 'Age',
    sortBy: {
      asc: 'Lowest to Highest',
      desc: 'Highest to Lowest',
    },
  },
};

export const LINK_VIEW_EXAMPLE_IMPORT_APPLICANTS =
  'https://docs.google.com/spreadsheets/d/1xhp_8SAxLveF4OWiwpcujR1Dh7FMbHgV6dldEwhG5e4/edit?usp=sharing';
