import { SVGProps } from 'react';

const NumericIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00005 13.7143C7.20958 13.7143 6.46672 13.5642 5.77148 13.2641C5.07624 12.9639 4.47148 12.5568 3.9572 12.0429C3.44291 11.5286 3.03586 10.9239 2.73605 10.2286C2.43624 9.53339 2.28615 8.79053 2.28577 8.00005C2.28577 7.20958 2.43586 6.46672 2.73605 5.77148C3.03624 5.07624 3.44329 4.47148 3.9572 3.9572C4.47148 3.44291 5.07624 3.03586 5.77148 2.73605C6.46672 2.43624 7.20958 2.28615 8.00005 2.28577C8.79053 2.28577 9.53339 2.43586 10.2286 2.73605C10.9239 3.03624 11.5286 3.44329 12.0429 3.9572C12.5572 4.47148 12.9644 5.07624 13.2646 5.77148C13.5648 6.46672 13.7147 7.20958 13.7143 8.00005C13.7143 8.79053 13.5642 9.53339 13.2641 10.2286C12.9639 10.9239 12.5568 11.5286 12.0429 12.0429C11.5286 12.5572 10.9239 12.9644 10.2286 13.2646C9.53339 13.5648 8.79053 13.7147 8.00005 13.7143ZM8.00005 12.5715C9.27624 12.5715 10.3572 12.1286 11.2429 11.2429C12.1286 10.3572 12.5715 9.27624 12.5715 8.00005C12.5715 6.72386 12.1286 5.64291 11.2429 4.7572C10.3572 3.87148 9.27624 3.42862 8.00005 3.42862C6.72386 3.42862 5.64291 3.87148 4.7572 4.7572C3.87148 5.64291 3.42862 6.72386 3.42862 8.00005C3.42862 9.27624 3.87148 10.3572 4.7572 11.2429C5.64291 12.1286 6.72386 12.5715 8.00005 12.5715ZM7.71434 10.8572H8.8572V5.14291H6.57148V6.28577H7.71434V10.8572Z"
        fill="black"
      />
    </svg>
  );
};

export default NumericIcon;
