import React from 'react';

const FastPacedIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.125 5C10.46 5 2.625 12.8302 2.625 22.4952C2.625 26.9463 4.28773 31.0084 7.02469 34.0967C7.30211 34.4097 7.59039 34.7077 7.88922 35C8.1282 34.7273 8.37609 34.4661 8.63445 34.2077C8.67195 34.1702 8.71039 34.1338 8.7482 34.0966C10.2154 32.6571 11.9139 31.5225 13.8001 30.7247C15.8027 29.8777 17.9306 29.4482 20.1249 29.4482C22.3192 29.4482 24.4472 29.8777 26.4498 30.7247C28.336 31.5225 30.0345 32.6571 31.5016 34.0966C31.5395 34.1338 31.5779 34.1702 31.6154 34.2077C31.8737 34.4661 32.1216 34.7273 32.3606 35C32.6595 34.7077 32.9478 34.4096 33.2252 34.0967C35.9621 31.0084 37.6248 26.9463 37.6248 22.4952C37.625 12.8302 29.79 5 20.125 5ZM35.0985 28.842C34.4217 30.442 33.5025 31.896 32.3607 33.1995C29.2052 30.1127 24.8878 28.2037 20.125 28.2037C15.3622 28.2037 11.0447 30.1045 7.8893 33.1912C6.7475 31.8877 5.82828 30.4434 5.15148 28.8434C4.38886 27.0427 3.9607 25.1182 3.88797 23.1641H6.33594V21.9141H3.88797C3.9618 19.961 4.38508 18.0041 5.15148 16.1923C5.88367 14.4613 6.89961 12.8774 8.1743 11.4962L10.2784 13.5973L10.7077 13.1646L11.12 12.7534L11.1184 12.7513L11.1612 12.7081L9.05562 10.6C10.4511 9.29945 12.0247 8.26453 13.7805 7.52187C15.5815 6.75923 17.5064 6.33105 19.4609 6.25828V9.17969H20.7109V6.25812C22.7421 6.33195 24.6184 6.75523 26.4302 7.52164C28.1831 8.26305 29.7834 9.29555 31.1773 10.5929L29.0698 12.7077L29.1149 12.7504L29.1146 12.7519L29.5275 13.1617L29.9572 13.5916L32.0686 11.4778C33.3464 12.8609 34.3648 14.4581 35.0983 16.1923C35.8647 18.0041 36.288 19.9609 36.3619 21.9141H33.8359V23.1641H36.362C36.2893 25.1177 35.8611 27.0418 35.0985 28.842V28.842Z"
        fill="url(#paint0_linear_28_1757)"
      />
      <path
        d="M28.9122 13.6364L22.2338 19.3963C21.6126 18.9714 20.8774 18.7444 20.1249 18.7452C18.0571 18.7452 16.3749 20.4274 16.3749 22.4952C16.3749 23.3139 16.6393 24.0716 17.0863 24.6889L16.1528 25.6224L17.0366 26.5062L17.9767 25.5661C18.5856 25.9934 19.3261 26.2451 20.1248 26.2451C22.1925 26.2451 23.8748 24.5628 23.8748 22.4951C23.8748 21.6836 23.6149 20.9322 23.1751 20.3179L29.0227 13.7468L28.9122 13.6364V13.6364ZM20.1248 24.9952C18.7441 24.9952 17.6248 23.876 17.6248 22.4952C17.6248 21.1145 18.7441 19.9952 20.1248 19.9952C21.5055 19.9952 22.6248 21.1145 22.6248 22.4952C22.6248 23.8759 21.5055 24.9952 20.1248 24.9952Z"
        fill="url(#paint1_linear_28_1757)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_28_1757"
          x1="20.1249"
          y1="5"
          x2="20.1249"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_28_1757"
          x1="22.5878"
          y1="13.6364"
          x2="22.5878"
          y2="26.5062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FastPacedIcon;
