/* eslint-disable no-useless-catch */

import clsx from 'clsx';
import Link from 'next/link';

import { NOTIFICATION_ACTION } from '@/common/constants';
import { apiInstance } from '@/modules/core/axios/configs';
import { APPLICANT_STATUS } from '@/modules/jobs/constants';
import CustomStepAnnouncementModal from '@/modules/notifications/components/Modals/CustomStepAnnouncementModal';

export const getAllNotifications = (instance, params) => {
  const key = '/v1/notification/all';

  const fetcher = async () => {
    try {
      const { data } = await instance.get(key, {
        params: {
          appTarget: 'deallCompany',
          ...params,
        },
      });
      return data?.data;
    } catch (error) {
      throw error;
    }
  };

  return { key, fetcher };
};

export const _getAllNotifications = (instance, params) => {
  return instance.get('/v1/notification/all', {
    params: {
      appTarget: 'deallCompany',
      ...params,
    },
  });
};

export const getNotificationStatus = async ({ axiosConfig } = {}) => {
  const key = '/v1/notification/status';
  try {
    const { data } = await apiInstance.get(key, axiosConfig);
    return data?.data;
  } catch (error) {
    throw error;
  }
};

export const updateNotificationReadStatus = (instance, id) => {
  return instance.patch(`/v1/notification/isread`, { id });
};

export const updateLastSeenNotification = (instance) => {
  return instance.put(`/v1/notification/last-seen`);
};

export const getRedirectRouteBasedOnAction = (action, notifData) => {
  switch (action) {
    case NOTIFICATION_ACTION.applicantsReceived:
    case NOTIFICATION_ACTION.processApplicantsReminderReceived:
      return `/job-post/${notifData.jobId}/candidates`;
    case NOTIFICATION_ACTION.awardedAsBestCompany:
      return `/company/profile`;
    case NOTIFICATION_ACTION.completeCompanyFeaturedMembersReminderReceived:
      return `/company/profile`;
    case NOTIFICATION_ACTION.jobTechTestDeadlineExtensionReceived:
      return {
        pathname: `/job-post/${notifData?.jobId}/candidates/${notifData?.jobApplicationId}`,
        query: {
          status: [
            APPLICANT_STATUS['techTestSent'],
            APPLICANT_STATUS['techTestSubmitted'],
          ],
          sortParam: 'createdAt',
          sortDir: 'desc',
          recruitmentStepId: notifData?.recruitmentStepId,
          page: 1,
        },
      };
    case NOTIFICATION_ACTION.jobTechTestSubmittedReceived:
      return {
        pathname: `/job-post/${notifData?.jobId}/candidates/${notifData?.jobApplicationId}`,
        query: {
          status: [
            APPLICANT_STATUS['techTestSent'],
            APPLICANT_STATUS['techTestSubmitted'],
          ],
          sortParam: 'createdAt',
          sortDir: 'desc',
          recruitmentStepId: notifData?.recruitmentStepId,
          page: 1,
        },
      };
    case NOTIFICATION_ACTION.jobTechnicalTestAssessmentReminderReceived:
      return {
        pathname: `/job-post/${notifData?.jobId}/candidates`,
        query: {
          status: [
            APPLICANT_STATUS['techTestSent'],
            APPLICANT_STATUS['techTestSubmitted'],
          ],
          sortParam: 'createdAt',
          sortDir: 'desc',
          recruitmentStepId: notifData?.recruitmentStepId,
          page: 1,
        },
      };
    case NOTIFICATION_ACTION.employerCustomStepAnnouncementReceived:
      return null;

    case NOTIFICATION_ACTION.invitedEmployerJoinReceived:
      return {
        pathname: '/manage-team',
        query: {
          userCompanyId: notifData?.userCompanyId,
        },
      };
    case NOTIFICATION_ACTION.userCompanyPermissionChangedReceived:
      return notifData?.pageUrl ?? '/notifications';
    default:
      return `/notifications`;
  }
};

export const getDefaultNotificationImage = (action) => {
  switch (action) {
    case NOTIFICATION_ACTION.applicantsReceived:
    case NOTIFICATION_ACTION.processApplicantsReminderReceived:
    case NOTIFICATION_ACTION.studentRecommendationSetReady:
    case NOTIFICATION_ACTION.completeCompanyFeaturedMembersReminderReceived:
    case NOTIFICATION_ACTION.employerCustomStepAnnouncementReceived:
    case NOTIFICATION_ACTION.invitedEmployerJoinReceived:
    case NOTIFICATION_ACTION.userCompanyPermissionChangedReceived:
      return '/notifications/3User.png';
    case NOTIFICATION_ACTION.companySubscriptionPurchased:
    case NOTIFICATION_ACTION.companyTrialSubsUpgraded:
      return '/notifications/TimeCircle.png';
    case NOTIFICATION_ACTION.jobTechTestDeadlineExtensionReceived:
      return '/notifications/TimeCircle.png';
    case NOTIFICATION_ACTION.jobPostTrialLimitReached:
    case NOTIFICATION_ACTION.companyMemberTrialLimitReached:
      return '/notifications/Danger.png';
    case NOTIFICATION_ACTION.awardedAsBestCompany:
      return '/notifications/Activity.png';
    case NOTIFICATION_ACTION.companyTrialSubsActivated:
      return '/notifications/Wallet.png';
    case NOTIFICATION_ACTION.jobTechTestSubmittedReceived:
      return '/notifications/Document.png';
    case NOTIFICATION_ACTION.jobTechnicalTestAssessmentReminderReceived:
      return '/notifications/Document.png';
    default:
      return null;
  }
};

export const generateComponentBasedOnAction = ({ action, notifData, size }) => {
  switch (action) {
    case NOTIFICATION_ACTION.completeCompanyFeaturedMembersReminderReceived:
      if (notifData?.type === 'fillInMembers') return <FillInNowLink />;
      else return <CompleteNowLink size={size} />;
    case NOTIFICATION_ACTION.employerCustomStepAnnouncementReceived:
      return (
        <>
          <div className="mt-2">
            <CustomStepAnnouncementModal size={size} />
          </div>
        </>
      );
    case NOTIFICATION_ACTION.invitedEmployerJoinReceived:
      return <ManageLink notifData={notifData} size={size} />;
    case NOTIFICATION_ACTION.userCompanyPermissionChangedReceived:
      return <RedirectToPageLink notifData={notifData} size={size} />;
    default:
      return null;
  }
};

export const CompleteNowLink = ({ size }) => {
  return (
    <Link
      href="/company/profile"
      className={clsx('mt-3 text-[10px] font-bold text-violet-600', {
        'md:text-sm': size === 'large',
      })}>
      
        Complete Now
      
    </Link>
  );
};

export const FillInNowLink = ({ size }) => {
  return (
    <Link
      href="/company/profile"
      className={clsx('mt-3 text-[10px] font-bold text-violet-600', {
        'md:text-sm': size === 'large',
      })}>
      
        Fill in Now
      
    </Link>
  );
};

export const ManageLink = ({ notifData, size }) => {
  return (
    <Link
      href={`/manage-team?userCompanyId=${notifData?.userCompanyId}`}
      className={clsx('mt-3 text-[10px] font-bold text-violet-600', {
        'md:text-sm': size === 'large',
      })}>
      
        See &amp; Manage
      
    </Link>
  );
};

export const RedirectToPageLink = ({ size, notifData }) => {
  return (
    (<Link
      href={notifData.pageUrl ?? '/notifications'}
      className={clsx('mt-3 text-[10px] font-bold text-violet-600', {
        'md:text-sm': size === 'large',
      })}
      onClick={(e) => {
        e.stopPropagation();
      }}>
      Go to{notifData?.pageName ?? 'the page'}

    </Link>)
  );
};
