import * as Sentry from '@sentry/react';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import { ApiProvider } from '@/common/contexts/useApi';
import { AuthProvider } from '@/common/contexts/useAuth';
import { PreRegistProvider } from '@/common/contexts/usePreRegist';
import { DelayActionProvider } from '@/modules/jobs/contexts/delayAction';
import { JobPostProvider } from '@/modules/jobs/contexts/useJobPostForm';
import { TalentDatabaseFilterProvider } from '@/modules/talentDatabase/contexts/useTalentDatabaseFilter';

import CustomUnleashContextProvider from '../../../modules/core/unleash/providers/CustomUnleashContextProvider';
import ErrorPlaceholder from '../Error/Placeholder';

const PermissionsProvider = dynamic(
  () =>
    import('@/modules/permissions/contexts/useCheckPermissions').then(
      (mod) => mod.PermissionsProvider
    ),
  {
    ssr: false,
  }
);

const CookiesProvider = dynamic(() => import('./CookiesProvider'), {
  ssr: false,
});

const ProvidersWithErrorBoundary = ({ pageProps, children }) => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPlaceholder />}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ReactQueryDevtools initialIsOpen={false} />
          <CookiesProvider>
            <CustomUnleashContextProvider>
              <PreRegistProvider>
                <AuthProvider>
                  <ApiProvider>
                    <PermissionsProvider>
                      <TalentDatabaseFilterProvider>
                        <JobPostProvider>
                          <DelayActionProvider>{children}</DelayActionProvider>
                        </JobPostProvider>
                      </TalentDatabaseFilterProvider>
                    </PermissionsProvider>
                  </ApiProvider>
                </AuthProvider>
              </PreRegistProvider>
            </CustomUnleashContextProvider>
          </CookiesProvider>
        </Hydrate>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default ProvidersWithErrorBoundary;
