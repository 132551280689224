export const eventNames = {
  viewHomePage: 'ed_viewHomePage',
  viewExplore: 'ed_viewExplore',
  detailExplore: 'ed_detailExplore',
  whatsappTalent: 'ed_whatsappTalent',
  signIn: 'ed_signIn',
  signInWithGoogle: 'ed_signInWithGoogle',
  signUpWithEmail: 'ed_signUpWithEmail',
  signUpWithGoogle: 'ed_signUpWithGoogle',
  verifyEmail: 'ed_verifyEmail',
  completeOnboardingGeneralInfo: 'ed_completeOnboardingGeneralInfo',
  joinExistingCompany: 'ed_joinExistingCompany',
  completeOnboardingCompanyGeneralInfo:
    'ed_completeOnboardingCompanyGeneralInfo',
  createNewCompany: 'ed_createNewCompany',
  viewJobList: 'ed_viewJobList',
  viewApplicantDetail: 'ed_viewApplicantDetail',
  viewApplicantList: 'ed_viewApplicantList',
  viewSignUpFromEmpbanner: 'ed_viewSignUpFromEmpbanner',
  viewSignUpPage: 'ed_viewSignUpPage',
  clickJobCardToJobDetail: 'ed_clickJobCardToJobDetail',
  clickJobCardToApplicantList: 'ed_clickJobCardToApplicantList',
  clickShortlistApplicant: 'ed_clickShortlistApplicant',
  clickRejectApplicant: 'ed_clickRejectApplicant',
  clickConsiderApplicant: 'ed_clickConsiderApplicant',
  clickToDoApplicants: 'ed_clickToDoApplicants',
  viewSignInFromLandingPage: 'ed_viewSignInFromLandingPage',
} as const;
