const CheckCircleIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="14" height="14" rx="7" fill="#27C887" />
      <path
        d="M6.20712 8.3786C5.81655 8.76881 5.18364 8.76866 4.79324 8.37827L3.76752 7.35255C3.57264 7.15766 3.25684 7.15711 3.06127 7.3513C2.86472 7.54646 2.86416 7.86419 3.06002 8.06005L4.79291 9.79294C5.18343 10.1835 5.8166 10.1835 6.20712 9.79294L11.1474 4.85263C11.3422 4.6579 11.3422 4.34219 11.1474 4.14746C10.9528 3.9528 10.6372 3.95272 10.4424 4.14729L6.20712 8.3786Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckCircleIcon;
