import React from 'react';

const PassionateIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6544 23.1251C28.3886 24.8277 27.5888 26.4018 26.3703 27.6203C25.1518 28.8388 23.5777 29.6387 21.8751 29.9045M11.8806 10.2121C9.4684 13.8486 7.5 18.0024 7.5 21.8751C7.5 25.356 8.88281 28.6944 11.3442 31.1558C13.8056 33.6172 17.144 35.0001 20.625 35.0001C24.106 35.0001 27.4444 33.6172 29.9058 31.1558C32.3672 28.6944 33.75 25.356 33.75 21.8751C33.75 13.7501 28.125 7.50006 23.5662 2.94873L23.566 2.94886L18.125 14.3751L11.8808 10.2122L11.8806 10.2121Z"
        stroke="url(#paint0_linear_28_1739)"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_28_1739"
          x1="20.625"
          y1="2.94873"
          x2="20.625"
          y2="35.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PassionateIcon;
