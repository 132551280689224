import clsx from 'clsx';
import Link from 'next/link';

import Notifications from '../Notifications';
import * as styles from './index.module.scss';

const NotificationPreview = () => {
  return (
    <div
      className={clsx(
        'relative max-h-[719px] max-w-[360px] overflow-y-auto rounded-lg bg-white shadow-md',
        styles.custom_scrollbar
      )}
    >
      <Notifications size="small" />
      <div className="sticky bottom-0 w-full cursor-pointer bg-white py-2 text-center text-xs font-semibold shadow-[0px_-1px_1px_rgba(0,0,0,0.08),0px_-4px_8px_rgba(0,0,0,0.08)]">
        <Link href="/notifications" passHref>
          See All Notifications
        </Link>
      </div>
    </div>
  );
};

export default NotificationPreview;
