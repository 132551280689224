import React from 'react';

const ArrowLeftIcon = (props: {
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.83337 8.18288L12.8334 8.18288"
        stroke="#1A1A1A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.86658 12.1991L2.83324 8.18314L6.86658 4.16647"
        stroke="#1A1A1A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
