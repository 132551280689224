import { Layout, Spin } from 'antd';
import { useEffect } from 'react';

import { SidebarProvider, useSidebar } from '@/common/contexts/useSidebar';

import Sidebar from '../Sidebar';
const { Content } = Layout;
import { LoadingOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';

import { HeaderProvider, useHeader } from '@/common/contexts/useHeader';

import Header from '../Header';
import { MobileNavbar } from '../Navbar';

const MainLayoutContent = ({ children }) => {
  const router = useRouter();
  const { headerData, setHeaderData } = useHeader();
  const { loading } = useSidebar();

  const isJobPostPath = ['/job-post/create', '/job-post/edit'].some((route) =>
    router.pathname.startsWith(route)
  );

  const isJobList = ['/job-post'].some((route) =>
    router.pathname.startsWith(route)
  );

  const isExploreTalent = ['/explore-talents'].some((route) =>
    router.pathname.startsWith(route)
  );

  const isTalentDatabase = ['/talent-database'].some((route) =>
    router.pathname.startsWith(route)
  );

  const isApplicantListPath = ['/job-post/[id]/candidates'].some((route) =>
    router.pathname.startsWith(route)
  );

  const isNotificationsPath = ['/notifications'].some((route) =>
    router.pathname.startsWith(route)
  );

  const isManageTeamPath = ['/manage-team'].some((route) =>
    router.pathname.startsWith(route)
  );

  const isImportApplicantsPath = ['/job-post/[id]/import'].some((route) =>
    router.pathname.startsWith(route)
  );

  const isImportApplicantsDetailPath = [
    '/job-post/[id]/import/[importBatchId]',
  ].some((route) => router.pathname.startsWith(route));

  useEffect(() => {
    if (isJobPostPath)
      setHeaderData({
        ...headerData,
        backgroundColor: 'transparent',
      });
  }, [isJobPostPath]);

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined className="text-violet-500" spin />}
      size="large"
      className="text-h5 text-violet-500"
      tip="Loading..."
    >
      <div className="h-screen">
        {isJobPostPath && <Header checkPath={isJobPostPath} />}

        <Layout
          className={clsx('min-h-screen', { '-mt-[65px]': isJobPostPath })}
          hasSider
          style={{
            backgroundColor: isJobPostPath ? '#6913D8' : '#FAFAFA',
          }}
        >
          <Sidebar hide={isJobPostPath} />

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.5,
            }}
            className={clsx(
              'flex w-full flex-col items-center md:overflow-auto'
            )}
          >
            {!isNotificationsPath && <MobileNavbar />}

            {isJobPostPath && (
              <img
                src="/rectangles-accent.svg"
                alt=""
                className="absolute left-0 top-0"
              />
            )}

            <Content
              className={clsx(
                'relative w-full min-w-full max-w-[1177px] md:py-6 2xl:min-w-[1177px] 2xl:px-0',
                {
                  '!max-w-full': [isJobList, isExploreTalent, isTalentDatabase],
                  'md:!px-8 md:!py-24': isJobPostPath,
                  'px-4 md:!px-6':
                    isApplicantListPath ||
                    isImportApplicantsPath ||
                    isImportApplicantsDetailPath,
                  'md:!px-8 md:!py-6 2xl:!px-12': isManageTeamPath,
                }
              )}
              style={{
                minHeight: '100vh',
                zIndex: 98,
              }}
            >
              {children}
            </Content>
          </motion.div>
        </Layout>
      </div>
    </Spin>
  );
};

const MainLayout = ({ children }) => {
  return (
    <HeaderProvider>
      <SidebarProvider>
        <MainLayoutContent>{children}</MainLayoutContent>
      </SidebarProvider>
    </HeaderProvider>
  );
};

export default MainLayout;
