import clsx from 'clsx';

export const EmptyNotification = ({ size = 'large' }) => {
  return (
    <div
      className={clsx('flex flex-col items-center gap-[46px] px-[40px]', {
        'my-5': size === 'small',
      })}
    >
      <img
        src="/notifications/Empty.png"
        alt="notification not found"
        className="h-[165.83px] w-[195.57px] md:h-[241.21px] md:w-[284.47px]"
      />
      <div className="text-center">
        <h1
          className={clsx(
            'text-base font-bold leading-[130%] text-neutral-900  md:leading-[130%]',
            {
              'md:text-[28px]': size === 'large',
            }
          )}
        >
          Hiring Made 100X Easier
        </h1>
        <p
          className={clsx('mt-[8px] text-xs leading-[150%] text-neutral-700', {
            'md:text-lg': size === 'large',
          })}
        >
          Never miss an update about your job posts & candidate updates here
        </p>
      </div>
    </div>
  );
};
