import { Layout } from 'antd';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import { useHeader } from '@/common/contexts/useHeader';

import { Navbar } from '../Navbar';

type HeaderProps = {
  checkPath: boolean;
};

const Header = ({ checkPath }: HeaderProps) => {
  const { headerData } = useHeader();

  return (
    <Layout.Header
      className={clsx(`w-full`, {
        '!h-max': !checkPath,
      })}
      style={{
        padding: 0,
        lineHeight: 0,
        backgroundColor: headerData.backgroundColor,
        zIndex: 99,
        position: 'relative',
        top: 0,
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
        }}
      >
        <Navbar checkPath={checkPath} />
      </motion.div>
    </Layout.Header>
  );
};

export default Header;
