import { Button as AntdButton, ButtonProps } from 'antd';
import clsx from 'clsx';

const primary =
  'bg-violet-500 text-white hover:bg-violet-0 hover:text-violet-500 focus:bg-violet-500 focus:text-white font-semibold text-[16px]';
const secondary =
  'bg-violet-0 text-violet-500 hover:bg-violet-500 hover:text-violet-0 focus:bg-violet-500 focus:text-white';
const link = 'bg-transparent !text-violet-500 hover:text-[#46059c] shadow-none';
const text = 'bg-transparent text-gray-800 hover:text-gray-800';
const success =
  'bg-[#27C8871A] text-aquamarine-700 hover:bg-teal-600 hover:text-teal-100 focus:bg-teal-600 focus:text-teal-100';
const warning =
  'bg-warning-0 text-supernova-500 hover:bg-yellow-600 hover:text-amber-100';
const danger =
  'bg-danger-0 text-danger-400 hover:bg-red-600 hover:text-red-100 focus:bg-red-600 focus:text-red-100';
const bluminePrimary =
  'bg-blumine-500 text-white hover:bg-[#4164FB1A] hover:text-blumine-500';
const blumineSecondary =
  'bg-[#4164FB1A] text-blumine-500 focus:bg-[#4164FB1A] focus:text-blumine-500 hover:bg-blumine-500 hover:text-white';
const gray =
  'bg-[#F1F2F4] text-neutral-1000 focus:bg-[#dedede] focus:text-neutral-1000 hover:bg-[#dedede] hover:text-neutral-1000';
const custom = '';

const outlineGreen =
  '!border-1 !border-aquamarine-600 !border-solid !bg-aquamarine-0 !text-aquamarine-600 hover:shadow !opacity-100 disabled:!border-[rgba(0,0,0,0.25)] disabled:!bg-[#f5f5f5] disabled:!text-[rgba(0,0,0,0.25)]';
const outlineRed =
  '!border-1 !border-danger-500 !border-solid !bg-danger-0 !text-danger-500 hover:shadow !opacity-100 disabled:!border-[rgba(0,0,0,0.25)] disabled:!bg-[#f5f5f5] disabled:!text-[rgba(0,0,0,0.25)]';
const outlineViolet =
  '!border-1 !border-violet-500 !border-solid !bg-white !text-violet-500 !hover:bg-violet-0';
const outlineBlumine =
  '!border-1 !border-[#2948C5] !border-solid !bg-[#E6EEFF] !text-[#2948C5] hover:shadow !opacity-100 disabled:!border-[rgba(0,0,0,0.25)] disabled:!bg-[#f5f5f5] disabled:!text-[rgba(0,0,0,0.25)]';

const Button = ({
  type = 'primary',
  className,
  bordered = 'border-none',
  rounded = 'full',
  ...rest
}: Omit<ButtonProps, 'type'> & {
  bordered?: string;
  type?:
    | 'primary'
    | 'secondary'
    | 'link'
    | 'text'
    | 'success'
    | 'warning'
    | 'danger'
    | 'bluminePrimary'
    | 'blumineSecondary'
    | 'gray'
    | 'custom'
    | 'outlineGreen'
    | 'outlineRed'
    | 'outlineViolet'
    | 'outlineBlumine';
  rounded?: 'full' | '8';
}) => {
  return (
    <AntdButton
      className={clsx(
        `${bordered} flex h-[44px] items-center justify-center opacity-90 hover:opacity-100`,
        {
          [primary]: type === 'primary',
          [secondary]: type === 'secondary',
          [link]: type === 'link',
          [text]: type === 'text',
          [success]: type === 'success',
          [warning]: type === 'warning',
          [danger]: type === 'danger',
          [bluminePrimary]: type === 'bluminePrimary',
          [blumineSecondary]: type === 'blumineSecondary',
          [gray]: type === 'gray',
          [custom]: type === 'custom',
          [outlineGreen]: type === 'outlineGreen',
          [outlineRed]: type === 'outlineRed',
          [outlineViolet]: type === 'outlineViolet',
          [outlineBlumine]: type === 'outlineBlumine',
          [className as string]: className,
        },
        {
          ['rounded-full']: rounded === 'full',
          ['rounded-[8px]']: rounded === '8',
        },
        className
      )}
      {...rest}
    />
  );
};

export default Button;
