import _ from 'lodash';

import { OnBoardingFormValues } from '@/modules/auth/types';
import { Member } from '@/modules/company/types';

import { DEALL_EMPLOYER_DASHBOARD_URL } from '../../../../common/constants';
import {
  ACTIONS_LABEL,
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../../manage-team/constants';
import {
  AccessPermission,
  Action,
  MapPermissionById,
  Permission,
  PermissionActionByResource,
  PermissionByResource,
  Resource,
  ResourceAction,
} from '../../types';

function PermissionGenerator() {
  // fn to check access permission

  // @deprecated
  // TODO: remove this function when remove hook useCheckPermission
  const checkAccessPermission = (props) => {
    const { accessPermission, resource, matchAllowedCriteria } = props;

    if (accessPermission && accessPermission?.allowedCriteria) {
      if (!matchAllowedCriteria) {
        return true;
      }

      return !!matchAllowedCriteria(resource, accessPermission.allowedCriteria);
    }

    return true;
  };

  // set permission to Map object
  const accessPermissionsMap = (accessPermissions: AccessPermission[]) => {
    return accessPermissions?.reduce((prev, cur) => {
      const { permission } = cur;

      if (permission) {
        const key =
          `${permission.resource}.${permission.action}` as ResourceAction;
        prev.set(key, cur);
      }

      return prev;
    }, new Map() as Map<ResourceAction, AccessPermission>);
  };

  // fn to parse resource to page name
  const parseResourceToPageName = (props: {
    resource: Resource;
    pageName?: string;
  }) => {
    const { resource, pageName } = props;

    const pageNameMap = {
      [PERMISSION_RESOURCES.jobPost]: 'Job Post',
      [PERMISSION_RESOURCES.talentDb]: 'Talent Database',
      [PERMISSION_RESOURCES.talentHunt]: 'Talent Hunt',
      [PERMISSION_RESOURCES.userCompany]: 'Manage Team',
      [PERMISSION_RESOURCES.analyticsGeneral]: 'Analytics',
    };

    return pageNameMap[resource] || pageName || 'Deall';
  };

  const getPermissionById = (props) => {
    const { permissionsDetail, permissionId } = props;

    return permissionsDetail?.find(
      (permission) => permission.id === permissionId
    );
  };

  const parsePermissionIds = (props) => {
    const { permissionIds, permissionsDetail } = props;

    return permissionIds?.map((permissionId) => {
      const permission = getPermissionById({
        permissionsDetail,
        permissionId,
      });

      if (permission) {
        return permission;
      }

      return {};
    });
  };

  return {
    // fn to check if user is allowed to access the page

    // @deprecated
    // TODO: remove this function when remove hook useCheckPermission
    isAllowed: (props) => {
      const { user, action, resource, matchAllowedCriteria } = props;

      if (!user) return false;

      const owner = user.role === 'owner';
      if (owner) return true;

      const accessPermissions = user.accessPermissions;
      const resultAccessPermissionMap = accessPermissionsMap(accessPermissions);

      if (resultAccessPermissionMap.size === 0) {
        return false;
      }

      const manageAction = PERMISSION_ACTIONS.manage;
      const manageAccessPermission = resultAccessPermissionMap.get(
        // @ts-ignore -- no need to fix because will be removed
        `${resource}.${manageAction}`
      );
      const accessPermission = resultAccessPermissionMap.get(
        // @ts-ignore -- no need to fix because will be removed
        `${resource}.${action}`
      );

      let permissionGranted = false;

      if (accessPermission) {
        permissionGranted = checkAccessPermission({
          accessPermission,
          resource,
          matchAllowedCriteria,
        });
      }

      if (!permissionGranted && manageAccessPermission) {
        const createJob =
          action === PERMISSION_ACTIONS.create &&
          resource === PERMISSION_RESOURCES.jobPost;

        if (createJob) {
          return permissionGranted;
        }

        permissionGranted = checkAccessPermission({
          accessPermission: manageAccessPermission,
          resource,
          matchAllowedCriteria,
        });
      }

      return permissionGranted;
    },

    // TODO: remove this function when remove hook useCheckPermission
    // fn to get allowed criteria
    getAllowedCriteria: (props) => {
      const { user, action, resource, keyCriteria } = props;

      const accessPermissions = user?.accessPermissions;
      const resultAccessPermissionMap = accessPermissionsMap(accessPermissions);

      const accessPermission = resultAccessPermissionMap?.get(
        // @ts-ignore -- no need to fix because will be removed
        `${resource}.${action}`
      );

      return accessPermission?.allowedCriteria?.[keyCriteria] ?? null;
    },

    // fn to get access permission
    getAccessPermission: (props: {
      user: Member;
      action: Action;
      resource: Resource;
    }) => {
      const { user, action, resource } = props;

      const accessPermissions = user?.accessPermissions;
      const resultAccessPermissionMap = accessPermissionsMap(accessPermissions);

      return resultAccessPermissionMap.get(`${resource}.${action}`);
    },

    // fn to get action label
    getActionLabel: (props: {
      action: Action | 'denied';
      resource: Resource;
    }) => {
      const { action, resource } = props;
      return ACTIONS_LABEL[resource][action] ?? 'Denied';
    },

    getMapDetailPermissions: (props: { permissions: Permission[] }) => {
      const { permissions } = props;

      return permissions.reduce((prev, cur) => {
        prev.set(cur.id, cur);
        return prev;
      }, new Map() as MapPermissionById);
    },

    // group permissions by resource
    groupDetailPermissionsByResource: (props: {
      permissions: Permission[];
    }) => {
      return _.groupBy(props.permissions, 'resource') as PermissionByResource;
    },

    // fn to get page url
    getPageUrl: (props: {
      resource: Resource;
      jobId?: string;
      viewCandidates?: boolean;
      toManageTeam?: boolean;
    }) => {
      const { resource, toManageTeam = true } = props;

      const resourceMapPath = {
        [PERMISSION_RESOURCES.jobPost]: `job-post`,
        [PERMISSION_RESOURCES.talentDb]: `talent-database`,
        [PERMISSION_RESOURCES.talentHunt]: `explore-talents`,
        [PERMISSION_RESOURCES.userCompany]: `manage-team`,
        [PERMISSION_RESOURCES.analyticsGeneral]: `analytics`,
      };

      const generateUrl = () => {
        if (toManageTeam) {
          return `${DEALL_EMPLOYER_DASHBOARD_URL}/manage-team`;
        }

        return `${DEALL_EMPLOYER_DASHBOARD_URL}/${resourceMapPath[resource]}`;
      };

      return generateUrl() || DEALL_EMPLOYER_DASHBOARD_URL;
    },

    // fn to get page name
    getPageName: (props: { resource: Resource; pageName?: string }) => {
      return parseResourceToPageName(props);
    },

    parsePermissionIds: (props: {
      user: Member | null;
      permissionsDetail: Permission[];
    }) => {
      const { user, permissionsDetail } = props;
      const accessPermissions = user?.accessPermissions;

      let result = parsePermissionIds({
        permissionIds: user?.accessPermissions?.map(
          (permission) => permission.permissionId
        ),
        permissionsDetail,
      });

      const finalResult = result?.reduce((prev, cur) => {
        const accessPermission = accessPermissions?.find(
          (permission) => permission.permissionId === cur.id
        );

        if (accessPermission && cur) {
          const resourceExist = prev[cur.resource];
          if (!resourceExist) {
            prev[cur.resource] = {};
          }

          prev[cur.resource][cur.action] = {
            ...accessPermission,
            ...cur,
          };
        }

        return prev;
      }, {});

      return finalResult as PermissionActionByResource;
    },

    getDepartmentsPermissions(props) {
      const { departmentPermissionIds, permissionsDetail, resource, action } =
        props;

      const parsedDepartmentsPermissions = _.groupBy(
        parsePermissionIds({
          permissionsDetail,
          permissionIds: departmentPermissionIds,
        }),
        'resource'
      );

      if (!action) {
        const isResourceExist = parsedDepartmentsPermissions[resource];

        // remove this if in the future view access will be needed again
        const hasViewAccess = parsedDepartmentsPermissions[resource]?.find(
          (permission) => permission.action === PERMISSION_ACTIONS.view
        );

        if (isResourceExist && !hasViewAccess) {
          return parsedDepartmentsPermissions[resource]?.reduce(
            (_, permission) => ({
              ...permission,
              label: GatePermission.getActionLabel({
                action: PERMISSION_ACTIONS[permission.action],
                resource: PERMISSION_RESOURCES[permission.resource],
              }),
              value: PERMISSION_ACTIONS[permission.action],
            }),
            {}
          );
        }

        return {
          label: GatePermission.getActionLabel({
            action: PERMISSION_ACTIONS['denied'],
            resource: PERMISSION_RESOURCES[resource],
          }),
          value: PERMISSION_ACTIONS['denied'],
        };
      }

      const permission = _.groupBy(
        parsedDepartmentsPermissions?.[resource],
        'action'
      )?.[action]?.reduce(
        (_, permission) => ({
          ...permission,
          label: GatePermission.getActionLabel({
            action: PERMISSION_ACTIONS[action],
            resource: PERMISSION_RESOURCES[resource],
          }),
          value: PERMISSION_ACTIONS[action],
        }),
        {}
      );

      if (!permission) {
        return {
          label: GatePermission.getActionLabel({
            action: PERMISSION_ACTIONS['denied'],
            resource: PERMISSION_RESOURCES[resource],
          }),
          value: PERMISSION_ACTIONS['denied'],
        };
      }

      return permission;
    },

    // check is user an owner
    isOwner: (props: { user: Member }) => {
      const { user } = props;

      if (!user) return false;

      return user.role === 'owner';
    },

    // check is valid resource
    isResource: (props: { resource: Resource }) => {
      const { resource } = props || {};
      return Object.values(PERMISSION_RESOURCES).includes(resource);
    },

    excludePermissionByAction: (props: {
      permissions: Permission[];
      action: Action;
    }) => {
      const { permissions, action } = props;
      return permissions?.filter((item) => item?.action !== action);
    },
  };
}

export const GatePermission = PermissionGenerator();

export const generateDepartmentPayload = (
  department: OnBoardingFormValues['department']
) => {
  if (!department?.markedAsOther && department?.key) {
    return {
      departmentId: department?.key,
    };
  }

  return {
    customDepartment: department?.value?.trim(),
  };
};
