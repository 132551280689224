import React from 'react';

import ThreeUsersIcon from '@/icons/menu/3UsersIcon';
import ActivityIcon from '@/icons/menu/ActivityIcon';
import EditSquare from '@/icons/menu/EditSquare';
import HeartIcon from '@/icons/menu/HeartIcon';
import HomeIcon from '@/icons/menu/HomeIcon';
import ImageIcon from '@/icons/menu/ImageIcon';
import MoreCircle from '@/icons/menu/MoreCircle';
import TwoUserIcon from '@/icons/menu/TwoUserIcon';
import WorkIcon from '@/icons/menu/WorkIcon';

type MenuItem = {
  withNewBadge?: boolean;
  beforeChange?: () => void;
  icon: (_: { isActive: boolean; className?: string }) => React.ReactNode;
  menu: Array<Partial<MenuItem> & { id: string }>;
  name: string;
  path: string;
  section: string;
};

type MenuType = Partial<MenuItem> & { id: string };

const useGenerateSidebarMenus = () => {
  const MENUS: MenuType[] = [
    {
      id: 'home',
      name: 'Home',
      path: '/',
      icon: ({ isActive, className }) => (
        <>
          {isActive ? (
            <HomeIcon.Primary className={className} />
          ) : (
            <HomeIcon className={className} />
          )}
        </>
      ),
    },

    {
      id: 'hiring-section',
      section: 'Hiring',
      menu: [
        {
          id: 'job-post',
          name: 'Job Post',
          path: '/job-post',
          icon: ({ isActive, className }) => (
            <>
              {isActive ? (
                <EditSquare.Primary className={className} />
              ) : (
                <EditSquare className={className} />
              )}
            </>
          ),
        },
        {
          id: 'talent-hunt',
          name: 'Talent Search',
          path: '/explore-talents',
          icon: ({ isActive, className }) => (
            <>
              {isActive ? (
                <WorkIcon.Primary className={className} />
              ) : (
                <WorkIcon className={className} />
              )}
            </>
          ),
        },
        {
          id: 'analytics',
          name: 'Analytics',
          path: '/analytics',
          icon: ({ isActive, className }) => (
            <>
              {isActive ? (
                <ActivityIcon.Primary className={className} />
              ) : (
                <ActivityIcon className={className} />
              )}
            </>
          ),
        },
      ],
    },

    {
      id: 'my-company',
      section: 'My Company',
      menu: [
        {
          id: 'talent-database',
          name: 'Talent Database',
          path: '/talent-database',
          icon: ({ isActive, className }) => (
            <>
              {isActive ? (
                <TwoUserIcon.Primary className={className} />
              ) : (
                <TwoUserIcon className={className} />
              )}
            </>
          ),
        },
        {
          id: 'company-profile',
          name: 'Company Profile',
          path: '/company/profile',
          icon: ({ isActive, className }) => (
            <>
              {isActive ? (
                <ImageIcon.Primary className={className} />
              ) : (
                <ImageIcon className={className} />
              )}
            </>
          ),
        },
      ],
    },

    {
      id: 'general',
      section: 'General',
      menu: [
        {
          id: 'manage-team',
          name: 'Manage Team',
          path: '/manage-team',
          icon: ({ isActive, className }) => (
            <>
              {isActive ? (
                <ThreeUsersIcon.Primary className={className} />
              ) : (
                <ThreeUsersIcon className={className} />
              )}
            </>
          ),
        },
        {
          id: 'settings',
          name: 'Settings',
          path: '/settings',
          icon: ({ isActive, className }) => (
            <>
              {isActive ? (
                <MoreCircle.Primary className={className} />
              ) : (
                <MoreCircle className={className} />
              )}
            </>
          ),
        },
        {
          id: 'send-feedback',
          name: 'Send Feedback',
          path: '/help-center',
          icon: ({ isActive, className }) => (
            <>
              {isActive ? (
                <HeartIcon.Primary className={className} />
              ) : (
                <HeartIcon className={className} />
              )}
            </>
          ),
        },
      ],
    },
  ];

  return MENUS;
};

export default useGenerateSidebarMenus;
