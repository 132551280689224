import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import {
  ONBOARDING_PATHS,
  PUBLIC_PATHS,
  SUBSCRIPTION_PATHS,
} from '@/common/constants';
import { initialStateAuth, useStoreAuth } from '@/common/contexts/useAuth';
import useToken from '@/modules/auth/hooks/useToken';
import {
  checkSelfAuthData,
  refreshAccessToken,
} from '@/modules/auth/services/AuthService';
import { signOut } from '@/modules/auth/utils';
import { apiInstance } from '@/modules/core/axios/configs';
import useAnalytics from '@/modules/core/mixpanel/hooks/useAnalytics';
import useUserCompanyLastSeenQuery from '@/modules/manage-team/hooks/queries/useUserCompanyLastSeenQuery';

const RouteGuard = ({ children }) => {
  const router = useRouter();
  const { analytics } = useAnalytics();
  const { state, dispatch, loading, setLoading } = useStoreAuth();
  const { companyData, subscription, isDataFilled, needUpgradePlan } = state;
  const { refreshToken, token, saveToken } = useToken();

  useUserCompanyLastSeenQuery({
    options: {
      enabled: !!companyData?.id && !!token && !loading && isDataFilled,
      refetchInterval: 1000 * 60, // 1 minutes
      refetchOnWindowFocus: false,
    },
    queryParams: {
      id: companyData?.id,
    },
  });

  const handleRefreshToken = async () => {
    if (!refreshToken) {
      signOut(() => {
        analytics.reset();
        dispatch({ key: 'DELETE_AUTH_DATA' });
      });
      return;
    }
    try {
      const response = await checkSelfAuthData({
        axiosInstance: apiInstance,
      });
      return response;
    } catch (err) {
      const { code } = err;

      // unauthorized
      if (code === 401 || !token) {
        refreshAccessToken({
          axiosInstance: apiInstance,
          userCompanyId: companyData?.id,
          body: {
            refreshToken: refreshToken,
          },
        })
          .then((tokenData) => {
            let userData = {
              ...state.userData,
            };

            dispatch({
              key: 'SET_AUTH_DATA',
              data: {
                ...initialStateAuth,
                userData: userData,
              },
            });

            saveToken(tokenData);
            router.reload();
          })
          .catch(() => {
            signOut(() => {
              analytics.reset();
              dispatch({ key: 'DELETE_AUTH_DATA' });
              router.replace('/sign-in');
            });
          });
      }
    }
  };

  const handleAuthCheck = (url) => {
    const path = url?.split('?')[0];

    // // Do not login & accessing private page route
    if (!token && !PUBLIC_PATHS.includes(path)) {
      const encodedUrl = encodeURIComponent(url);

      signOut(() => {
        dispatch({
          key: 'DELETE_AUTH_DATA',
        });
        router.replace({
          pathname: '/sign-in',
          query: {
            ...(router.isReady && { redirectUrl: encodedUrl }),
          },
        });
      });
      return;
    }

    // Logged in, accessing private routes but do not completing onboarding
    if (token && !isDataFilled && !ONBOARDING_PATHS.includes(path)) {
      router.replace('/onboarding');
      return;
    }

    // Logged in, complete onboarding & accessing public  routes
    if (token && isDataFilled && PUBLIC_PATHS.includes(path)) {
      router.replace('/');
      return;
    }

    // Logged in, complete onboarding & accessing onboarding routes
    if (token && isDataFilled && ONBOARDING_PATHS.includes(path)) {
      router.replace('/');
      return;
    }

    // Logged in, complete onboarding but not subscribed or company subs expired
    const checkoutPage = path.startsWith('/checkout');
    if (
      token &&
      (!companyData ||
        !subscription?.status ||
        needUpgradePlan ||
        companyData?.active === false) &&
      isDataFilled &&
      !SUBSCRIPTION_PATHS.includes(path) &&
      !checkoutPage
    ) {
      router.replace('/subscription');
      return;
    }

    handleRefreshToken();
  };

  useEffect(() => {
    // on initial load - run auth check
    handleAuthCheck(router.asPath);
  }, [router.asPath]);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setLoading(true);
    };
    const handleRouteChangeComplete = () => {
      setLoading(false);
    };

    // on route change start - show loading indicator & hide page content
    router.events.on('routeChangeStart', handleRouteChangeStart);
    // on route change complete - hide loading indicator & show page content
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  const loaderIcon = (
    <LoadingOutlined className="text-[60px] text-violet-500" spin />
  );

  if (loading) {
    return (
      <div className="flex h-full min-h-screen min-w-full items-center justify-center">
        <Spin spinning indicator={loaderIcon} />
      </div>
    );
  }

  return children;
};

export { RouteGuard };
