import moment from 'moment';
import qs from 'qs';

export const formatThousands = (value) => {
  if (!value) return '-';
  return value.toLocaleString('id');
};

export const calculateDiffFromNow = (date) => {
  const today = moment(Date.now());
  const startDate = moment(date);

  let yearDiff = Math.abs(moment.duration(startDate.diff(today)).asYears());
  let monthDiff = Math.abs(moment.duration(startDate.diff(today)).asMonths());
  let daysDiff = Math.abs(moment.duration(startDate.diff(today)).asDays());
  let hoursDiff = Math.abs(moment.duration(startDate.diff(today)).asHours());
  let minutesDiff = Math.abs(
    moment.duration(startDate.diff(today)).asMinutes()
  );

  let value = {
    diff: 0,
    type: 'now',
  };
  if (yearDiff > 1) {
    value.diff = Math.floor(yearDiff);
    value.type = 'years';
  } else if (monthDiff > 1) {
    value.diff = Math.floor(monthDiff);
    value.type = 'months';
  } else if (daysDiff > 1) {
    value.diff = Math.floor(daysDiff);
    value.type = 'days';
  } else if (hoursDiff > 1) {
    value.diff = Math.floor(hoursDiff);
    value.type = 'hours';
  } else if (minutesDiff > 1) {
    value.diff = Math.floor(minutesDiff);
    value.type = 'minutes';
  }
  return value;
};

export const formatDate = (date, format = 'DD MMM YYYY') => {
  if (!date) return '-';
  return moment(date).format(format);
};

export const parseDocumentFileSize = async (fileUrl) => {
  try {
    const http = new XMLHttpRequest();

    http.open('HEAD', fileUrl, false);
    http.send(null);

    if (http.status === 200) {
      const fileSize = http.getResponseHeader('content-length');

      return fileSize;
    }

    return null;
  } catch (error) {
    console.log(error);
  }
};

export const parseDocumentFileName = (fileUrl) => {
  const fileDecoded = decodeURIComponent(fileUrl);
  const split = fileDecoded?.split('/');
  return split
    ?.pop()
    ?.replace(/%20/g, ' ')
    .replace(/[^A-Za-z0-9.()-_[]]/g, '');
};

export const formatBytes = (bytes) => {
  const units = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

  let length = 0;
  let values = parseInt(bytes, 10) || 0;

  const size = 1024;

  while (values >= size && ++length) {
    values = values / size;
  }

  return values.toFixed(values < 10 && length > 0 ? 1 : 0) + units[length];
};

/**
 * format params for request,
 * covert array into like this key=value1&key=value2&.....
 *
 * @param {Record<string,any>} params
 * @return {string}
 */
export const paramsSerializer = (params) => {
  return qs.stringify(params, {
    arrayFormat: 'repeat',
  });
};

/**
 * Format Talent Database Options
 * input: [1,2,3,4]
 * output: [1,3,2,4]
 *
 * @param {[]} arr
 * @return {[]} arr
 */

export const filterOptionsOrderFormatter = (rawArr, sortBy = 'label') => {
  if (!rawArr) return [];

  const temp = {};

  rawArr.map((item) => {
    if (!temp[String(item?.value)]) temp[String(item?.value)] = item;
  });

  let arr = Object.values(temp);

  if (sortBy) {
    arr.sort((a, b) => {
      const textA = String(a?.[sortBy]).toLowerCase();
      const textB = String(b?.[sortBy]).toLowerCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }

  if (!arr || !arr?.length) return [];

  const ARR_IS_EVEN = arr.length % 2 === 0;
  const MIDDLE_THRESHOLD = ARR_IS_EVEN
    ? Math.floor(arr.length / 2)
    : Math.floor(arr.length / 2 + 1);

  const left = arr.slice(0, MIDDLE_THRESHOLD);
  const right = arr.slice(MIDDLE_THRESHOLD, arr.length);

  const newArr = left
    .reduce((acc, _, idx) => {
      return [...acc, left[idx], right[idx]];
    }, [])
    .filter((item) => !!item);

  return newArr;
};

/**
 * Format Hour to Days
 *
 * @param {{
 *  hours: number
 *  round: boolean
 *  roundDirection: (ceil|floor)
 * }}
 */

export const hoursToDays = ({
  hours = 0,
  round = true,
  roundDirection = 'ceil',
}) => {
  const HOURS_IN_DAYS = 24;
  const days = hours / HOURS_IN_DAYS;
  if (!round) return days;
  if (roundDirection === 'ceil') return Math.ceil(days);
  return Math.floor(days);
};

// Convert oid into uuid
export const formatIdToUuid = (oid) => {
  const hex = `00000000${oid}`.replace(/-/g, '');
  const parts = [
    hex.slice(0, 8),
    hex.slice(8, 12),
    hex.slice(12, 16),
    hex.slice(16, 20),
    hex.slice(20),
  ];
  const uuid = parts.join('-');
  return uuid;
};

// Convert uuid into oid
export const formatUuidToId = (uuid) => {
  const hex = uuid
    .split('-')
    .filter((item) => item !== '00000000')
    .join('');
  return hex;
};

export const formatSnakeToCamelCase = (str) =>
  str
    .toLowerCase()
    .replace(/[-_][a-z0-9]/g, (group) => group.slice(-1).toUpperCase());
