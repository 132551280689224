const UndrawConfidental = (props) => {
  return (
    <svg
      width="121"
      height="102"
      viewBox="0 0 121 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2845_61052)">
        <path
          d="M76.1626 13.8968C75.029 16.2428 72.9431 17.9894 70.7584 19.411C68.4407 20.9137 65.9492 22.13 63.3384 23.0333C62.9791 23.1589 62.6162 23.2792 62.2533 23.3923H46.7161C46.6155 23.2774 46.5185 23.1571 46.425 23.0333C45.4495 21.7445 44.9267 20.1128 45.1225 18.5135C45.3794 16.417 46.9731 14.4891 49.0409 14.0422C51.1089 13.597 53.466 14.8302 54.093 16.8477C54.4398 12.958 54.8386 8.90494 56.9083 5.59139C58.7822 2.59198 62.0305 0.445175 65.5482 0.0610479C67.6173 -0.154248 69.7048 0.227609 71.5634 1.16136C71.734 1.24752 71.9028 1.33727 72.0681 1.43061C73.1835 2.06244 74.1677 2.90138 74.9679 3.90231C77.1615 6.67736 77.6988 10.7107 76.1626 13.8968Z"
          fill="#F2F2F2"
        />
        <path
          d="M72.0682 1.43062C72.0725 1.47773 72.0645 1.52516 72.045 1.56827C72.0255 1.61138 71.9951 1.64869 71.9568 1.67654C64.9201 7.40156 59.3547 14.7238 55.7244 23.0333C55.6705 23.1517 55.6184 23.272 55.5681 23.3923H54.853C54.9033 23.272 54.9536 23.1517 55.0058 23.0333C55.6867 21.4465 56.4395 19.892 57.2641 18.3699C59.0488 15.0835 61.1487 11.978 63.5342 9.09701C65.9195 6.21255 68.5835 3.57011 71.4878 1.20805C71.5109 1.18931 71.5363 1.17363 71.5634 1.16138C71.6139 1.13965 71.6686 1.12956 71.7235 1.13185C71.7784 1.13414 71.8321 1.14877 71.8806 1.17463C71.9291 1.20049 71.9712 1.23693 72.0036 1.28125C72.0361 1.32557 72.0581 1.37662 72.0682 1.43062Z"
          fill="white"
        />
        <path
          d="M72.3646 23.0332H3.91357C3.32397 23.0326 2.74437 23.1854 2.23187 23.4766C2.10912 23.5462 1.99092 23.6235 1.87799 23.7081C1.45038 24.0249 1.103 24.4373 0.863698 24.9124C0.624398 25.3874 0.499836 25.9119 0.5 26.4437V61.9844C0.500805 62.8887 0.860706 63.7557 1.5007 64.3951C2.14069 65.0345 3.00848 65.3941 3.91357 65.3949H72.3646C73.2697 65.394 74.1375 65.0345 74.7775 64.3951C75.4174 63.7556 75.7774 62.8887 75.7782 61.9844V26.4437C75.7774 25.5394 75.4174 24.6725 74.7775 24.033C74.1375 23.3936 73.2697 23.0341 72.3646 23.0332ZM75.4188 61.9844C75.4177 62.7933 75.0955 63.5688 74.523 64.1408C73.9505 64.7128 73.1743 65.0347 72.3646 65.0359H3.91357C3.10388 65.0347 2.32769 64.7129 1.75516 64.1408C1.18262 63.5688 0.860469 62.7933 0.859323 61.9844V26.4437C0.861874 25.7988 1.06772 25.1711 1.44764 24.6498C1.82755 24.1284 2.36221 23.7399 2.9757 23.5394C3.00087 23.5322 3.0242 23.5233 3.04939 23.5161C3.33002 23.4337 3.62106 23.392 3.91357 23.3922H72.3646C73.1743 23.3934 73.9505 23.7153 74.523 24.2873C75.0955 24.8593 75.4177 25.6348 75.4188 26.4437V61.9844Z"
          fill="#3F3D56"
        />
        <path
          d="M97.0174 69.3927C97.2538 69.2388 97.4535 69.035 97.6025 68.7956C97.7515 68.5562 97.8462 68.2871 97.8798 68.0072C97.9134 67.7273 97.8853 67.4434 97.7972 67.1756C97.7092 66.9078 97.5634 66.6625 97.3702 66.457L98.4432 53.6748L95.37 54.0125L94.5281 66.4532C94.2041 66.8004 94.0189 67.2541 94.0074 67.7286C93.9959 68.2031 94.1591 68.6653 94.4659 69.0276C94.7728 69.39 95.202 69.6272 95.6722 69.6945C96.1425 69.7618 96.6211 69.6544 97.0174 69.3927Z"
          fill="#FFB8B8"
        />
        <path
          d="M86.0795 99.8806L83.8769 99.8805L82.8291 91.3923L86.0799 91.3925L86.0795 99.8806Z"
          fill="#FFB8B8"
        />
        <path
          d="M82.3035 99.2517H86.5514V101.924H79.6289C79.6289 101.573 79.6981 101.225 79.8325 100.901C79.9669 100.577 80.1639 100.282 80.4123 100.034C80.6606 99.7862 80.9555 99.5894 81.28 99.4551C81.6045 99.3208 81.9523 99.2517 82.3035 99.2517Z"
          fill="#2F2E41"
        />
        <path
          d="M101.71 99.8806L99.5073 99.8805L98.4595 91.3923L101.71 91.3925L101.71 99.8806Z"
          fill="#FFB8B8"
        />
        <path
          d="M97.9341 99.2517H102.182V101.924H95.2595C95.2595 101.215 95.5413 100.535 96.0429 100.034C96.5445 99.5332 97.2248 99.2517 97.9341 99.2517Z"
          fill="#2F2E41"
        />
        <path
          d="M86.1165 94.6235C86.0927 94.6235 86.0687 94.6225 86.0445 94.6205L82.9271 94.3713C82.8034 94.3614 82.6831 94.3261 82.5738 94.2674C82.4645 94.2088 82.3685 94.1282 82.2919 94.0307C82.2154 93.9332 82.1598 93.8209 82.1288 93.7009C82.0978 93.5809 82.0921 93.4557 82.1119 93.3334L86.8384 64.1254L87.155 60.0057C87.169 59.8131 87.2452 59.6303 87.372 59.4846C87.4989 59.3389 87.6696 59.2383 87.8586 59.1978L92.6127 58.1589C92.7875 58.1198 92.97 58.1342 93.1364 58.2002C93.3029 58.2662 93.4456 58.3806 93.5461 58.5287L94.7053 60.2215C95.1605 60.8796 95.6552 61.5095 96.1866 62.1078C97.0091 63.0437 98.4075 65.1216 98.5329 68.5223C98.5666 69.6394 98.6828 70.7526 98.8804 71.8526L102.708 92.452C102.727 92.5728 102.722 92.6962 102.691 92.8148C102.661 92.9333 102.607 93.0445 102.533 93.1414C102.458 93.2383 102.364 93.3189 102.257 93.3783C102.15 93.4377 102.032 93.4746 101.91 93.4868L98.9551 93.7104C98.7437 93.7313 98.5317 93.6763 98.3572 93.5552C98.1827 93.4342 98.0569 93.2551 98.0025 93.0499L92.7606 73.5742C92.7503 73.536 92.7277 73.5023 92.6963 73.4783C92.6649 73.4542 92.6264 73.4412 92.5868 73.4413C92.5473 73.4413 92.5088 73.4545 92.4775 73.4786C92.4462 73.5027 92.4237 73.5365 92.4135 73.5747L86.9843 93.9569C86.9332 94.1479 86.8205 94.3168 86.6636 94.4373C86.5067 94.5578 86.3144 94.6233 86.1165 94.6235Z"
          fill="#2F2E41"
        />
        <path
          d="M94.4068 44.922C96.8439 44.922 98.8195 42.9482 98.8195 40.5134C98.8195 38.0785 96.8439 36.1047 94.4068 36.1047C91.9698 36.1047 89.9941 38.0785 89.9941 40.5134C89.9941 42.9482 91.9698 44.922 94.4068 44.922Z"
          fill="#FFB8B8"
        />
        <path
          d="M86.9274 60.6685L86.8361 54.3727C86.5 50.3461 88.2849 48.2319 89.8415 47.1684C90.2429 46.8977 90.6951 46.7112 91.1708 46.6203C91.6465 46.5293 92.1357 46.5357 92.6088 46.6392L96.0816 47.3893C96.5652 47.4946 97.0224 47.6967 97.4255 47.9836C97.8286 48.2704 98.1694 48.6359 98.4271 49.0581C98.6736 49.4564 98.8354 49.901 98.9025 50.3645C98.9697 50.8279 98.9407 51.3002 98.8174 51.752C98.5761 52.5975 98.041 53.3295 97.3083 53.8165C94.3243 55.9211 94.1141 59.4562 94.1123 59.4918L94.1086 59.5649L86.9274 60.6685Z"
          fill="#6913D8"
        />
        <path
          d="M97.7956 55.987H94.7413C94.527 55.9867 94.3215 55.9015 94.1699 55.7501C94.0184 55.5987 93.9331 55.3934 93.9329 55.1792V49.884C93.9329 49.2651 94.1789 48.6716 94.6169 48.234C95.0549 47.7964 95.649 47.5505 96.2685 47.5505C96.8879 47.5505 97.482 47.7964 97.92 48.234C98.358 48.6716 98.6041 49.2651 98.6041 49.884V55.1792C98.6038 55.3934 98.5186 55.5987 98.367 55.7501C98.2154 55.9015 98.0099 55.9867 97.7956 55.987Z"
          fill="#6913D8"
        />
        <path
          d="M77.2894 56.9873C77.1095 57.2019 76.8861 57.376 76.634 57.498C76.3819 57.6201 76.1068 57.6874 75.8267 57.6955C75.5467 57.7035 75.2681 57.6523 75.0094 57.545C74.7506 57.4377 74.5176 57.2768 74.3256 57.073C72.5853 55.2495 75.4301 52.5114 77.177 54.3389C77.2386 54.4019 77.2957 54.469 77.3481 54.5399L86.1566 54.3212L87.2079 52.4L90.4391 53.6535C89.8188 54.6146 89.1065 58.3868 87.4841 58.2032C87.2713 58.2089 77.5069 57.0028 77.2894 56.9873Z"
          fill="#FFB8B8"
        />
        <path
          d="M89.0729 55.5337L86.6105 53.7283C86.4379 53.6014 86.3227 53.4112 86.2901 53.1995C86.2576 52.9879 86.3105 52.772 86.4371 52.5992L89.5728 48.3302C89.7543 48.0832 89.9827 47.8742 90.245 47.7154C90.5073 47.5565 90.7983 47.4508 91.1015 47.4043C91.4046 47.3578 91.714 47.3715 92.0119 47.4444C92.3098 47.5174 92.5904 47.6483 92.8376 47.8296C93.0849 48.0109 93.294 48.2391 93.453 48.5011C93.612 48.7631 93.7178 49.0539 93.7644 49.3568C93.8109 49.6597 93.7972 49.9688 93.7242 50.2664C93.6512 50.564 93.5202 50.8444 93.3387 51.0914L90.203 55.3604C90.076 55.5329 89.8857 55.648 89.6738 55.6805C89.4619 55.713 89.2458 55.6602 89.0729 55.5337Z"
          fill="#6913D8"
        />
        <path
          d="M95.2906 42.8049C95.4329 42.508 95.4823 42.1752 95.4323 41.8499C95.3823 41.5245 95.2352 41.2218 95.0103 40.9814C94.1801 40.1451 92.8831 40.7029 91.7612 40.621C89.8925 40.4846 88.6198 38.2582 89.1776 36.4711C89.7355 34.684 91.7184 33.575 93.5884 33.6926C95.4584 33.8102 97.1515 34.9659 98.2785 36.4614C99.05 35.2209 100.945 35.0762 102.169 35.8759C103.392 36.6755 104.025 38.1368 104.286 39.5737C104.548 41.0106 104.505 42.4872 104.725 43.931C105.259 47.4307 107.52 50.7262 110.791 52.0866C114.062 53.447 118.249 52.5727 120.389 49.7512C120.805 53.1704 120.075 56.631 118.313 59.5915C116.551 62.552 113.856 64.8455 110.651 66.1132C108.878 66.8142 106.92 67.1864 105.054 66.7967C102.041 66.1677 99.6979 63.5934 98.6765 60.6924C97.6552 57.7915 97.7775 54.6084 98.2735 51.5734C98.5791 49.7041 98.9174 47.4902 97.5528 46.1754C97.1697 45.8568 96.719 45.6298 96.2349 45.5115C95.9782 45.4345 95.7425 45.2999 95.5458 45.1181C95.349 44.9362 95.1964 44.7119 95.0996 44.4622C95.0027 44.2125 94.9642 43.944 94.987 43.6772C95.0097 43.4104 95.0931 43.1523 95.2308 42.9226C95.253 42.8835 95.2729 42.8443 95.2906 42.8049Z"
          fill="#2F2E41"
        />
        <path
          d="M111.132 102H70.1689C70.1213 102 70.0756 101.981 70.0419 101.948C70.0082 101.914 69.9893 101.868 69.9893 101.821C69.9893 101.773 70.0082 101.727 70.0419 101.694C70.0756 101.66 70.1213 101.641 70.1689 101.641H111.132C111.179 101.641 111.225 101.66 111.259 101.694C111.292 101.727 111.311 101.773 111.311 101.821C111.311 101.868 111.292 101.914 111.259 101.948C111.225 101.981 111.179 102 111.132 102Z"
          fill="#3F3D56"
        />
        <path
          d="M38.0643 44.1989C37.7917 44.1988 37.5235 44.1298 37.2848 43.9982L1.52441 24.3336C1.48267 24.3107 1.45177 24.2721 1.4385 24.2264C1.42524 24.1806 1.43069 24.1315 1.45366 24.0898C1.47664 24.0481 1.51526 24.0172 1.56102 24.004C1.60678 23.9907 1.65593 23.9962 1.69767 24.0191L37.4581 43.6837C37.6404 43.7843 37.845 43.8379 38.0533 43.8398C38.2617 43.8416 38.4672 43.7916 38.6513 43.6942L75.0003 24.5364C75.0212 24.5254 75.044 24.5185 75.0675 24.5163C75.091 24.5141 75.1148 24.5165 75.1374 24.5235C75.1599 24.5304 75.1809 24.5417 75.1991 24.5568C75.2173 24.5719 75.2323 24.5904 75.2433 24.6112C75.2543 24.6321 75.2611 24.6549 75.2633 24.6784C75.2655 24.7019 75.263 24.7256 75.256 24.7482C75.249 24.7707 75.2376 24.7916 75.2225 24.8098C75.2074 24.8279 75.1889 24.8429 75.168 24.8539L38.8189 44.0116C38.5864 44.1345 38.3274 44.1988 38.0643 44.1989Z"
          fill="#3F3D56"
        />
        <path
          d="M11.9205 56.4199H6.88993C6.50874 56.4199 6.14316 56.2687 5.87361 55.9994C5.60407 55.7301 5.45264 55.3648 5.45264 54.984C5.45264 54.6031 5.60407 54.2379 5.87361 53.9686C6.14316 53.6993 6.50874 53.548 6.88993 53.548H11.9205C12.3016 53.548 12.6672 53.6993 12.9368 53.9686C13.2063 54.2379 13.3577 54.6031 13.3577 54.984C13.3577 55.3648 13.2063 55.7301 12.9368 55.9994C12.6672 56.2687 12.3016 56.4199 11.9205 56.4199Z"
          fill="#3F3D56"
        />
        <path
          d="M17.8493 61.2664H6.88993C6.50874 61.2664 6.14316 61.1151 5.87361 60.8458C5.60407 60.5765 5.45264 60.2112 5.45264 59.8304C5.45264 59.4495 5.60407 59.0843 5.87361 58.815C6.14316 58.5457 6.50874 58.3944 6.88993 58.3944H17.8493C18.2305 58.3944 18.5961 58.5457 18.8656 58.815C19.1351 59.0843 19.2866 59.4495 19.2866 59.8304C19.2866 60.2112 19.1351 60.5765 18.8656 60.8458C18.5961 61.1151 18.2305 61.2664 17.8493 61.2664Z"
          fill="#3F3D56"
        />
        <path
          d="M38.0499 49.958C41.2251 49.958 43.7991 47.3863 43.7991 44.214C43.7991 41.0417 41.2251 38.4701 38.0499 38.4701C34.8748 38.4701 32.3008 41.0417 32.3008 44.214C32.3008 47.3863 34.8748 49.958 38.0499 49.958Z"
          fill="#6913D8"
        />
      </g>
      <defs>
        <clipPath id="clip0_2845_61052">
          <rect
            width="120"
            height="102"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UndrawConfidental;
