import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { useStoreAuth } from '@/common/contexts/useAuth';
import { useHeader } from '@/common/contexts/useHeader';
import { useSidebar } from '@/common/contexts/useSidebar';
import useWindowSize from '@/common/hooks/useWindowSize';
import { getWord } from '@/common/utils';
import LogoIcon from '@/icons/LogoIcon';
import HamburgerIcon from '@/icons/menu/Hamburger';
import NotificationActiveIcon from '@/icons/NotificationActiveIcon';
import RightArrowIcon from '@/icons/RightArrowIcon';
import { signOut } from '@/modules/auth/utils';
import useAnalytics from '@/modules/core/mixpanel/hooks/useAnalytics';
import NotificationPreview from '@/modules/notifications/components/NotificationPreview';
import useNotificationStatusQuery from '@/modules/notifications/hooks/queries/useNotificationStatusQuery';

import containerStyles from './index.module.scss';

type NavbarProps = {
  checkPath?: boolean;
  className?: string;
  withLogo?: boolean;
  logoSrc?: string;
  notificationIconClassName?: string;
  userClassName?: string;
  messageIconClassName?: string;
  arrowDownColor?: string;
};

type MobileNavbarProps = {
  className?: string;
  hamburgerIconClassName?: string;
};

export const LogoComponent = ({ checkPath }: { checkPath: boolean }) => {
  return (
    <Link href="/">{checkPath ? <LogoIcon /> : <LogoIcon.Primary />}</Link>
  );
};

export const Navbar = ({
  checkPath,
  className,
  withLogo = false,
  logoSrc,
  notificationIconClassName,
  userClassName,
}: NavbarProps) => {
  const [menuActive, setMenuActive] = useState(false);
  const router = useRouter();
  const { analytics } = useAnalytics();
  const [width] = useWindowSize();

  const { headerData } = useHeader();
  const { dispatch, setLoading, state } = useStoreAuth();
  const { data: notificationStatus } = useNotificationStatusQuery();

  const { mobileCollapsed, setMobileCollapsed } = useSidebar();

  const handleLogout = () => {
    signOut(() => {
      setLoading(true);
      analytics.reset();
      dispatch({ key: 'DELETE_AUTH_DATA' });
      router.replace('/sign-in');
    });
  };

  return (
    <div
      className={clsx(
        'align-center flex justify-between px-8 py-2',
        className,
        {
          'bg-[#FAFAFA]': !checkPath,
        }
      )}
    >
      <div style={{ display: 'flex' }}>
        <div className={`${containerStyles.collapsible_container}`}>
          <div
            className={containerStyles.collapsible_icon}
            onClick={() => setMobileCollapsed(true)}
          >
            {!mobileCollapsed ? <HamburgerIcon /> : null}
          </div>
          {/* if collapsed logo will disappear */}
          {mobileCollapsed && logoSrc ? (
            <img src={logoSrc} alt="logo" />
          ) : withLogo || checkPath ? (
            <LogoComponent checkPath={!!checkPath} />
          ) : null}
        </div>

        <div className={containerStyles.back_button}>
          {headerData?.isHasBackButton && (
            <NavbarBackButton headerData={headerData} />
          )}
        </div>
      </div>

      <div
        className={clsx(containerStyles.info_container, {
          'h-[46px] min-h-[46px]': checkPath,
        })}
      >
        {/* <MessageIcon
          color={headerData?.iconColor ?? "#484848"}
          alt="message"
          className={clsx("mr-2", props?.messageIconClassName)}
        /> */}

        {!checkPath && (
          <Dropdown
            arrow
            placement="bottomRight"
            overlay={<NotificationPreview />}
            trigger={['click']}
          >
            <NotificationActiveIcon
              color={headerData?.iconColor ?? '#484848'}
              active={notificationStatus?.hasNotification}
              className={clsx('cursor-pointer', notificationIconClassName)}
              onClick={(e) => {
                if (width < 768) {
                  e.stopPropagation();
                  router.push('/notifications');
                }
              }}
            />
          </Dropdown>
        )}

        {!checkPath ? (
          <div
            className={clsx(
              'hidden cursor-pointer',
              {
                '!hidden': checkPath,
              },
              containerStyles.profile_container
            )}
            onClick={() => setMenuActive(!menuActive)}
          >
            <Avatar
              size={32}
              className="flex items-center justify-center lg:hidden"
              src={state?.companyData?.profile?.profilePictureUrl}
            ></Avatar>
            <h2
              className={clsx(
                'hidden px-3 text-sm font-semibold md:block',
                userClassName,
                {
                  'text-neutral-1000': !checkPath,
                  'text-white': checkPath,
                }
              )}
            >
              {state?.userData?.profile?.name
                ? getWord(state?.userData?.profile?.name, 2)
                : 'User'}
            </h2>
            <Menu
              className={`${containerStyles.menu_content} ${
                menuActive && containerStyles.active
              }`}
            >
              <Menu.Item
                key="logout"
                icon={<LogoutOutlined />}
                className={containerStyles.menu_item}
                onClick={handleLogout}
              >
                Logout
              </Menu.Item>
            </Menu>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const MobileNavbar = ({
  className,
  hamburgerIconClassName,
}: MobileNavbarProps) => {
  const { mobileCollapsed, setMobileCollapsed } = useSidebar();

  return (
    <div
      className={clsx(
        className,
        'sticky inset-x-0 top-0 z-[500] w-full bg-white px-4 py-6 998:hidden'
      )}
    >
      <div className="flex items-center">
        <HamburgerIcon
          className={clsx('cursor-pointer', hamburgerIconClassName)}
          onClick={() => setMobileCollapsed(!mobileCollapsed)}
        />
        <Link href="/" passHref legacyBehavior>
          <LogoIcon.Primary className="ml-[24px] h-5" />
        </Link>
      </div>
    </div>
  );
};

export const NavbarBackButton = ({
  headerData,
}: {
  headerData: {
    isHasBackButton: boolean;
    backButtonName: string;
    backButtonDestination: string;
    textColor: string;
  };
}) => {
  return (
    <Link
      href={headerData.backButtonDestination ?? '/'}
      className="flex items-center text-gray-500 hover:text-blumine-800"
      style={{ color: headerData.textColor }}
    >
      <RightArrowIcon className="rotate-180" />
      {headerData?.backButtonName}
    </Link>
  );
};
