import { message } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';

import { apiInstance } from '@/modules/core/axios/configs';
import { setLastSignedIn } from '@/modules/manage-team/services/TeamService';

import {
  GoogleUserInfo,
  LoginWithEmailPayload,
  LoginWithSocmedPayload,
  RegisterWithEmailPayload,
  RegisterWithSocmedPayload,
} from '../types';

const AuthService = {
  loginWithEmail: ({
    axiosConfig,
    payload,
  }: {
    axiosConfig?: AxiosRequestConfig;
    payload: LoginWithEmailPayload;
  }) => {
    return apiInstance.post('/v1/login/email', payload, axiosConfig);
  },

  registerWithEmail: ({
    axiosConfig,
    payload,
  }: {
    axiosConfig?: AxiosRequestConfig;
    payload: RegisterWithEmailPayload;
  }) => {
    return apiInstance.post('/v1/registration/email', payload, axiosConfig);
  },

  loginWithSocmed: ({
    axiosConfig,
    payload,
  }: {
    axiosConfig?: AxiosRequestConfig;
    payload: LoginWithSocmedPayload;
  }) => {
    return apiInstance.post('/v1/login/socmed', payload, axiosConfig);
  },

  registerWithSocmed: ({
    axiosConfig,
    payload,
  }: {
    axiosConfig?: AxiosRequestConfig;
    payload: RegisterWithSocmedPayload;
  }) => {
    return apiInstance.post('/v1/registration/socmed', payload, axiosConfig);
  },

  getGoogleUserInfo: ({
    axiosConfig,
  }: {
    axiosConfig?: AxiosRequestConfig;
  }) => {
    return axios.get<GoogleUserInfo>('/oauth2/v3/userinfo', axiosConfig);
  },

  getMyUserData: ({ axiosConfig }: { axiosConfig?: AxiosRequestConfig }) => {
    return apiInstance.get('/v1/users/me', axiosConfig);
  },
};

export default AuthService;

// TODO: move to AuthService
export const refreshAccessToken = async (props) => {
  const { axiosInstance, userCompanyId, body, ...options } = props;
  const { refreshToken } = body;

  try {
    if (axiosInstance) {
      const {
        data: { data },
      } = await axiosInstance.post(
        'v1/login/refresh',
        {
          refreshToken,
          platform: 'job_portal_employer',
        },
        ...options
      );

      await setLastSignedIn({
        instance: apiInstance,
        id: userCompanyId,
      })?.catch((error) => console.log(error));

      const { tokenData } = data;

      if (tokenData && tokenData.token) {
        tokenData.expire = Number(tokenData.expire) * 1000 + Date.now();
        tokenData.refreshExpire =
          Number(tokenData.refreshExpire) * 1000 + Date.now();
        return tokenData;
      } else {
        message.error('Restricted access');
      }
    }
  } catch (e) {
    throw new Error(e as string);
  }
};

export const checkSelfAuthData = (props) => {
  const { axiosInstance, ...options } = props;
  return axiosInstance.get('/v1/login/self', {
    ...options,
  });
};

export const changePassword = (props) => {
  const { axiosInstance, body, ...options } = props;

  return axiosInstance.put(
    '/v1/users/password',
    {
      ...body,
    },
    {
      ...options,
    }
  );
};

export const sendVerificationCode = (props) => {
  const { axiosInstance, body, ...options } = props;
  return axiosInstance.post(
    '/v1/users/forgot/code',
    {
      ...body,
    },
    {
      ...options,
    }
  );
};

export const verifyForgotPassword = (props) => {
  const { axiosInstance, body, ...options } = props;
  return axiosInstance.post(
    '/v1/users/forgot/verify',
    {
      ...body,
    },
    {
      ...options,
    }
  );
};

export const generateBridgeToken = (props) => {
  const { axiosInstance, payload } = props;
  return axiosInstance.post('/v1/auth/login-token', {
    ...payload,
  });
};
