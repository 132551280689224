import { Tooltip } from 'antd';
import moment from 'moment';
import QueryString from 'qs';

import Button from '@/common/components/Button';
import useWindowSize from '@/common/hooks/useWindowSize';
import { FixMeLater } from '@/common/types';
import NoAvatarIcon from '@/icons/NoAvatarIcon';
import { Member } from '@/modules/company/types';
import { Job } from '@/modules/jobs/types';
import {
  ACTIONS_LABEL,
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '@/modules/manage-team/constants';
import AddJobIcon from '@/modules/manage-team/icons/AddJobIcon';
import {
  AccessPermission,
  Action,
  Permission,
  PermissionByResource,
} from '@/modules/permissions/types';
import { GatePermission } from '@/modules/permissions/utils/generator/permissions';
import SendInvitation from '@/modules/settings/icons/SendInvitation';

type TeamColumnProps = {
  permissionsDataResources: PermissionByResource;
  SelectPermission: FixMeLater;
  permissionsResult: Permission[];
  isTooltipVisible: boolean;
  setIsTooltipVisible: FixMeLater;
  currentCompanyUser: Member;
};

export const TEAM_COLUMN = (props: TeamColumnProps) => {
  const {
    permissionsDataResources,
    SelectPermission,
    permissionsResult,
    isTooltipVisible,
    setIsTooltipVisible,
    currentCompanyUser,
  } = props;

  const getFinalPermission = (
    permission: Record<Action, Permission & AccessPermission>,
    row: Member
  ) => {
    const owner = GatePermission.isOwner({
      user: row,
    });

    if (
      permission?.manage ||
      (permission?.view && permission?.manage) ||
      owner
    ) {
      return {
        label: 'Editor',
        value: 'manage',
        ...permission?.manage,
      };
    } else if (permission?.view) {
      return {
        label: 'Viewer',
        value: 'view',
        ...permission?.view,
      };
    } else {
      return {
        label: 'Denied',
        value: 'denied',
      };
    }
  };

  const [width] = useWindowSize();

  return [
    {
      title: 'Member',
      dataIndex: 'name',
      fixed: 'left',
      // sorter: () => true,
      render: (_, row) => {
        return (
          <div className="flex items-center gap-3 text-sm text-neutral-900">
            {row?.profile?.profilePictureUrl ? (
              <img
                className="size-[36px] shrink-0 rounded-full"
                src={row?.profile?.profilePictureUrl}
                alt="profile"
              />
            ) : (
              <NoAvatarIcon className="shrink-0" />
            )}
            <div className="flex flex-col gap-[2px]">
              <div className="flex flex-wrap items-center space-x-[4px]">
                <span className="inline-block text-[12px] font-semibold text-neutral-900">
                  {row?.name}
                </span>
                {row.role === 'owner' && (
                  <span className="inline-block w-fit rounded-[16px] bg-aquamarine-0 px-[8px] py-[2.5px] text-[10px] font-semibold text-aquamarine-600">
                    Admin
                  </span>
                )}
              </div>

              <span className="block text-[11px] leading-[130%] text-neutral-600">
                {row.email}
              </span>
            </div>
          </div>
        );
      },
      width: 320,
    },
    {
      title: 'Talent Database',
      dataIndex: 'talentdB',

      // sorter: () => true,
      render: (_, row) => {
        const talentDbPermission = GatePermission.parsePermissionIds({
          user: row,
          permissionsDetail: permissionsResult,
        })?.[PERMISSION_RESOURCES.talentDb];

        const finalPermission = getFinalPermission(talentDbPermission, row);

        const selectOptions = GatePermission.excludePermissionByAction({
          permissions: permissionsDataResources[PERMISSION_RESOURCES.talentDb],
          action: PERMISSION_ACTIONS.view,
        });

        return (
          <SelectPermission
            finalPermission={finalPermission}
            selectOptions={selectOptions}
            resource={PERMISSION_RESOURCES.talentDb}
            row={row}
          />
        );
      },
      width: 168,
    },
    {
      title: 'Talent Search',
      dataIndex: 'talentHunt',

      // sorter: () => true,
      render: (_, row) => {
        const talentHuntPermission = GatePermission.parsePermissionIds({
          user: row,
          permissionsDetail: permissionsResult,
        })?.[PERMISSION_RESOURCES.talentHunt];

        const finalPermission = getFinalPermission(talentHuntPermission, row);
        const selectOptions = GatePermission.excludePermissionByAction({
          permissions:
            permissionsDataResources[PERMISSION_RESOURCES.talentHunt],
          action: PERMISSION_ACTIONS.view,
        });

        return (
          <SelectPermission
            finalPermission={finalPermission}
            selectOptions={selectOptions}
            resource={PERMISSION_RESOURCES.talentHunt}
            row={row}
          />
        );
      },
      width: 168,
    },

    {
      title: 'Create Job',
      dataIndex: 'createJob',

      // sorter: () => true,
      render: (_, row) => {
        const createJobPermission = GatePermission.parsePermissionIds({
          user: row,
          permissionsDetail: permissionsResult,
        })?.[PERMISSION_RESOURCES.jobPost]?.[PERMISSION_ACTIONS.create];

        const owner = GatePermission.isOwner({
          user: row,
        });

        const finalPermission = {
          ...((createJobPermission || owner) && createJobPermission),
          label: createJobPermission || owner ? 'Allow' : 'Denied',
          value: createJobPermission || owner ? 'view' : 'denied',
        };

        const selectOptions =
          permissionsDataResources[PERMISSION_RESOURCES.jobPost] ?? [];

        return (
          <SelectPermission
            finalPermission={finalPermission}
            selectOptions={selectOptions.filter(
              (option) => option.action === PERMISSION_ACTIONS.create
            )}
            resource={PERMISSION_RESOURCES.jobPost}
            row={row}
          />
        );
      },
      width: 168,
    },

    {
      title: 'Analytics',
      dataIndex: 'analytics',
      render: (_, row) => {
        const analyticsGeneralPermissions = GatePermission.parsePermissionIds({
          user: row,
          permissionsDetail: permissionsResult,
        })?.[PERMISSION_RESOURCES.analyticsGeneral];

        const owner = GatePermission.isOwner({
          user: row,
        });

        const viewAnalyticsGeneralPermission =
          analyticsGeneralPermissions?.['view'];

        const usersPermission = {
          ...viewAnalyticsGeneralPermission,
          label: viewAnalyticsGeneralPermission || owner ? 'Allow' : 'Denied',
          value: viewAnalyticsGeneralPermission || owner ? 'view' : 'denied',
        };

        const selectOptions =
          permissionsDataResources[PERMISSION_RESOURCES.analyticsGeneral] ?? [];

        return (
          <SelectPermission
            finalPermission={usersPermission}
            selectOptions={selectOptions}
            resource={PERMISSION_RESOURCES.analyticsGeneral}
            row={row}
          />
        );
      },
      width: 168,
    },
    {
      title: 'Access Per Job Post',
      dataIndex: 'jobPost',

      // sorter: () => true,
      render: (_, row) => {
        let viewJobPermission = GatePermission.getAccessPermission({
          user: row,
          action: PERMISSION_ACTIONS.view,
          resource: PERMISSION_RESOURCES.jobPost,
        });

        const manageJobPermission = GatePermission.getAccessPermission({
          user: row,
          action: PERMISSION_ACTIONS.manage,
          resource: PERMISSION_RESOURCES.jobPost,
        });

        const owner = GatePermission.isOwner({
          user: currentCompanyUser,
        });

        let jobs: {
          actionLabel: string;
          id: Job['id'];
          role: Job['role'];
          status: Job['status'];
        }[] = [];

        const allowedCriteriaViewJobs =
          viewJobPermission?.allowedCriteria?.jobs ?? [];
        const allowedCriteriaManageJobs =
          manageJobPermission?.allowedCriteria?.jobs ?? [];

        const hasViewJobPermission = allowedCriteriaViewJobs.length > 0;
        const hasManageJobPermission = allowedCriteriaManageJobs.length > 0;

        if (hasViewJobPermission) {
          const manageJobIds =
            manageJobPermission?.allowedCriteria?.jobIds ?? [];

          // filter viewJobPermission by manageJobIds to exclude id that has manage permission
          viewJobPermission!.allowedCriteria!.jobs =
            allowedCriteriaViewJobs.filter(
              (job) => !manageJobIds.includes(job.id)
            );

          jobs.push(
            ...allowedCriteriaViewJobs.map((job) => {
              return {
                ...job,
                actionLabel: ACTIONS_LABEL[PERMISSION_RESOURCES.jobPost].view,
              };
            })
          );
        }

        if (hasManageJobPermission) {
          jobs.push(
            ...allowedCriteriaManageJobs.map((job) => {
              return {
                ...job,
                actionLabel: ACTIONS_LABEL[PERMISSION_RESOURCES.jobPost].manage,
              };
            })
          );
        }

        const MAX_VISIBLE_JOBS = 2;
        const visibleJobs = jobs.filter((_, index) => index < MAX_VISIBLE_JOBS);

        const hasRestJobs = jobs.length > MAX_VISIBLE_JOBS;
        const hasJobs = jobs.length > 0;

        const generateJobsContent = () => {
          if (owner && !hasJobs) {
            return (
              <div className="flex w-full items-center justify-center gap-[10px] text-center">
                <AddJobIcon />
                <span className="inline-block text-[11px] leading-[130%] text-neutral-1000 underline">
                  Click to give job access.
                </span>
              </div>
            );
          }

          if (!hasJobs && !owner) {
            return (
              <div className="flex w-full items-center justify-center gap-[10px] text-center">
                <span className="inline-block text-[11px] font-light italic leading-[130%] text-neutral-500">
                  No Acess to Job Post
                </span>
              </div>
            );
          }

          return null;
        };

        return (
          <div
            className="flex min-h-[32px] items-center rounded-[8px] border border-solid border-neutral-150 px-[16px] py-[5px]"
            onMouseEnter={() => {
              if (hasRestJobs) {
                setIsTooltipVisible((prev) => ({
                  ...prev,
                  seeAndProcessCandidate: {
                    ...prev.seeAndProcessCandidate,
                    [row.id]: true,
                  },
                }));
              }
            }}
            onMouseLeave={() => {
              if (hasRestJobs) {
                setIsTooltipVisible((prev) => ({
                  ...prev,
                  seeAndProcessCandidate: {
                    ...prev.seeAndProcessCandidate,
                    [row.id]: false,
                  },
                }));
              }
            }}
          >
            <span className="inline-block w-full  text-[11px] leading-[130%]  text-neutral-1000">
              {generateJobsContent()}
              <Tooltip
                open={isTooltipVisible['seeAndProcessCandidate'][row.id]}
                title={
                  <ul
                    className="space-y-1"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {jobs?.map((item, idx) => (
                      <li
                        // eslint-disable-next-line react/no-array-index-key
                        key={`seeAndProcessCandidate-${item?.role}${idx}}`}
                        className="flex  items-center space-x-2 text-[11px] font-normal text-neutral-900"
                      >
                        <div className="size-1 shrink-0 rounded-full bg-black"></div>
                        <div className="flex flex-1 ">
                          <span className="inline-block break-words">
                            {item?.role}
                          </span>
                          &nbsp;
                          <span className="inline-block w-max shrink-0">
                            - {item.actionLabel}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                }
                placement="bottomLeft"
                showArrow={false}
                overlayInnerStyle={{
                  marginTop: '-15px',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  maxHeight: '400px',
                  overflowY: 'auto',
                  padding: '8px 16px',
                }}
              >
                <div className="flex items-center justify-center gap-[6px]">
                  <span className="line-clamp-1 inline-block break-all text-center text-[11px] leading-[130%] text-neutral-1000">
                    {visibleJobs
                      .map((job) => {
                        return job.role;
                      })
                      .join(', ')}
                  </span>

                  {hasRestJobs && (
                    <span className="inline-block rounded-[32px] bg-neutral-100 px-[7px] py-[3px] text-[11px] leading-[130%] text-neutral-1000">
                      +{jobs.length - MAX_VISIBLE_JOBS}
                    </span>
                  )}
                </div>
              </Tooltip>
            </span>
          </div>
        );
      },
      width: width <= 1300 ? 255 : 'auto',
    },
  ];
};

export const INVITED_COLUMN = ({
  handleResendInvitation,
  resendInvitationLoading,
}) => {
  return [
    {
      title: 'Member',
      dataIndex: 'name',
      // sorter: () => true,
      render: (_, row) => {
        return (
          <div className="flex items-center gap-3 text-sm text-neutral-900">
            {row?.profile?.profilePictureUrl ? (
              <img
                className="size-[36px] shrink-0 rounded-full"
                src={row?.profile?.profilePictureUrl}
                alt="profile"
              />
            ) : (
              <NoAvatarIcon className="shrink-0" />
            )}
            <div className="flex flex-col gap-[2px]">
              <div className="flex flex-wrap items-center space-x-[4px]">
                <span className="inline-block text-[12px] font-semibold text-neutral-900">
                  {row?.email}
                </span>
                <span className="rounded-2xl bg-neutral-150 px-[8px] py-[2px] text-[11px] font-semibold leading-[130%] text-neutral-500">
                  {row?.status === 'pending' ? 'Pending' : 'Active'}
                </span>
              </div>
            </div>
          </div>
        );
      },
      width: 700,
    },
    {
      title: 'Invitation Sent Date',
      dataIndex: 'invitation',
      render: (_, row) => {
        const getDate = () => {
          if (new Date(row?.updatedAt) > new Date(row?.createdAt)) {
            return `Resent on ${moment(row?.updatedAt).format(
              'DD MMM YYYY, HH:mm'
            )}`;
          }

          return `Sent on ${moment(row?.createdAt).format(
            'DD MMM YYYY, HH:mm'
          )}`;
        };

        return (
          <span className="text-[11px] leading-[130%] text-neutral-900">
            {getDate()}
          </span>
        );
      },
      width: 200,
    },
    {
      title: 'Resend Invitation',
      render: (_, row) => {
        return (
          <Button
            onClick={() => {
              handleResendInvitation(row);
            }}
            loading={resendInvitationLoading[row?.id]}
            className="!h-auto !bg-violet-0 !px-[13px] !py-[4px] !text-[11px] !text-violet-500 hover:!bg-violet-0"
          >
            <SendInvitation />
            <span className="ml-[8px]">Resend</span>
          </Button>
        );
      },
      width: 138,
    },
  ];
};

export const setLastSignedIn = ({ instance, id }) => {
  return instance.post(`/v1/job-portal/user-company/${id}/last-signed-in`);
};

export const deleteUserCompany = (instance, id) => {
  return instance.delete(`/v1/job-portal/user-company/${id}`);
};

export const inviteUserCompany = (instance, payload) => {
  return instance.post(`/v1/job-portal/invited-user-company`, { ...payload });
};

export const getCompanyMember = (instance, { companyId, ...params }) => {
  return instance.get(`/v1/job-portal/company/${companyId}/member`, {
    params,
    paramsSerializer: (params) => {
      return QueryString.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const getMemberById = (instance, { id }) => {
  return instance.get(`/v1/job-portal/user-company/${id}`);
};

export const resendInvitation = (instance, { id }) => {
  return instance.post(`/v1/job-portal/invited-user-company/${id}/resend`);
};

export const _getCompanyMember = (instance, companyId, { key, ...params }) => {
  const swrKey = key ? key : `/v1/job-portal/company/${companyId}/member`;

  const fetcher = async () => {
    try {
      const response = await instance.get(swrKey, {
        params: {
          ...params,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, { arrayFormat: 'repeat' });
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return { key, fetcher };
};
