import axios, { AxiosRequestConfig } from 'axios';

import { API_BASE_URL } from '@/common/configs/auth';
import { BackendRes } from '@/common/types';
import { apiInstance } from '@/modules/core/axios/configs';

import {
  CompanyByDomainResponse,
  CompleteCompanyPayload,
  Member,
} from '../types';

const baseURL = `${API_BASE_URL}/`;

const CompanyService = {
  completeCompanyData: (data: CompleteCompanyPayload) => {
    return apiInstance.put('/v1/profiles/employer', {
      ...data,
    });
  },
  getMyUserCompany: ({ axiosConfig }: { axiosConfig?: AxiosRequestConfig }) => {
    return apiInstance.get<
      BackendRes<{
        result: Member;
      }>
    >(`/v1/job-portal/me/company`, axiosConfig);
  },
  getCompany: ({ axiosConfig }: { axiosConfig?: AxiosRequestConfig }) => {
    const { params } = axiosConfig || {};
    const { id: companyId, ...restParams } = params;
    return apiInstance.get(`/v1/job-portal/company/${companyId}`, {
      ...axiosConfig,
      params: restParams,
    });
  },
  getCompanyByDomain: ({
    axiosConfig,
    domain,
  }: {
    axiosConfig?: AxiosRequestConfig;
    domain: string;
  }) => {
    return apiInstance.get<CompanyByDomainResponse>(
      `/v1/job-portal/company/email-domain/${domain}`,
      axiosConfig
    );
  },
};

// TODO: Refactor these fn to use CompanyService
export const completeCompanyData = (instance, data) => {
  return instance.put('/v1/profiles/employer', {
    ...data,
  });
};

export const getCompanyById = (instance, companyId) => {
  const key = `/v1/job-portal/company/${companyId}`;

  const fetcher = async () => {
    try {
      const response = await instance.get(key);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return { key, fetcher };
};

export const _getMyCompanyData = ({ token }) => {
  const endpoint = `${baseURL}v1/job-portal/me/company`;

  const fetcher = () => {
    return axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  return { key: endpoint, fetcher };
};

export const getMyCompanyData = (token) => {
  return axios.get(`${baseURL}v1/job-portal/me/company`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateCompanyData = (instance, id, data) => {
  return instance.put(`/v1/job-portal/company/${id}`, {
    ...data,
  });
};

export const updateCompanyAbout = (instance, id, data) => {
  return instance.put(`/v1/job-portal/company/${id}/about`, {
    ...data,
  });
};

export const updateCompanyInsight = (instance, id, data) => {
  return instance.put(`/v1/job-portal/company/${id}/insight`, {
    ...data,
  });
};

export const updateCompanyCulture = (instance, id, data) => {
  return instance.put(`v1/job-portal/company/${id}/culture`, {
    ...data,
  });
};

export const companyContactedStudent = (instance, data) => {
  return instance.post(`/v1/job-portal/user-company-contacted-student`, {
    ...data,
  });
};

export const companyAccessedStudent = (instance, data) => {
  return instance.post(`/v1/job-portal/user-company-accessed-student`, {
    ...data,
  });
};

export const getCultureRecommendation = (instance) => {
  return instance.get(`/v1/job-portal/culture`);
};

export const getEmployerTodo = (instance, userCompanyId) => {
  let params = '';
  if (userCompanyId) params = `?userCompanyId=${userCompanyId}`;
  return instance.get(`/v1/job-portal/employer-todo${params}`);
};

export const getDeallTodoForEmployer = (instance, id) => {
  return instance.get(`/v1/job-portal/company/${id}/deall-task`);
};

export const inviteCompanyMember = (instance, data) => {
  return instance.post(`/v1/job-portal/invited-user-company`, {
    ...data,
  });
};

export const getAvgResponseTime = (instance, id) => {
  return instance.get(`/v1/job-portal/company/${id}/avg-response-time`);
};

export const requestDisableUpgradedBanner = (instance, companyId) => {
  return instance.post(
    `/v1/job-portal/company/${companyId}/disable-subscription-upgraded-banner`
  );
};

export const _getCompanySubscription = (instance, params) => {
  const { companyId } = params;
  const key = `/v1/job-portal/company/${companyId}/subscription`;

  const fetcher = async () => {
    try {
      const response = await instance.get(key);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return { key, fetcher };
};

export const getCompanyOfCurrentUserSWR = (instance) => {
  const key = '/v1/job-portal/me/company';

  const fetcher = async () => {
    try {
      const response = await instance.get(key);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return { key, fetcher };
};

export const updateCompanyJobIntegration = (instance, id, data) => {
  return instance.patch(`/v1/job-portal/company/${id}/job-integration`, {
    ...data,
  });
};

export const getCompanyStats = (instance, companyId) => {
  const key = `/v1/job-portal/company/${companyId}/stats`;

  const fetcher = async () => {
    try {
      const response = await instance.get(key);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return { key, fetcher };
};

export const patchCompanyData = (instance, id, data) => {
  return instance.patch(`/v1/job-portal/company/${id}`, {
    ...data,
  });
};

export const increaseCompanyProfileView = (instance, id) => {
  return instance.post(`/v1/job-portal/company/${id}/increase-view`);
};

export const addCompanyPhoto = (instance, data) => {
  return instance.post(`/v1/job-portal/company-photo`, { ...data });
};

export const deleteCompanyPhoto = (instance, ids) => {
  return instance.delete(`/v1/job-portal/company-photo/bulk`, {
    params: {
      ids,
    },
  });
};

export const getListCompanyPhoto = (instance, { params }) => {
  const key = `/v1/job-portal/company-photo`;

  const fetcher = async () => {
    try {
      const response = await instance.get(key, {
        params: {
          ...params,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return { key, fetcher };
};

export const patchHighlightSuggestionChecked = (instance, companyId) => {
  return instance.post(
    `/v1/job-portal/company/${companyId}/highlight-suggestion-checked`
  );
};

export default CompanyService;
