import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { apiInstance } from '@/modules/core/axios/configs';

import { NotificationKeys } from '../../generator/keys';
import { _getAllNotifications } from '../../services/NotificationService';
import { NotificationAllResponse, NotificationList } from '../../types';

const useNotificationInfiniteQuery = ({
  params: { isRead, category } = {},
  options,
}: {
  params?: {
    isRead?: boolean | null;
    category?: string | string[] | null;
  };
  options?: {
    enabled?: boolean;
    select?: (
      data: InfiniteData<NotificationAllResponse>
    ) => InfiniteData<NotificationList>;
  };
}) => {
  const LIMIT = 16;

  let queryParams: Record<string, string | string[]> = {
    appTarget: 'deallCompany',
    limit: `${LIMIT}`,
  };

  // If there is a category, add it to the query params
  if (category !== null) {
    queryParams = {
      ...queryParams,
      category: `${category}`,
    };
  }

  // If there is a isRead, add it to the query params
  if (isRead !== null) {
    queryParams = {
      ...queryParams,
      isRead: `${isRead}`,
    };
  }

  return useInfiniteQuery(
    [...NotificationKeys.list(), queryParams],
    async ({ pageParam }) => {
      const queryParamsWithTime = {
        ...queryParams,
        datetime: pageParam?.nextDatetime,
      };

      const res = await _getAllNotifications(apiInstance, queryParamsWithTime);
      const resData = res?.data?.data?.result as NotificationAllResponse;
      return resData;
    },
    {
      getNextPageParam: (lastPage) => {
        const { nextDatetime } = lastPage;

        // If there is no next page, return undefined to stop the query from trying to fetch another page.
        if (!nextDatetime) {
          return undefined;
        }
        return {
          nextDatetime,
        };
      },
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export default useNotificationInfiniteQuery;
