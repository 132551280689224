import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

/**
 * @param {{
 *  error: Record<string, any>
 *  environment: string
 * }}
 */
const CopyErrorMessage = ({ error, environment }) => {
  const [errorCopied, setErrorCopied] = useState(false);
  if (errorCopied) {
    return <CheckOutlined className="!text-green-700" />;
  }
  return (
    <CopyOutlined
      className="cursor-pointer !text-gray-500 hover:!text-gray-800"
      onClick={() => {
        const errorObject = {
          ...error,
          timeStamp: new Date(),
          environment,
        };
        navigator.clipboard.writeText(JSON.stringify(errorObject || ''));
        setErrorCopied(true);
      }}
    />
  );
};

export default CopyErrorMessage;
