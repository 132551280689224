import React from 'react';

const EnergeticIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.875 37.4988L18.375 24.9988L8.375 21.2488L25.875 2.49878L23.375 14.9988L33.375 18.7488L15.875 37.4988Z"
        stroke="url(#paint0_linear_28_1754)"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_28_1754"
          x1="20.875"
          y1="2.49878"
          x2="20.875"
          y2="37.4988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7767E4" />
          <stop offset="1" stopColor="#5C48E5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EnergeticIcon;
