const SuccessIcon = (
  props: JSX.IntrinsicElements['svg'] & {
    bgColor?: string;
  }
) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="14" height="14" rx="7" fill={props?.bgColor || '#FFFFFF'} />
    <path
      d="M6.20719 8.37857C5.81661 8.76878 5.1837 8.76863 4.79331 8.37824L3.76758 7.35252C3.5727 7.15763 3.2569 7.15707 3.06133 7.35127C2.86478 7.54643 2.86422 7.86416 3.06008 8.06002L4.79297 9.79291C5.1835 10.1834 5.81666 10.1834 6.20719 9.79291L11.1475 4.8526C11.3422 4.65787 11.3422 4.34216 11.1475 4.14743C10.9528 3.95277 10.6372 3.95269 10.4425 4.14726L6.20719 8.37857Z"
      fill="#0B9F79"
    />
  </svg>
);

SuccessIcon.Close = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_403_21165)">
        <path
          d="M15.2462 5.92913C15.5706 5.60466 15.5706 5.07859 15.2462 4.75413C14.9217 4.42966 14.3956 4.42966 14.0712 4.75413L10.7074 8.11785C10.3169 8.50838 9.68374 8.50838 9.29322 8.11785L5.92949 4.75413C5.60503 4.42966 5.07896 4.42966 4.75449 4.75413C4.43002 5.07859 4.43002 5.60466 4.75449 5.92913L8.11822 9.29285C8.50874 9.68338 8.50874 10.3165 8.11822 10.7071L4.75449 14.0708C4.43003 14.3953 4.43002 14.9213 4.75449 15.2458C5.07896 15.5703 5.60502 15.5703 5.92949 15.2458L9.29322 11.8821C9.68374 11.4915 10.3169 11.4915 10.7074 11.8821L14.0712 15.2458C14.3956 15.5703 14.9217 15.5703 15.2462 15.2458C15.5706 14.9213 15.5706 14.3953 15.2462 14.0708L11.8824 10.7071C11.4919 10.3165 11.4919 9.68338 11.8824 9.29285L15.2462 5.92913Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_403_21165">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuccessIcon;
