import { atom } from 'jotai';
import { atomWithStorage, RESET } from 'jotai/utils';

import { LocalSyncStorage } from '@/common/helpers/VirtualLocalStorage';
import { generateLocalStorageKey } from '@/common/utils';
import { Job } from '@/modules/jobs/types';

import {
  ANALYTICS_NEW_RELEASE_CLICK_STATUS_STRG_IDENTIFIER,
  ANALYTICS_NEW_RELEASE_DATE_STRG_IDENTIFIER,
} from '../constants';
import {
  AnalyticsJobType,
  AnalyticsTime,
  DropdownAnalyticsTimeType,
  NodeWithStepFunnel,
} from '../types';
import { generateLabelAndValueByTimeFilter } from '../utils/generator';

const ANALYTICS_FILTER_VERSIONS = ['1.0.0'];

export const selectedJobsFilterAtom = atomWithStorage<
  (Job | AnalyticsJobType)[]
>(
  generateLocalStorageKey(
    'selectedJobsFilter',
    ANALYTICS_FILTER_VERSIONS[ANALYTICS_FILTER_VERSIONS.length - 1]
  ),
  ['allActiveJob']
);

export const selectedPeriodFilterAtom = atomWithStorage<{
  label: string;
  value: [Date, Date] | null;
  timeFilterValue: AnalyticsTime;
} | null>(
  generateLocalStorageKey(
    'selectedPeriodFilterAtom',
    ANALYTICS_FILTER_VERSIONS[ANALYTICS_FILTER_VERSIONS.length - 1]
  ),
  generateLabelAndValueByTimeFilter('weekly')
);

selectedPeriodFilterAtom.onMount = (set) => {
  set((period) => {
    if (period?.timeFilterValue && period.timeFilterValue !== 'custom') {
      return {
        ...generateLabelAndValueByTimeFilter(period.timeFilterValue),
        timeFilterValue: period.timeFilterValue,
      };
    }

    return period;
  });
};

export const resetAnalyticsFilterAtom = atom(null, (_get, set) => {
  set(selectedJobsFilterAtom, RESET);
  set(selectedPeriodFilterAtom, RESET);
  ANALYTICS_FILTER_VERSIONS.forEach((version, index) => {
    if (index !== ANALYTICS_FILTER_VERSIONS.length - 1) {
      localStorage.removeItem(
        generateLocalStorageKey('selectedTimeFilter', version)
      );
      localStorage.removeItem(
        generateLocalStorageKey('selectedJobsFilter', version)
      );
      localStorage.removeItem(
        generateLocalStorageKey('selectedPeriodFilterAtom', version)
      );
    }
  });
});

export const dropdownFilterByJobOpenAtom = atom(false);
export const dropdownTypeTimeFilterAtom =
  atom<DropdownAnalyticsTimeType>('default');

export const analyticsReleaseDate = new Date('2023-11-06');
export const analyticsNewReleaseDateAtom = atomWithStorage<Date>(
  ANALYTICS_NEW_RELEASE_DATE_STRG_IDENTIFIER,
  analyticsReleaseDate,
  new LocalSyncStorage<Date>()
);
export const analyticsNewReleaseClickedAtom = atomWithStorage<boolean>(
  ANALYTICS_NEW_RELEASE_CLICK_STATUS_STRG_IDENTIFIER,
  false,
  new LocalSyncStorage<boolean>()
);

export const activeNodeFunnelAtom = atom<NodeWithStepFunnel | null>(null);
